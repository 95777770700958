import { Component, Input, OnInit } from '@angular/core'
import { HelpersService } from 'src/app/services/helpers/helpers.service'

@Component({
	selector: 'app-insurances',
	templateUrl: './insurances.component.html',
	styleUrls: ['./insurances.component.scss'],
})
export class InsurancesComponent implements OnInit {
	constructor(public helpers: HelpersService,) {}

	ngOnInit(): void {}
	@Input() classCustom: string

	@Input() data: any
}

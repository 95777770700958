<footer>
	<div class="socials" [style]="{'backgroundColor': data?.background_color}">
		<div class="container">
			<div class="row align-items-md-center">
				<div class="col-12 col-md-6 text-center text-md-end pe-md-5">
					<div class="footer-logo-wrapper">
						<a [href]="data?.image?.link_text ? data.image.link_text : 'https://www.allianz.com.br/'" target="_blank">
							<figure>
								<img [src]="data?.image?.image ? data.image.image : '/assets/images/logo.svg'" alt="Allianz">
							</figure>
						</a>

						<hr [class]="image ? 'xp-show' : 'd-none'" />

						<figure [class]="image ? 'xp-show logo' : 'd-none logo'">
							<img [src]="image ? image : '/assets/images/logo.svg'" alt="Allianz">
						</figure>
					</div>
				</div>
				<div *ngIf="data?.icons.length > 0" class="col-12 col-md-6 text-center text-md-start ps-md-5">
					<div class="socials__wrapper">
						<ul class="socials__list">
							<li *ngFor="let icon of (data?.icons ? data?.icons : [])">
								<a
									[href]="icon.link_text"
									target="_blank"
								>
									<figure>
										<img
											loading="lazy"
											[src]="icon.image"
											[alt]="helpers.removeTags(icon.text)"
                                            class="social-ga-js"
										/>
									</figure>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div *ngIf="data?.icons.length === 0" class="col-12 col-md-6 text-center text-md-start ps-md-5">
					<div class="socials__wrapper">
						<ul class="socials__list">
							<li>
								<a
									href="https://www.instagram.com/allianzbrasil/"
									target="_blank"
								>
									<figure>
										<img
											loading="lazy"
											src="/assets/images/instagram.svg"
											alt="Instagram"
                                            class="social-ga-js"
										/>
									</figure>
								</a>
							</li>
							<li>
								<a
									href="https://www.facebook.com/allianzbrasil"
									target="_blank"
								>
									<figure>
										<img
											loading="lazy"
											src="/assets/images/facebook.svg"
											alt="Facebook"
                                            class="social-ga-js"
										/>
									</figure>
								</a>
							</li>
							<li>
								<a
									href="https://www.linkedin.com/company/allianz-seguros-brasil/?viewAsMember=true"
									target="_blank"
								>
									<figure>
										<img
											loading="lazy"
											src="/assets/images/linkedin.svg"
											alt="Linkedin"
                                            class="social-ga-js"
										/>
									</figure>
								</a>
							</li>
							<li>
								<a
									href="https://www.youtube.com/channel/UCqMBrRQRPhCIyzUWmUbfSpw"
									target="_blank"
								>
									<figure>
										<img
											loading="lazy"
											src="/assets/images/youtube.svg"
											alt="Youtube"
                                            class="social-ga-js"
										/>
									</figure>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="disclaimer" *ngIf="data && (data.links && data.links.length > 0)" [style]="{'backgroundColor': data.background_text_legal}">
		<div class="container">
			<ul class="disclaimer__list">
				<li *ngFor="let link of data.links; let i = index">
					<div class="d-flex">
						<a
							[href]="link.link_text"
							target="_blank"
							[innerHTML]="link.text | htmlParse"
						>
						</a>
						<div *ngIf="data.links.length > i + 1" class="mx-1">
							•
						</div>
					</div>
				</li>
			</ul>

            <p class="disclaimer__text">
                *Algumas coberturas e serviços possuem restrições e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.
            </p>

            <br>

            <p class="disclaimer__text" [innerHTML]= "(data?.legal_text ? data?.legal_text : '') | htmlParse"></p>
		</div>
	</div>

	<div class="disclaimer" *ngIf="data && (!data.links || data.links.length === 0)" [style]="{'backgroundColor': data.background_text_legal}">
		<div class="container" >
			<ul class="disclaimer__list">
				<li>
					<a
						href="https://www.allianz.com.br/politica-de-privacidade"
						target="_blank"
					>Política de Privacidade</a
					>
				</li>
				<li>•</li>
				<li>
					<a
						href="https://www.allianz.com.br/politicas-do-sistema.html#cookies"
						target="_blank"
					>Política de Cookies</a
					>
				</li>
				<li>•</li>
				<li>
					<a
						href="https://www.allianz.com/en.html"
						target="_blank"
					>Allianz Global</a
					>
				</li>
			</ul>

            <p class="disclaimer__text">
                *Algumas coberturas e serviços possuem restrições e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.
            </p>

            <br>

			<p class="disclaimer__text">
                Allianz Seguros S.A., CNPJ: 61.573.796/0001-66, Rua Eugênio de Medeiros nº 303, ands. 1-parte, 2 ao 9, 15 e 16, Pinheiros, São Paulo - CEP: 05425-000. Processos SUSEP: 15414.002216/2004-57, 15414.100898/2004-62 e 15414.001289.2007-74. Consulte as Condições Gerais, assim como os planos de assistência 24h, serviços e abrangência do seguro no site <a href="http://www.allianz.com.br" target="_blank"> http://www.allianz.com.br</a>. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP. A aceitação do seguro está sujeita a análise do risco. Linha Direta Allianz Seguros:<a href="tel:4090 1110" target="_blank"> 4090 1110</a> (Capitais e Regiões Metropolitanas),<a href="tel:0800 777 7243" target="_blank"> 0800 777 7243</a> (demais regiões).  SAC (24 horas):<a href="tel:08000 115 215" target="_blank"> 08000 115 215</a>. Ouvidoria Allianz Seguros: <a href="tel:0800 771 3313" target="_blank">0800 771 3313</a>. Atendimento à pessoa com deficiência auditiva ou de fala: <a href="tel:08000 121 239" target="_blank">08000 121 239</a>.
			</p>
		</div>
	</div>
</footer>

import { Injectable } from '@angular/core'
import { Callbacks } from '../../models/callbacks'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'

@Injectable({
	providedIn: 'root',
})
export class AddressService {
	constructor(private http: HttpClient) {}

    getAddressByZipCode(zipCode: string, callback: Callbacks): void {
        this.http
            .get<any>(
                `${environment.api}api/addresses/${zipCode}/cep`
            )
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                    next: (data) => {
                        callback.fnSuccess(data)
                    },
                    error: (err) => {
                        console.warn(err)
                        callback.fnError(err)
                    }
                }
            )
    }

    getStates(callback: Callbacks): void {
        this.http
            .get<any>(
                `${environment.api}api/addresses/states/`
            )
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                    next: (data) => {
                        callback.fnSuccess(data)
                    },
                    error: (err) => {
                        console.warn(err)
                        callback.fnError(err)
                    }
                }
            )
    }

    getCities(ufId: number, callback: Callbacks): void {
        this.http
            .get<any>(
                `${environment.api}api/addresses/state/${ufId}/cities/`
            )
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                    next: (data) => {
                        callback.fnSuccess(data)
                    },
                    error: (err) => {
                        console.warn(err)
                        callback.fnError(err)
                    }
                }
            )
    }
}

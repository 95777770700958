import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks'

@Injectable({
	providedIn: 'root',
})
export class VehicleService {
	constructor(private http: HttpClient) {}

	getYears(payload: any, callback: Callbacks): void {
		this.http
			.get<any>(`${environment.api}fipe/year?type=${payload.type}&origin=${payload.origin}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					console.warn(err)
					callback.fnError(err)
				},
			})
	}

	getModel(payload: any, callback: Callbacks): void {
		this.http
			.get<any>(`${environment.api}fipe/models?type=${payload.type}&origin=${payload.origin}&year=${payload.year}&model=${payload.model}&cep=${payload.cepParam}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					console.warn(err)
					callback.fnError(err)
				},
			})
	}


}

<div class="formulae">
    <div *ngFor="let component of data let i = index">
        <!-- form -->
        <div *ngIf="component.handle == 'form_section'">
<!--            <app-banner-form [title]="component.data.text" [description]="component.data.description" [text]="(slug === 'auto' && step === 3 && fullRequest) || (slug !== 'auto' && step === 2 && fullRequest) ? formFeedback.title_form : component.data.form.text" [img]="component.data.image"  [alt_text]="component.data.alt_text">-->
<!--                -->
<!--            </app-banner-form>-->
            <div class='formulae-form'>
                <div class='wrapper-banner'>
                    <div class='collapse-form' *ngIf='!activeForm' (click)="toggleForm()">
                        <div class='text'>
                            <p>Solicite aqui sua cotação!</p>
                            <figure>
                                <img src='/assets/images/formulae-arrow-right.png' alt=''>
                            </figure>
                        </div>
                    </div>
                    <figure>
                        <img src='/assets/images/formulae-header.png' alt=''>
                    </figure>

                    <div class='wrapper-text'>
                        <div>
                            <h3>VOCÊ GANHOU</h3>
                            <h4>10%<span>OFF*</span></h4>
                            <span>nos Seguros Auto, Residência e Vida.</span> <br>
                            <span>Utilize o cupom <span style="font-family: var(--font-bold);"> FORMULA10</span></span> <br><br>
                        </div>
                        <p>
                            E foi dada a largada para você viver o melhor da <strong>Fórmula E.</strong> <br><br>
                            A seguradora parceira oficial da Fórmula E também pode ser a sua!
                        </p>
                    </div>

                    <div class='wrapper-form' [ngClass]='activeForm ? "active" : ""'>
                        <figure class='icon' (click)="toggleForm()">
                            <img src='/assets/images/formulae-arrow-right.png' alt=''>
                        </figure>

                        <div form>
                            <p>Solicite aqui sua cotação!</p>
                            <form action='#'
                                  autocomplete='off'
                                  (ngSubmit)='onSubmit()'
                                  [formGroup]='form'
                                  *ngIf="form"
                                  class="formCustom">
                                <input type="hidden" name="hubUtmz" id="hubUtmz" value="" />
                                <div *ngIf="component.data.form.steps.length > 1" class="progress-wrap">
                                    <ul class="checkout-bar">
                                        <li class="progressbar-dots" *ngFor="let steps of component.data.form.steps let i = index" [ngClass]="{'active': steps.order === step, 'complete': steps.order < step, 'last': component.data.form.steps.length === i+1}">
                                            <div *ngIf="component.data.form.steps.length > i+1 || steps > 1" class="line-progress-bar" [ngClass]="{'active': steps.order === step, 'complete': steps.order < step }"></div>
                                            <span>{{steps.order}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="wrapper-steps">
<!--                                    [ngClass]="!fullRequest ? 'show' : 'hide'"-->
                                    <div *ngFor="let steps of component.data.form.steps let i = index" class="wrapper-steps__step" id="'step' + steps.order">
                                        <div class="form-content row">
                                            <div *ngFor="let input of steps.inputs let i = index" [ngClass]='input.component === "name" || input.component === "consent" ? "col-12" : "col-12 col-md-6"'>
                                                <div class="field" *ngIf="input.component === 'name'">
                                                    <app-input
                                                        [id]='input.component'
                                                        [name]='input.component'
                                                        [labelName]='input.title'
                                                        [showLabel]='true'
                                                        [placeholder]='input.placeholder'
                                                        [form]='form'
                                                    ></app-input>
                                                </div>

                                                <div class="field" *ngIf="input.component === 'email'">
                                                    <app-input
                                                        [id]='input.component'
                                                        [name]='input.component'
                                                        [labelName]='input.title'
                                                        [showLabel]='true'
                                                        [placeholder]='input.placeholder'
                                                        typeInput='email'
                                                        [form]='form'
                                                    ></app-input>

                                                </div>

                                                <div class="field" *ngIf="input.component === 'cellphone'">
                                                    <app-input
                                                        [id]='input.component'
                                                        [name]='input.component'
                                                        [showLabel]='true'
                                                        [labelName]='input.title'
                                                        [placeholder]='input.placeholder'
                                                        inputMask='(00) 00000-0000'
                                                        [form]='form'
                                                    ></app-input>
                                                    <span
                                                        *ngIf='form.get("cellphone")?.value && form.get("cellphone")?.value.length < 11'
                                                        class='control-error diferent-email'
                                                    >
                                                            POR FAVOR, INSIRA UM TELEFONE VÁLIDO
                                                        </span>
                                                </div>

                                                <div class="field" *ngIf="input.component === 'coupon'">
                                                    <app-input
                                                        [id]='input.component'
                                                        [name]='input.component'
                                                        [labelName]='input.title'
                                                        [showLabel]='true'
                                                        [placeholder]='input.placeholder'
                                                        [form]='form'
                                                        (emmitEvent)='couponValue($event)'
                                                        (emmitBlur)='getCoupon(coupon, false)'
                                                    ></app-input>
                                                </div>

                                                <div class="field" *ngIf="input.component === 'interest1Title'">
                                                    <app-select
                                                        [id]='input.component'
                                                        [name]='input.component'
                                                        [labelName]='input.title'
                                                        [placeholder]='input.placeholder'
                                                        [options]='modality'
                                                        [disabled]='modality.length <= 1'
                                                        inputModel=''
                                                        [form]='form'
                                                    ></app-select>
                                                </div>

                                                <div class="mb-3" *ngIf="input.component === 'consent'">
                                                    <div class="option d-flex">
                                                        <app-checkbox
                                                            style="width: 95%"
                                                            [id]="input.component"
                                                            [name]="input.component"
                                                            [form]='form'
                                                        >
                                                            <div
                                                                class="label-checkbox"
                                                                [innerHTML]="input.placeholder | htmlParse"
                                                            ></div>
                                                        </app-checkbox>
                                                        <!--                                                            <span-->
                                                        <!--                                                                style="width: 5%"-->
                                                        <!--                                                                class="custom-tooltip btn-open "-->
                                                        <!--                                                                (click)="openModal()"-->
                                                        <!--                                                            >i</span>-->
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div *ngIf="(steps ||steps.button || steps.button.text || steps.button.background || steps.button.text_color) || (component.data.form.steps.length > i+1 && step > 1)" class="action-button">
                                            <button *ngIf="steps && steps.button && steps.button.text && steps.button.background && steps.button.text_color"
                                                    type="button"
                                                    [disabled]="disabled" [style.backgound-color]="steps?.button?.background"
                                                    [style.color]="steps?.button?.text_color" (click)="onSubmit()"
                                            >
                                                {{ steps?.button?.text }}
                                            </button>
                                        </div>
                                    </div>
<!--                                    <div *ngIf="formFeedback && fullRequest">-->
<!--                                        <h5 *ngIf="formFeedback?.title" class="title" [innerHTML]="formFeedback.title | htmlParse"></h5>-->

<!--                                        <p>Sucesso!</p>-->
<!--                                    </div>-->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end form -->

        <div class='container' *ngIf="component.handle === 'content'">
            <div class='formulae-content'>
                <h3>Por que escolher a Seguradora Oficial da Fórmula E?</h3>
                <p>
                    Chegou a hora de ter a mesma seguradora da <strong>Fórmula E!</strong> <br>
                    Como uma das maiores seguradoras automotivas do mundo, a Allianz tem orgulho em apoiar a corrida mais tecnológica e inovadora do automobilismo.</p>
            </div>
        </div>

        <div class="wrapper-tabs pt-5" *ngIf="component?.handle === 'sections_tabbannersection'">
            <h2 class="title-tab" *ngIf="component.data && component.data.text" [innerHTML]="component.data.text | htmlParse"></h2>
            <app-tabs-rounded *ngIf="component.data && component.data.banners">
                <app-tab-rounded *ngFor="let tab of component.data.banners let i = index" [tabTitle]="tab.title" [active]="i == component.data.banners.length - 1">
                    <div>
                        <app-tab-description [tab]="tab"></app-tab-description>

                        <section id="safe-description" class='formulae-benefits'>
                            <div class="benefits">
                                <div class="wrapper-container">
                                    <div class="container">
                                        <div class="safe auto">
                                            <h2 class="safe-title" [innerHTML]='tab.title_card'></h2>
                                            <div class="safe-draft" *ngIf='tab.draft'>
                                                <img src='/assets/images/formulae-icon-star.png' alt=''>
                                                <p>Se o seu carro for <strong>elétrico ou híbrido</strong>, você tem <strong>proteção completa</strong> e diferenciais!
                                                    <br>
                                                    <a href='https://www.allianz.com.br/seguros/seu-patrimonio/carro.html#protecao-e-diferenciais' target='_blank'>Saiba mais!</a>
                                                </p>
                                            </div>
                                            <div class="row justify-content-center">
                                                <div class="col-6 col-md-4 mb-4 mx-auto mx-md-0" *ngFor="let card of tab?.cards">
                                                    <app-card [data]="card" [legalText]="card?.legal_text"></app-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p class='disclaimer' *ngIf='tab.disclaimer'>{{ tab.disclaimer }}</p>
                        </section>
                    </div>
                </app-tab-rounded>
            </app-tabs-rounded>
        </div>

        <div *ngIf="component.handle === 'benefits'" [id]='component.fragment ? component.fragment : ""'>
            <app-banner-text [img]="component?.data?.image_flashy" [title]="component?.title" [services]="component?.data?.benefits_items" [subtitle]='component?.data?.subtitle'></app-banner-text>
        </div>
    </div>
</div>

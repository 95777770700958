import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'htmlParse'
})
export class HtmlParsePipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string, ...args: unknown[]): any {
    return this._sanitizer.bypassSecurityTrustHtml(value)
  }

}

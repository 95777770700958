import { ActionModel } from '../action'
import { ILandingpage } from 'src/app/models/landingpage/landingpage'
import { ActionLandingpageTypes } from './landingpage.action'

const initialState: ILandingpage = {
	partner: {
        title: '',
        slug: ''
    },
    slug: '',
    pageContent: []
}


export function landingpageReducer(state: ILandingpage = initialState, action: ActionModel) {
    switch (action.type) {
        case ActionLandingpageTypes.Add: {
            state = { ...state, ...action.payload }
            return state
        }

        case ActionLandingpageTypes.Clear: {
            state = initialState
            return state
        }

        default:
            return state
    }
}

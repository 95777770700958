import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
	public showPass: boolean = false
	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() placeholder?: string
	@Input() max?: number = 50
	@Input() typeInput?: string = 'text'
	@Input() labelName?: string
	@Input() callout?: string
	@Input() inputModel: any
	@Input() inputMask: string = ''
    @Input() inputAltMask?: any = {
        mask: '',
        digits: 0,
        oldMask: this.inputMask
    }
	@Input() showLabel: boolean = false
	@Input() disabled: boolean = false
	@Output() emmitEvent: EventEmitter<any> = new EventEmitter()
	@Output() emmitBlur: EventEmitter<any> = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}

	toggleInputPass(): void {
		this.showPass = !this.showPass
	}

    changeMask(evt: any): void {
        if(this.inputAltMask?.mask !== '' && this.inputAltMask?.digits > 0) {
            if(this.form?.controls?.registrationNumberCFPorCNPJ?.value?.length > this.inputAltMask?.digits) {
                this.inputMask = this.inputAltMask?.mask

            }
            else {
                this.inputMask = this.inputAltMask?.oldMask
            }

        }
    }

    keyDownHandler(evt: any): void {
	    if(this.typeInput === 'email') {
            if (/[^a-zA-Z0-9 @.]/gi.test(evt.key)) {
                evt.preventDefault();
            }
        }

	    else if(this.typeInput === 'text') {
            // if (/[^a-zA-Z0-9 @.]/gi.test(evt.key)) {
            if (/[-'`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi.test(evt.key)) {
                evt.preventDefault();
            }
        }
    }

	onChange(event: any, value: string): void {
        this.emmitEvent.emit(value.replace(/[^a-z0-9 @.]/gi, ''))
    }

	onBlur(event: any, value: string): void {
		if (value.length) this.emmitBlur.emit(value)
	}
}

<div class='control select'>
    <div class='form-group'>
        <label
            [for]='id || name'
            [class.default]='!labelName'
            [formGroup]='form'
        >
            <span *ngIf='labelName' class='control-label'>{{ labelName }}</span>
            <select
                [id]='id || name'
                [attr.name]='name'
                [formControlName]='name'
                [setValue]='inputModel'
                (change)='onChange()'
                class="form-custom form-select"
                [attr.disabled]="disabled == true ? true : null"
            >
                <option *ngIf="placeholder" value=''>{{ placeholder }}</option>
                <option *ngFor='let opt of options' [value]='opt.value'>
                    {{ opt.label }}
                </option>
            </select>
        </label>
        <span
            *ngIf="
				form.controls[name || ''].invalid &&
				(form.controls[name || ''].touched ||
					form.controls[name || ''].dirty)
			"
            class='control-error'
            [setFormControlError]="{
				objError: form.controls[name || ''].errors,
				control: labelName
			}"
        >
		</span>
    </div>
</div>

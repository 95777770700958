import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from './state/app-state'
import { AuthService } from './services/auth/auth.service'
import { IGlobals } from './models/landingpage/globals'

declare global {
	interface Window {
		ga: any
		pintrk: any
		dataLayer: any
		gtag: any
		uetq: any
	}
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	private loading$?: Observable<boolean>
	private globals$?: Observable<IGlobals>
	loading?: boolean
	globals?: IGlobals
    activeFormulaE = false

	constructor(
		private authService: AuthService,
		private store: Store<AppState>
	) {
		this.loading$ = this.store.select('loading')
		this.loading$.subscribe((state) => {
			this.loading = state
		})
	}

	ngOnInit(): void {
        if(window.location.pathname === '/formulae') {
            this.activeFormulaE = true
        }
    }
}

<ul class="nav nav-tabs">
	<div class="nav-area light-gray-bg-xp">
		<li
			*ngFor="let tab of tabs let i = index"
			(click)="selectTab(tab)"
			[class.active]="tab.active"
			class="black-bg-xp-active"
		>
			<button type="button" class="btn btn-primary dark-gray-text-xp light-gray-bg-xp btn-tab-js">{{ tab.title }}</button>
		</li>
	</div>
</ul>
<ng-content></ng-content>

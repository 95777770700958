import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
	@Input() path?: string = ''
	@Input() title?: string = ''
	@Input() alt?: string = 'image'
	@Input() load: boolean = false
	@Input() lazy?: boolean = true

	default?: {
		defaultImage: string
		errorImage: string
	}

	ngOnInit(): void {}
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { LandPagesService } from 'src/app/services/landpages/landpages.service'
import { ModalTooltipComponent } from '../../components/modals/modal-tooltip/modal-tooltip.component'

@Component({
	selector: 'app-parceiros-insurance',
	templateUrl: './parceiros-insurance.component.html',
	styleUrls: ['./parceiros-insurance.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ParceirosInsuranceComponent implements OnInit {
    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
    }

	hubUtm = ''
	errorZipcode = false
	disabled = true
	step = 1
	formFeedback = {
		title: '',
		subtitle: '',
	}
	partner = this.activatedRouter.snapshot.paramMap.get('parceiro')

	slug: string = ''

	data: any = null
	tabs = [
		{
			title: 'Vida',
			active: false,
			content: 'life',
		},
		{
			title: 'Residência',
			active: false,
			content: 'residence',
		},
		{
			title: 'Auto',
			active: true,
			content: 'auto',
		},
	]

	constructor(
		private modal: NgbModal,
		private landpageService: LandPagesService,
		public activatedRouter: ActivatedRoute,
		public router: Router

	) {}

	ngOnInit(): void {

		this.getPartnerData()
        this.slug = window.location.pathname

		if(this.partner === 'xp-investimentos' || this.partner === 'xp-colaboradores') {
            const content = document.querySelector('.parceiros');
            const header = document.querySelector('header');
            const footer = document.querySelector('footer');

            content?.classList.add('xp-content')
            header?.classList.add('xp-content')
            footer?.classList.add('xp-content')
        }
	}

	getPartnerData() {
		const payload = {
			partner: this.activatedRouter.snapshot.paramMap.get('parceiro') || 'allianz',
			uuid: this.activatedRouter.snapshot.routeConfig?.data?.title === 'Seguro Allianz - Parceiro Preview! Faça sua cotação' ? this.activatedRouter.snapshot?.paramMap?.get('uuid') : ''
		}
		const self = this

		self.landpageService.getPartnersData(payload, this.activatedRouter.snapshot.routeConfig?.data?.title === 'Seguro Allianz - Parceiro Preview! Faça sua cotação' ? `${payload.partner}/${payload.uuid}/preview` : `${payload.partner}`, {
			fnSuccess(data?: any) {
				if (data) {
					self.data = data.filter((element: any) => element !== null)
				}
			},
			fnError(error) {
				self.router.navigate(['/not-found'])
			},
			fnFinalized() {},
		})
	}

	openModal(): void {
		this.modal.open(ModalTooltipComponent, {
			modalDialogClass: 'modal-tooltip',
		})
	}
}

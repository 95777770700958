<div class="modal-content">
    <button
        type="button"
        class="close light-gray-bg-xp gray-text-xp"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <figure>
            <img *ngIf="figure" [src]='figure' alt=''>
        </figure>
        <h2 class="modal-title text-center" *ngIf="titleModal" [innerHTML]="titleModal | htmlParse"></h2>

        <h4 class="modal-description" *ngIf="description" [innerHTML]="description | htmlParse"></h4>

        <button *ngIf='buttonText' class="modal-button black-bg-xp" (click)="close()" [innerHTML]="(buttonText ? buttonText : '') | htmlParse"></button>

    </div>
</div>

<section id="highlight" [ngClass]="{'invert-colors': invertColors}">
    <div class="highlight">
        <div class="highlight__columns">
            <div class="highlight__columns__item" [ngClass]="{'no-image': img === '' || !img}">
                <div class="highlight__info" [ngClass]="{'no-image-text': img === '' || !img}">
                    <div class="highlight__texts">
                        <h1 *ngIf="title" class="highlight__title" [innerHTML]="title | htmlParse"></h1>
                        <h3 *ngIf="description" class="highlight__subtitle" [innerHTML]="description | htmlParse"></h3>
                        <div *ngIf="disclaimer" class='highlight__disclaimer' [innerHTML]="disclaimer | htmlParse"></div>
                    </div>
                    <div *ngIf="img !== ''" style="width: 100%;">
                        <app-image class="highlight__banner" [path]="img" [alt]="alt_text" title="banner"></app-image>
                    </div>
                </div>
            </div>
            <div id="quote-form" class="highlight__columns__item other-bg">
                <div class="content">
                    <div class="wrapper-form">
                        <div *ngIf="text" class="highlight__title__form" [innerHTML]="text | htmlParse"></div>
                        <ng-content select="[form]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

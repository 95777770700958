<div class="modal-content">
    <button
        type="button"
        class="close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <h4 class="modal-description mb-0">
            Pedimos o seu CPF, pois é um dado essencial para o corretor realizar a sua cotação.
        </h4>
    </div>
</div>

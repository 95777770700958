import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { LandPagesService } from 'src/app/services/landpages/landpages.service'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { ModalTooltipComponent } from '../../components/modals/modal-tooltip/modal-tooltip.component'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { FollowizeService } from '../../services/followize/followize.service'
import { AppState } from '../../state/app-state'
import * as moment from 'moment'
import { ToastrService } from 'ngx-toastr'
import { CouponService } from '../../services/coupon/coupon.service'
import { ModalBenefitComponent } from '../../components/modals/modal-benefit/modal-benefit.component'
import { ModalCouponComponent } from '../../components/modals/modal-coupon/modal-coupon.component'
import { HelpersService } from '../../services/helpers/helpers.service'

@Component({
    selector: 'app-formulae',
    templateUrl: './formulae.component.html',
    styleUrls: ['./formulae.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormulaeComponent implements OnInit {
    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
    }
    loadingDataCoupon: boolean = false
    validCoupon: boolean = false
    hubUtm = ''
    pathParams: string = ''
    coupon = ''
    errorZipcode = false
    disabled = true
    step = 1
    modelSelected = false
    activeForm = true
    formFeedback = {
        title_form: '',
        title: '',
        subtitle: '',
    }
    slug: string = ''
    form: FormGroup
    typeForm = ''
    partner = this.router.snapshot.paramMap.get('parceiro')
    fullRequest = false
    modality = [
        {
            value: 'Residência',
            label: 'Residência'
        },
        {
            value: 'Auto',
            label: 'Auto'
        },
        {
            value: 'Vida',
            label: 'Vida'
        },
    ]
    data: any[] = [
        {
            handle: "form_section",
            api_key: "dd59231c-40a1-4702-84ab-538518d9c0ac",
            team_key: null,
            lead: "/followize/lead-event",
            lead_count: 167,
            data: {
                text: "<p><span style=\"color:#003781\"><strong>Seguro Auto</strong>&nbsp;em at&eacute;&nbsp;</span><span style=\"color:#f62459\"><strong>10x sem juros</strong></span><span style=\"color:#003781\">&nbsp;no cart&atilde;o de cr&eacute;dito!</span></p>",
                background: "#ffffff",
                description: "<p>E foi dada a largada para você viver o melhor da Fórmula E. A seguradora oficial da Fórmula E também pode ser a sua!</p>",
                image: "/assets/images/formulae-banner.png",
                alt_text: null,
                form: {
                    title: "Formulário Auto",
                    text: "<p><span style=\"color:#122b54\"><strong>Solicite aqui sua cota&ccedil;&atilde;o!</strong></span></p>",
                    sucess_title: "<p><span style=\"color:#122b54\"><strong>Tudo pronto! ;)</strong></span></p>",
                    sucess_message: "<p><span style=\"color:#122b54\">Em at&eacute; 1 dia &uacute;til voc&ecirc; receber&aacute; o contato do nosso corretor parceiro, que vai solicitar mais alguns dados para concluir a cota&ccedil;&atilde;o e enviar a oferta ideal pra voc&ecirc;!&nbsp;🙂</span></p>",
                    steps: [
                        {
                            text: "<p>Etapa 1 Auto</p>",
                            description: "",
                            button: {
                                background: "#003781",
                                background_hover: "#122b54",
                                text: "QUERO UMA COTAÇÃO",
                                text_color: "#ffffff",
                                text_color_hover: "#ffffff",
                                link_url: null,
                                link_external: false
                            },
                            inputs: [
                                {
                                    title: "Nome completo",
                                    placeholder: "Digite",
                                    type: "text",
                                    required: true,
                                    component: "name",
                                    value: null,
                                    children: [

                                    ]
                                },
                                {
                                    title: "Whatsapp",
                                    placeholder: "Digite",
                                    type: "3",
                                    required: true,
                                    component: "cellphone",
                                    value: null,
                                    children: [

                                    ]
                                },
                                {
                                    title: "E-mail",
                                    placeholder: "Digite",
                                    type: "text",
                                    required: true,
                                    component: "email",
                                    value: null,
                                    children: [

                                    ]
                                },
                                {
                                    title: "Escolha o Seguro",
                                    placeholder: "Selecione",
                                    type: "text",
                                    required: true,
                                    component: "interest1Title",
                                    value: null,
                                    children: [

                                    ]
                                },
                                {
                                    title: "Insira seu cupom",
                                    placeholder: "Digite",
                                    type: "text",
                                    required: true,
                                    component: "coupon",
                                    value: null,
                                    children: [

                                    ]
                                },
                                {
                                    title: "Declaração de ciência",
                                    placeholder: "<p><strong><span>Declaro ci&ecirc;ncia sobre o tratamento dos dados inseridos por mim no formul&aacute;rio, na forma estabelecida pela&nbsp;</span><a href=\"https://www.allianz.com.br/politicas-do-sistema.html#privacidade\" target='_blank'><span>Politica de Privacidade</span></a><span>&nbsp;e&nbsp;</span><a href=\"https://www.allianz.com.br/politicas-do-sistema.html#cookies\" target='_blank'><span>Politica de Cookies</span></a><span>&nbsp;da Allianz.</span></strong><span>&nbsp;Autorizo a Allianz a compartilhar minhas informa&ccedil;&otilde;es com corretores credenciados para cota&ccedil;&atilde;o de produtos e servi&ccedil;os. Al&eacute;m disso, aceito receber comunica&ccedil;&otilde;es sobre promo&ccedil;&otilde;es da Allianz e seus parceiros.</span></p>",
                                    type: "2",
                                    required: true,
                                    component: "consent",
                                    value: null,
                                    children: [

                                    ]
                                }
                            ]
                        },
                    ]
                }
            }
        },
        {
            handle: "banners",
            fragment: "outros-seguros",
            image_direction: "left",
            data: {
                text: "<p><span style=\"color:#003781\"><strong>Proteja tamb&eacute;m sua casa ou apartamento</strong></span></p>",
                sub_text: "",
                description: "<p><span style=\"color:#003781\">A partir de&nbsp;</span><span style=\"color:#f62459\"><strong>R$15,00</strong></span><span style=\"color:#003781\"><strong>&nbsp;por m&ecirc;s!</strong><br />\r\nVoc&ecirc; pode pagar em at&eacute;&nbsp;<strong>10x sem juros no d&eacute;bito e conta</strong>, e proteger seu lar, seja alugado ou pr&oacute;prio.</span></p>",
                sub_description: "",
                text_legal: "",
                image: 'https://nyc3.digitaloceanspaces.com/valide-dev/CMS-cote-allianz/5d5fd57c-e91b-4e33-9f68-1efe0270cdc9.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=XX2M4JTBQV3AFFHU3YDY%2F20240219%2Fnyc3%2Fs3%2Faws4_request&X-Amz-Date=20240219T125503Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=079c58e5051799d0805138c5f79e5d3dc22fd586297f60a4526d5469b8008259',
                alt_text: "",
                button: {
                    background: "#003781",
                    background_hover: "#122b54",
                    text: "VEM SABER MAIS",
                    text_color: "#ffffff",
                    text_color_hover: "#ffffff",
                    link_url: "https://cote-allianz-integrada.valide.vc/allianz/residencia",
                    link_external: true
                }
            }
        },
        {
            handle: "content",
            title: "HUB de Produtos Allianz",
            description: "HUB de Produtos Allianz",
        },
        {
            handle: "sections_tabbannersection",
            title: "HUB de Produtos Allianz",
            data: {
                text: "<p><span style='color:#fff;'>Conheça todos os benefícios e viva o Melhor da Fórmula E com a Allianz Seguros!</span></p>",
                background_color: "#ffffff",
                description: "",
                banners: [
                    {
                        title: "Auto",
                        text: "<p>Proteja seu carro em até <br><span> 10x sem juros!</span></p>",
                        sub_text: "<p>No cartão de crédito ou até 6x sem juros no débito em conta.</p>",
                        description: "<p>Protegemos o seu carro contra Roubo, furto e perda total, colisão, alagamento e muito mais. Você ainda pode contar com guincho de km ilimitada, até 30 dias de carro reserva e pequenos reparos no seu veículo.</p> <p><strong>Faça já sua cotação!</strong></p>",
                        sub_description: "",
                        image: '/assets/images/formulae-image-auto.png',
                        alt_text: null,
                        draft: true,
                        title_card: "Conheça as nossas coberturas e assistências*",
                        disclaimer: "*Algumas coberturas e serviços possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.",
                        cards: [
                            {
                                alt_text: '',
                                description: '<p>Caso algum destes incidentes aconteçam, você recebe a indenização de acordo com a modalidade contratada.</p>',
                                image: '/assets/images/formulae-icon-1.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.002216/2004-57. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Roubo, Furto ou Perda Total</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Reparamos o seu veículo e você pode contar com um valor de franquia reduzido.</p>',
                                image: '/assets/images/formulae-icon-2.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.002216/2004-57. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Colisão</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Problemas acontecem e você pode precisar de um guincho, chaveiro, troca de pneus, carga de bateria, reboque em caso de pane seca ou outro serviço.</p>',
                                image: '/assets/images/formulae-icon-3.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.002216/2004-57. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Assistência Guincho com 200 Km ou Km livre </strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>A gente não pode controlar o tempo, nem as chuvas, alagamentos, granizo e outros eventos naturais, por isso, cobrimos tudo isso e muito mais!</p>',
                                image: '/assets/images/formulae-icon-4.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.002216/2004-57. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Alagamento e outros eventos da natureza</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Para aquelas horas que você precisa acionar alguma cobertura do seu seguro e fica sem o seu carro temporariamente. Fique tranquilo, o nosso seguro conta com essa ajudinha.</p>',
                                image: '/assets/images/formulae-icon-5.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.002216/2004-57. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Até 30 dias de carro reserva</strong></span></p>',
                                type: 'box',
                            },
                        ]
                    },
                    {
                        title: "Residência",
                        text: "<p>Todos os cuidados para a sua casa, a partir de <br><span>R$15,00* por mês</span></p>",
                        sub_text: "<p>Protegemos sua casa ou apartamento contra roubo e furto qualificado, incêndio, danos elétricos e muito mais</p>",
                        description: "<p>Além disso, você também pode contar com assistência PET, assistência BIKE, Check-up Lar, proteção de bens como bicicletas, celulares, filmadoras e muitos outros serviços de Assistência 24h.</p><p><strong>Faça já sua cotação!</strong></p><p><span style='font-size: 16px;'>*Solicite ao corretor as informações sobre os valores e condições da apólice que deseja contratar.</span></p>",
                        sub_description: "",
                        image: '/assets/images/formulae-image-residencia.png',
                        alt_text: null,
                        draft: false,
                        title_card: "Conheça as nossas coberturas e assistências*",
                        disclaimer: "*Algumas coberturas e serviços possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.",
                        cards: [
                            {
                                alt_text: '',
                                description: '<p>De susto já basta o roubo. Com a Allianz é só notícia boa! Essa cobertura vai amparar os prejuízos com relação a subtração dos bens que estavam dentro da sua casa, assim como os possíveis prejuízos causados ao imóvel quando há arrombamentos ou outros rompimentos para ocorrer o furto qualificado.</p>',
                                image: '/assets/images/formulae-icon-1.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº.15414.100898/2004-62. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Roubo e furto qualificado</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Essa é a garantia chamada de Básica do seguro, e sua contratação é automática.</p><p>Para Incêndio, a cobertura garante proteção para os danos materiais causados pelo fogo que se propaga com intensidade.</p>',
                                image: '/assets/images/formulae-icon-8.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº.15414.100898/2004-62. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Incêndio</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Essa cobertura ampara os prejuízos em casos de roubo ou furto qualificado que ocorram fora da residência. Protegemos bens como bicicletas, celulares, filmadoras e máquinas fotográficas, notebook, tablet, joias e relógios de pulso, dentro dos limites de cada um desses produtos.</p><p>*Somente em território nacional</p>',
                                image: '/assets/images/formulae-icon-7.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº.15414.100898/2004-62. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                text: '<p><span style=\"color:#414141\"><strong>Roubo e furto de bens fora da residência</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Esse é para pai ou mãe de pet! Você conta com serviços incríveis como aplicação de vacina em domicílio, envio de ração, consulta veterinária ou emergencial, hospedagem e muito mais estão garantidos para o seu cão ou gato.</p>',
                                image: '/assets/images/formulae-icon-6.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº.15414.100898/2004-62. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Assistência PET</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>A geladeira estragou? A lavadora de roupas parou de funcionar? Não se preocupe, com nosso seguro você tem profissionais para reparar eletrodomésticos como refrigeradores, fogão, micro-ondas, lavadora e secadora de roupa, e muitos outros.</p>',
                                image: '/assets/images/formulae-icon-12.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº.15414.100898/2004-62. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Conserto de eletrodomésticos e reparos elétricos e hidráulicos.</strong></span></p>',
                                type: 'box',
                            },
                        ]
                    },
                    {
                        title: "Vida",
                        text: "<p>Você pode aproveitar o hoje e proteger o amanhã a partir de <br> <span>R$ 19,90* por mês</span></p>",
                        sub_text: "",
                        description: "<p>Protegemos você e sua família com proteção completa e você ainda pode contar com assistência nutricional, desconto em medicamentos e 2ª opinião médica que garantem um amanhã mais tranquilo para quem você ama.</p><p><strong>Faça já sua cotação!</strong></p><p><span style='font-size: 16px;'>*Solicite ao corretor as informações sobre os valores e condições da apólice que deseja contratar.</span></p>",
                        sub_description: "",
                        image: '/assets/images/formulae-image-vida.png',
                        alt_text: null,
                        draft: false,
                        title_card: "Conheça as nossas coberturas e assistências*",
                        disclaimer: "*Algumas coberturas e serviços possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.",
                        cards: [
                            {
                                alt_text: '',
                                description: '<p>Com nossa assistência nutricional você poderá contar com Atendimento/ Consulta por telefone para:</p> <p>Cálculo do IMC (Índice de Massa Corporal) e anamnese específica para determinar antecedentes nutricionais, (patológicos, histórico familiar e culturais); Orientações para clientes com doenças crônicas, transtornos alimentares e gestantes;</p> <p>Saúde da gestante: Orientações para alimentação saudável, dicas e orientações para evitar náuseas, diabetes e hipertensão gestacional.</p><p>Saúde da Criança: orientações para os pais, baseado em dicas e receitas de alimentação saudável, alergias alimentares e culinária infantil para seus filhos;</p><p>Saúde da Mulher: Aspectos nutricionais relacionados ao ciclo menstrual, tensão pré-menstrual e menopausa.</p> <p>E MUITO MAIS!</p>*A Assistência Nutricional faz parte dos pacotes:  Essencial, Plus, Master e Exclusivo. </p>',
                                image: '/assets/images/formulae-icon-9.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.001289.2007-74. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Assistência Nutricional</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Você poderá contar nos casos de uma doença complexa coberta, o rápido acesso a um segundo parecer médico dado por um especialista de renome internacional.</p><p>*A Segunda opinião médica internacional está presente nos pacotes: Essencial, Plus, Master e Exclusivo.</p>',
                                image: '/assets/images/formulae-icon-10.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.001289.2007-74. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Segunda opinião médica</strong></span></p>',
                                type: 'box',
                            },
                            {
                                alt_text: '',
                                description: '<p>Com uma rede que conta com mais de 9.000 (*) farmácias, em todo o território nacional, você poderá contar com uma moderna estrutura tecnológica e organizacional para a obtenção de descontos de até 59% em medicamentos.</p><p>*Essa assistência somente está disponível nos pacotes: Essencial, Plus, Master e Exclusivo.</p>',
                                image: '/assets/images/formulae-icon-11.png',
                                new_card: false,
                                legal_text: 'Processo SUSEP nº 15414.001289.2007-74. Consulte mais informações no site http://www.allianz.com.br. Algumas coberturas, serviços e assistências possuem restrições, carência e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais. A aceitação da proposta do seguro está sujeita à análise do risco. O registro do produto é automático e não representa aprovação ou recomendação por parte da SUSEP.',
                                sub_text: '',
                                text: '<p><span style=\"color:#414141\"><strong>Desconto em medicamentos</strong></span></p>',
                                type: 'box',
                            },
                        ]
                    }

                ]
            }
        },
        {
            handle: "benefits",
            fragment: "vantagens-e-facilidades",
            title: "E olha só essas facilidades",
            data: {
                background_section: "#ffffff",
                text_title_color: "#003781",
                image_flashy: "/assets/images/formulae-facilidades.jpg",
                alt_text: "",
                subtitle: "",
                benefits_items: [
                    {
                        benefits_description: 'Precisou de guincho ou socorro mecânico? É só chamar a gente pelo whatsapp',
                        benefits_description_color: '#003781',
                        benefits_icon: '/assets/images/formulae-facilidades-icon-2.png',
                        benefits_title: 'Assistência pelo whatsapp',
                        benefits_title_color: '#003781',
                    },
                    {
                        benefits_description: 'Cliente Allianz tem descontos imperdíveis em diversas lojas parceiras, como Centauro, Sephora, Petz e muitas outras lojas.',
                        benefits_description_color: '#003781',
                        benefits_icon: '/assets/images/formulae-facilidades-icon-3.png',
                        benefits_title: 'Clube de descontos Allianz',
                        benefits_title_color: '#003781',
                    },
                    {
                        benefits_description: 'Consulte as principais informações de sua apólice auto, parcelas, boletos e muito mais!',
                        benefits_description_color: '#003781',
                        benefits_icon: '/assets/images/formulae-facilidades-icon-1.png',
                        benefits_title: 'Precisou? Tá na mão! O App Allianz Cliente Auto te ajuda com o seu Seguro Auto a qualquer hora.',
                        benefits_title_color: '#003781',
                    },
                ],
            }
        },

    ]

    constructor(
        private store: Store<AppState>,
        private followizeService: FollowizeService,
        private helpers: HelpersService,
        private modal: NgbModal,
        public router: ActivatedRoute,
        private landpageService: LandPagesService,
        private formBuilder: FormBuilder,
        public activatedRouter: ActivatedRoute,
        private toast: ToastrService,
        private couponService: CouponService,

    ) {
        if (this.router.snapshot.routeConfig?.path === 'formulae') {
            this.form = this.formBuilder.group({
                event: ['Fórmula e'],
                product: null,
                hubUtmz: null,
                api_key: ['01cf0ac2-28b5-4baa-8353-14171955d8c4'],
                team_key: ['71ddae38-93b4-4cb9-aa15-3b5bd0ee9618'],
                name: [null, [Validators.required]],
                coupon: null,
                email: [null, [Validators.required, Validators.email]],
                cellphone: [
                    null,
                    [Validators.required, GenericValidatorService.validatePhone],
                ],
                interest1Title: [null, [Validators.required]],
                consent: [false, [Validators.requiredTrue]],
            })
        }
        this.watchChangesForm()
    }

    toggleForm() {
        if(!this.activeForm) {
            this.activeForm = true
        } else {
            this.activeForm = false
        }

        window.dataLayer.push({
            event: 'clique_fechar_aba_form',
            eventCategory: '',
            eventAction: 'click',
            eventLabel: '',
        })
    }

    ngOnInit(): void {
        this.slug = window.location.pathname
        const header = document.getElementById("anchor-mobile");

        if (window.location.href.split('?').length > 1) {
            this.pathParams = '?'+window.location.href.split('?')[1]
            this.hubUtm = this.helpers.formatUtmz(window.location.href)
            this.form.controls['hubUtmz'].setValue(this.hubUtm)
        }

        if(header) {
            header.classList.add("d-none");
        }
    }

    backStep(): void {
        this.disabled = false
        this.step--
    }
    openModal(): void {
        this.modal.open(ModalTooltipComponent, {
            modalDialogClass: 'modal-tooltip',
        })
    }
    changeRadio(event: any): any {
        if (event) {
            this.form.get('name')?.markAsTouched()
            this.form.get('email')?.markAsTouched()
            this.form.get('cellphone')?.markAsTouched()
        }
    }
    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    isFieldValid(field: string): boolean {
        const form = this.form
        // @ts-ignore
        return form.get(field).valid
    }

    changeEmail(email: string) {
        var re = /\S+@\S+\.\S+/
        if (email) {
            return re.test(email)
        } else {
            return true
        }
    }

    watchChangesForm(): void {
        if (this.router.snapshot.routeConfig?.path === 'formulae') {
            const self = this

            self.form.valueChanges.subscribe((data) => {
                if (
                    self.step === 1 &&
                    self.isFieldValid('name') &&
                    self.isFieldValid('coupon') &&
                    self.isFieldValid('email') &&
                    self.isFieldValid('cellphone') &&
                    self.isFieldValid('interest1Title') &&
                    self.isFieldValid('consent')
                ) {
                    self.disabled = false
                }
            })
        }
    }

    openModalSuccess(): void {
        const modalRef = this.modal.open(ModalCouponComponent, {
            modalDialogClass: 'modal-coupon',
        })

        modalRef.componentInstance.figure = '/assets/images/formulae-icon-check.png'
        modalRef.componentInstance.titleModal = 'Tudo pronto!'
        modalRef.componentInstance.description = 'Em até 1 dia útil você receberá o contato do nosso corretor parceiro, que vai solicitar mais alguns dados para concluir a cotação e enviar a oferta ideal para você!'
    }

    openModalCoupon(data: any): void {
        const modalRef = this.modal.open(ModalCouponComponent, {
            modalDialogClass: 'modal-coupon',
        })

        if(data.response === 'error') {
            modalRef.componentInstance.description = data.message
            modalRef.componentInstance.buttonText = 'TENTE NOVAMENTE'
        } else {
            modalRef.componentInstance.figure = '/assets/images/formulae-icon-cupom.png'
            modalRef.componentInstance.titleModal = 'Cupom de desconto aplicado'
            modalRef.componentInstance.description = data.description
        }
    }

    couponValue(value: string) {
        this.coupon = value
    }

    getCoupon(value: string, submit: boolean): void {
        const self = this

        if (!self.loadingDataCoupon) {
            self.loadingDataCoupon = true

            self.couponService.getCoupon(value.toLowerCase(), {
                fnSuccess(data?: any) {
                    // self.coupon = value

                    if(submit && data.response === 'error') {
                        self.validCoupon = false
                        self.openModalCoupon(data)
                    }

                    if(data.response === 'error') {
                        self.validCoupon = false
                    } else {
                        self.validCoupon = true
                        self.openModalCoupon(data)
                    }
                },
                fnError(error) {
                    self.validCoupon = false
                },
                fnFinalized() {
                    self.loadingDataCoupon = false
                },
            })
        }
    }

    onSubmit(): void {
        const self = this
        const data = self.form.value
        const form_section: Array<any> = self.data.filter((handle: any) => handle.handle == 'form_section')

        if(!self.validCoupon && self.coupon !== '') {
            self.getCoupon(self.coupon, true)
        }

        if (self.form.valid && self.validCoupon || self.form.valid && self.coupon === '') {
            self.disabled = true
            self.store.dispatch(ShowLoading())
            const formData = new FormData()


            for (let key in data) {
                if (key === 'consent') {
                    formData.append(key, 'on')
                } else if (key === 'product') {
                    formData.append(key, data.interest1Title)
                } else {
                    formData.append(key, data[key] ? data[key] : '')
                }
            }

            self.followizeService.sendFollowizeEvent('', formData, {
                fnSuccess(data?: any) {
                    window.dataLayer.push({
                        event: 'sendlead',
                        eventCategory: 'lead',
                        eventAction: 'send',
                        eventLabel: '',
                    })

                    window.dataLayer.push({
                        event: 'sendlead_formulae',
                        eventCategory: 'lead',
                        eventAction: 'send',
                        eventLabel: '',
                    })

                    window.gtag('event', 'conversion', {
                        send_to: 'AW-1031001625/En43CLyhiuEDEJmsz-sD',
                        event_callback: window.location,
                    })
                    self.fullRequest = true

                    self.openModalSuccess()
                },
                fnError(error) {
                    self.formFeedback = {
                        title: 'Ops!',
                        subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
                        title_form: ''
                    }
                    self.fullRequest = true
                },
                fnFinalized() {
                    self.step = 3
                    self.form.reset()
                    self.store.dispatch(HiddenLoading())
                },
            })
        } else {
            self.disabled = false
        }
    }
}

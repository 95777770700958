<div class='control'>
    <div class='form-group'>
        <label [for]="'i-' + id" [class.default]='!labelName' [formGroup]='form'>
			<span *ngIf='labelName && showLabel' class='custom-label control-label'>{{
                labelName
                }}</span>
            <input
                #element
                [id]="'i-' + id"
                [type]="!showPass ? typeInput : 'text'"
                [attr.name]='name'
                [formControlName]='name'
                [placeholder]='placeholder || ""'
                [setValue]='inputModel'
                [mask]='inputMask'
                (keyup)='onChange($event, element.value)'
                (blur)='onBlur($event, element.value)'
                (keydown)="keyDownHandler($event)"
                (keydown)="changeMask($event)"
                class='form-control custom-input'
                [ngClass]="{'mt-0': !showLabel}"
                [maxlength]='max ? max : 50'
                [attr.disabled]="disabled == true ? true : null"
            />

            <button
                *ngIf="typeInput == 'password'"
                (click)='toggleInputPass()'
                type='button'
                class='show-pass'
                [class.active]='showPass'
                data-input='name'
            >
                <figure>
                    <img src='/assets/images/eye.svg' alt='eye' />
                </figure>
            </button>
        </label>
        <span *ngIf='callout' class='callout'>{{ callout }}</span>
        <span
            *ngIf='form.controls[name || ""].invalid &&
             (form.controls[name || ""].touched || form.controls[name || ""].dirty)'
            class='control-error'
            [setFormControlError]='{objError: form.controls[name || ""].errors, control: labelName}'
        >
        </span>
    </div>
</div>

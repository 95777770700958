<div class="safe">
  <div class="know-more-wrapper-tab">
    <p class="know-more-wrapper-tab-title" style="font-size: 45px" [innerHTML]="tab.text | htmlParse"></p>
    <p class="know-more-wrapper-tab-subtitle" [innerHTML]="tab.sub_text | htmlParse"></p>
    <div class="know-more-wrapper-tab-text" [innerHTML]="tab.description | htmlParse"></div>
    <a *ngIf='tab.button' [routerLink]="tab.button.link_url.split(splitPoint)[1]" queryParamsHandling="preserve" class="buttonChanger button-tab-ga-js" [attr.data-text]="tab.title">
        Quero uma cotação
    </a>
    <p class="know-more-wrapper-tab-legaltext" style="font-size: 12px" [innerHTML]="tab.sub_description | htmlParse"></p>
  </div>
  <app-image class="know-more-wrapper-img" [path]="tab.image" [title]="tab.title | htmlParse" [alt]="tab.alt_text"></app-image>
</div>

<ul class="nav nav-tabs">
	<div class="nav-area">
		<li
			*ngFor="let tab of tabs let i = index"
			(click)="selectTab(tab)"
			[class.active]="tab.active"
		>
			<button type="button" class="btn btn-primary btn-tab-js">{{ tab.title }}</button>
		</li>
	</div>
</ul>
<ng-content></ng-content>

import { Component, OnInit } from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'
import { Location } from '@angular/common'
import { AppState } from 'src/app/state/app-state'
import { Store } from '@ngrx/store'
import {HelpersService} from "../../services/helpers/helpers.service";

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	uri: any
	data: any = null

	constructor(public router: Router, private location: Location, public store: Store<AppState>, public helpers: HelpersService) {}

	ngOnInit(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.uri = this.location.path()
			}
		})
		setTimeout(() => {
			this.store.subscribe((item) => {
				this.data = item.globals.footer
			})
		}, 2000);
	}
}

<button
    class="button-card"
    [attr.data-modalTitle]="transformText(data.text)"
    [attr.data-modalDescription]="transformText(data.description)"
    [ngClass]="classCustom"
    (click)="openModal()"
>
    <img
        *ngIf="data.new_card"
        class="selo"
        src="/assets/images/selo-novidade.png"
        alt="selo-novidade"
        title="selo-novidade"
    />
    <app-image [path]="data.image" [title]="data.text" [alt]="data.alt_text" [lazy]="false"></app-image>
    <h3 *ngIf="data.text" [innerHTML]="data.text | htmlParse"></h3>
    <div *ngIf="data.sub_text" [innerHTML]="data.sub_text | htmlParse" class="sub_text"></div>
    <span>ver mais +</span>
</button>

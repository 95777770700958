import { Component, Input, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { Event, NavigationEnd, Router } from '@angular/router'
import { ILandingpage } from 'src/app/models/landingpage/landingpage'
// import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AppState } from 'src/app/state/app-state'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input() data?: any = null
	isScrolled: boolean = false
    isScrolledMenu: boolean = false
    isMobile: boolean = false
	uri: any

	constructor(
		public router: Router,
		private location: Location,
		public store: Store<AppState>
	) {

	}

	ngOnInit(): void {
		this.scrollHeader()
		this.scrollMenu()
		window.addEventListener('scroll', this.scrollHeader.bind(this))
		window.addEventListener('scroll', this.scrollMenu.bind(this))

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.uri = this.location.path().split('?')[0]
			}
		})
		this.store.subscribe((item) => {
			this.data = item.globals.header
		})
	}

	scrollHeader(): void {
		const scrollTop = window.scrollY || document.documentElement.scrollTop
		this.isScrolled = scrollTop > 50
	}

    scrollMenu(): void {
        const scrollTop = window.scrollY || document.documentElement.scrollTop
        this.isMobile = screen.width < 576

        if (screen.width > 576) {
            if (scrollTop > 780) {
                this.isScrolledMenu = true
                // @ts-ignore
                document.getElementById('wrapper-menu-anchor').innerHTML = document.getElementById('anchor-menu').innerHTML
            } else {
                this.isScrolledMenu = false
                // @ts-ignore
                document.getElementById('wrapper-menu-anchor').innerHTML = ''
            }
        }
    }
}

<div class="modal-content">
    <button
        type="button"
        class="close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <h4 class="modal-description mb-0">
            Vale saber! Se o seu veículo é utilizado em aplicativo de transporte de passageiros, no momento não estamos preparados para protegê-lo.
        </h4>
    </div>
</div>

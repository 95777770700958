import { ActionModel } from '../action'
import { ActionGlobalsTypes } from './globals.action'
import {IGlobals} from "../../models/landingpage/globals";

const initialState: IGlobals = {
    header: {
        logo: {
			image: '',
			text: '',
			description: '',
			link_text: '',
			link_external: false,
		},
        logo_opcional: {
			image: '',
			text: '',
			description: '',
			link_text: '',
			link_external: false,
		},
        background_color: '',
    },
    footer: {
        background_color: '',
        image: {
			image: '',
			text: '',
			description: '',
			link_text: '',
			link_external: false,
		},
        icons: [],
        links: [],
    },
}

export function globalsReducer(state: IGlobals = initialState, action: ActionModel) {
    switch (action.type) {
        case ActionGlobalsTypes.Add: {
            state = { ...state, ...action.payload }
            return state
        }

        case ActionGlobalsTypes.Clear: {
            state = initialState
            return state
        }

        default:
            return state
    }
}

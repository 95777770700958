<div [id]="idElement" class="know-more-content" [ngClass]="{'is-even': isEven, 'is-other': isOther}">
    <app-image [path]="img ? img : data.image" [title]="title ? title : data.text" [alt]="altText ? altText : ''"></app-image>
    <div class="know-more-wrapper" #ref><ng-content ></ng-content></div>
    <div class="know-more-wrapper" *ngIf=" ! ref.children.length">
        <div class="title" [innerHTML]="title || data.text | htmlParse"></div>
        <div class="subtitle" [innerHTML]="text || data.description | htmlParse"></div>
        <div *ngIf="link || data.button">
            <a [routerLink]="link || data.button.link_url.split(splitPoint)[1]" queryParamsHandling="preserve" class="buttonChanger">
                Vem saber mais
            </a>
            <!--            <a (click)="redirecionando(link || data.button.link_url.split(splitPoint)[1])" class="buttonChanger">-->
            <!--                Vem saber mais-->
            <!--            </a>-->
        </div>
    </div>
</div>

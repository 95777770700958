<div class="section-banner-text py-5">
    <div class="wrapper-container">
        <div class="container">
            <h2 class="title-section">{{ title }}</h2>
            <div class="row align-items-md-center mb-5">
                <div
                    *ngIf="img"
                    class="col-12 col-md-6 col-lg-5 ps-md-4 ps-lg-5"
                >
                    <app-image [path]="img" [title]="title" [alt]="alt_text" [lazy]="false"></app-image>
                </div>
                <div
                    *ngIf="services"
                    class="col-12 col-md-6 col-lg-7 pe-md-5 ps-lg-5"
                >
                    <div *ngFor="let service of services" class="service">
                        <app-image [path]="service.benefits_icon" [title]="service.benefits_title" [alt]="service.alt_text"></app-image>
                        <div class="text">
                            <h3 class="description__title" [innerHTML]="service.benefits_title | htmlParse"></h3>
                            <p class="description__subtitle" [innerHTML]="service.benefits_description | htmlParse"></p>
                        </div>
                    </div>

                    <p class='safe-subtitle' [innerHTML]="subtitle | htmlParse"></p>
                </div>
            </div>
        </div>
    </div>
</div>

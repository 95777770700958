import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Route, Router, RouterEvent } from '@angular/router'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-toggle-safe-renovation',
	templateUrl: './toggle-safe-renovation.component.html',
	styleUrls: ['./toggle-safe-renovation.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ToggleSafeRenovationComponent implements OnInit {
	@Input() title1?: string = ''
	@Input() text1?: string = ''
	@Input() titleFooter1?: string = ''
	@Input() img1: string = ''
	@Input() link1?: string = ''
	@Input() isEven1: boolean = false
	@Input() isOther1: boolean = false
	@Input() idElement1: string = 'idPadrao'
	@Input() data1?: any
	@Input() textFooter1?: string = ''
	public splitPoint = ''

	@Input() title2?: string = ''
	@Input() text2?: string = ''
	@Input() img2: string = ''
	@Input() link2?: string = ''
	@Input() isEven2: boolean = false
	@Input() isOther2: boolean = false
	@Input() idElement2: string = 'idPadrao'
	@Input() data2?: any
	@Input() textFooter2?: string = ''
	@Input() titleFooter2?: string = ''

	constructor(private sanitizer: DomSanitizer,
				private router: Router) {}

	ngOnInit (): void {
		this.splitPoint = environment.splitPoint
	}

	redirecionando (rota: string) {
		this.router.navigate([rota])
		setTimeout(() => {
			document.location.reload()
		}, 300);
	}

}

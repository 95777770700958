<ul class="nav nav-tabs">
	<li
		*ngFor="let tab of tabs"
		(click)="selectTab(tab)"
		[class.active]="tab.active"
		class="black-bg-xp-active"
	>
		<button type="button" class="btn btn-primary dark-gray-text-xp light-gray-bg-xp" [innerHTML]="tab.title | htmlParse"></button>
	</li>
</ul>
<ng-content></ng-content>

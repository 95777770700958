<div class="modal-content">
    <button
        type="button"
        class="close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body modal-no-support">
        <h4 class="modal-description mb-3">
            <strong>
                Obrigado pelo interesse no seguro Allianz! :)
            </strong>
        </h4>

        <h4 class="modal-description mb-0">
            No momento, não temos cobertura para veículos utilizados em transporte por aplicativos.
            <a href="http://allianz.com.br" target="_blank"> Visite nosso site</a> para explorar outras opções de Seguros disponíveis pra você.
        </h4>
    </div>
</div>

<div class="parceiros">
<!--    <div *ngIf="slug === '/'" class="toggle-safes">-->
<!--        <ngx-slick-carousel class="carousel my-carousel" #slickModal="slick-carousel" [config]="slideConfig">-->
<!--            <div ngxSlickItem class="slide">-->
<!--                <div class="know-more-content is-other">-->
<!--                    <div class="know-more-wrapper">-->
<!--                        <div class='text-area'>-->
<!--                            <div class="text-title">-->
<!--                                <p>-->
<!--                                    <span style="color:#003781">Seja um </span>-->
<!--                                    <strong>-->
<!--                                        <span style="color:#f62459">segurado Allianz</span>-->
<!--                                    </strong>-->
<!--                                    <span style="color:#003781"> e esteja sempre pronto para </span>-->
<!--                                    <span style="color:#f62459">-->
<!--                                        <strong>viver o melhor</strong>!-->
<!--                                    </span>-->
<!--                                </p>-->
<!--                            </div>-->

<!--                            <div class="text-description">-->
<!--                                <p>-->
<!--                                    <span style="color:#003781">-->
<!--                                        Cuidamos de você, da sua família e das suas conquistas. Com a Allianz você fica tranquilo para curtir a vida sabendo que seus bens e quem você ama estão protegidos.-->
<!--                                    </span>-->
<!--                                </p>-->

<!--                                <p>-->
<!--                                    <span style="color:#f62459">-->
<!--                                        Aproveite <strong>todos os benefícios</strong> da Allianz!-->
<!--                                    </span>-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <figure>-->
<!--                        <img-->
<!--                            src="/assets/images/banner-home-allianz-02.png"-->
<!--                            alt="side-banner"-->
<!--                            title="side-banner"-->
<!--                        />-->
<!--                    </figure>-->
<!--                </div>-->
<!--            </div>-->

<!--&lt;!&ndash;            <div ngxSlickItem class="slide">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="know-more-content is-other is-background" style='background-image: url("/assets/images/banner-home-allianz.png");'>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class='know-wrapper-image'>&ndash;&gt;-->
<!--&lt;!&ndash;                        <figure>&ndash;&gt;-->
<!--&lt;!&ndash;                            <img&ndash;&gt;-->
<!--&lt;!&ndash;                                src="/assets/images/promocao-allianz.png"&ndash;&gt;-->
<!--&lt;!&ndash;                                alt="promocao"&ndash;&gt;-->
<!--&lt;!&ndash;                                title="promocao"&ndash;&gt;-->
<!--&lt;!&ndash;                            />&ndash;&gt;-->
<!--&lt;!&ndash;                        </figure>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="know-more-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class='text-area'>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="text-description">&ndash;&gt;-->
<!--&lt;!&ndash;                                <p>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span style='color: #FAB600;'>e mais...</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </p>&ndash;&gt;-->
<!--&lt;!&ndash;                                <p>&ndash;&gt;-->
<!--&lt;!&ndash;                                    Ao contratar um Seguro Allianz você ainda concorre à <span style='color: #FAB600;'>Shows no Allianz Parque!</span> Quanto mais apólices participantes você tiver, mais chances você tem de<span style='color: #FAB600;'> ganhar!</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </p>&ndash;&gt;-->
<!--&lt;!&ndash;                                <a href='https://boraproshow.allianz.com.br/taylor-swift' target='_blank' class="button">&ndash;&gt;-->
<!--&lt;!&ndash;                                    saiba mais&ndash;&gt;-->
<!--&lt;!&ndash;                                </a>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </ngx-slick-carousel>-->
<!--    </div>-->

    <div *ngFor="let component of data let i = index">
        <div *ngIf="component.handle === 'banners'">
            <div class="toggle-safes">
                <app-toggle-safe
                    [img]="component.data.image"
                    [altText]="component.data.alt_text"
                    [title]="component.data.image"
                    [idElement]="component.data.image"
                    [isEven]="component.image_direction == 'right' ? true : false"
                    [isOther]="true">
                    <div class="text-area">
                        <p *ngIf="component.data && component.data.text" class="text-title" [innerHTML]="component.data.text | htmlParse"></p>
                        <p *ngIf="component.data && component.data.description" class="text-description" [innerHTML]="component.data.description | htmlParse"></p>
                        <p *ngIf="component.data && component.data.sub_description" class="text-subdescription" [innerHTML]="component.data.sub_description | htmlParse"></p>
                    </div>
                </app-toggle-safe>
            </div>
        </div>

        <div class="pt-5" *ngIf="component.handle === 'sections_tabbannersection'">
            <h2 class="title-tab" [innerHTML]="component.data.text | htmlParse"></h2>
            <app-tabs-rounded>
                <app-tab-rounded *ngFor="let tab of component.data.banners let i = index" [tabTitle]="tab.title" [active]="i == component.data.banners.length - 1">
                    <div >
                        <app-tab-description [tab]="tab"></app-tab-description>
                    </div>
                </app-tab-rounded>
            </app-tabs-rounded>
        </div>

        <div *ngIf="component.handle === 'content_section'">
            <app-olimpycs></app-olimpycs>
        </div>

        <div class="avanti-palestra light-gray-bg-xp" *ngIf="component.handle === 'content_section'">
            <div class="avanti-palestra-texts">
                <div class="avanti-palestra-texts-title" [innerHTML]="component.text | htmlParse"></div>
                <div style="max-width: 475px;" [innerHTML]="component.description | htmlParse"></div>
            </div>
        </div>
    </div>
</div>

<header class='scroll-on'>
<!--    [ngClass]="{'scroll-on': isScrolled}"-->
    <div class="wrapper-container">
        <div class="header-partner">
            <div class="header-partner__logo">
                <a [routerLink]="uri" queryParamsHandling="preserve">
                    <figure>
                        <img [src]="'/assets/images/logo-olimpiadas-2.svg'" alt="Allianz">
                    </figure>
                    <!-- <figure>
                        <img [src]="data.logo.image || '/assets/images/logo.svg'" alt="Allianz">
                    </figure> -->
                </a>
            </div>
            <figure *ngIf="data.logo_opcional && data.logo_opcional.image" class="header-partner__figure">
                <img [src]="data.logo_opcional.image" alt="Allianz">

                <!-- <img [src]="'/assets/images/logo-olimpiadas-2.svg'" alt="Allianz"> -->
            </figure>

            <div [ngClass]="isScrolledMenu && !isMobile ? 'd-flex' : 'd-none'" id="wrapper-menu-anchor" class='wrapper-anchor container'></div>
        </div>
    </div>
</header>

<div class="modal-content">
    <button
        type="button"
        class="close"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <h4 class="modal-description mb-0">
            Digite a sua profissão e ao encontrá-la, selecione a opção correspondente. Se a sua profissão não retornar na busca significa que no momento não estamos preparados para protegê-la. Agradecemos o seu interesse na Allianz e torcemos para que em breve seja possível ter você aqui com a gente!
        </h4>
    </div>
</div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-modal-professional',
	templateUrl: './modal-professional.component.html',
	styleUrls: ['./modal-professional.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModalProfessionalComponent implements OnInit {
	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
	close() {
		this.activeModal.close()
	}
}

import { AbstractControl, ValidationErrors } from '@angular/forms'

export class GenericValidatorService {
	constructor() {}

	static isValidCpf(control: AbstractControl): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (value === undefined) return { cpfInvalid: true }
			const cpf = value.replace(/[^\d]+/g, '')
			let Soma
			let Resto
			Soma = 0
			if (
				cpf.length !== 11 ||
				cpf === '00000000000' ||
				cpf === '11111111111' ||
				cpf === '22222222222' ||
				cpf === '33333333333' ||
				cpf === '44444444444' ||
				cpf === '55555555555' ||
				cpf === '66666666666' ||
				cpf === '77777777777' ||
				cpf === '88888888888' ||
				cpf === '99999999999'
			)
				return { cpfInvalid: true }

			for (let i = 1; i <= 9; i++)
				Soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
			Resto = (Soma * 10) % 11

			if (Resto === 10 || Resto === 11) Resto = 0

			if (Resto !== parseInt(cpf.substring(9, 10)))
				return { cpfInvalid: true }

			Soma = 0

			for (let i = 1; i <= 10; i++)
				Soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
			Resto = (Soma * 10) % 11

			if (Resto === 10 || Resto === 11) Resto = 0

			if (Resto !== parseInt(cpf.substring(10, 11)))
				return { cpfInvalid: true }
		}
		return null
	}

    static isValidCPForCNPJ(control: AbstractControl): ValidationErrors | null {
		const { value }  = control

		if (value) {
            if (value === undefined) return { cpfInvalid: true }

            if(value.length <= 11) {
                const cpf = value.replace(/[^\d]+/g, '')
                let Soma
                let Resto
                Soma = 0
                if (
                    cpf.length !== 11 ||
                    cpf === '00000000000' ||
                    cpf === '11111111111' ||
                    cpf === '22222222222' ||
                    cpf === '33333333333' ||
                    cpf === '44444444444' ||
                    cpf === '55555555555' ||
                    cpf === '66666666666' ||
                    cpf === '77777777777' ||
                    cpf === '88888888888' ||
                    cpf === '99999999999'
                )
                    return { cpfInvalid: true }

                for (let i = 1; i <= 9; i++)
                    Soma += parseInt(cpf.substring(i - 1, i)) * (11 - i)
                Resto = (Soma * 10) % 11

                if (Resto === 10 || Resto === 11) Resto = 0

                if (Resto !== parseInt(cpf.substring(9, 10)))
                    return { cpfInvalid: true }

                Soma = 0

                for (let i = 1; i <= 10; i++)
                    Soma += parseInt(cpf.substring(i - 1, i)) * (12 - i)
                Resto = (Soma * 10) % 11

                if (Resto === 10 || Resto === 11) Resto = 0

                if (Resto !== parseInt(cpf.substring(10, 11)))
                    return { cpfInvalid: true }

                return null;
            }
            else {
                let cnpj = value.replace(/[^\d]+/g,'');

                if(cnpj == '') return { cnpjInvalid: true }

                if (cnpj.length != 14)
                    return { cnpjInvalid: true }

                // Elimina CNPJs invalidos conhecidos
                if (cnpj == "00000000000000" ||
                    cnpj == "11111111111111" ||
                    cnpj == "22222222222222" ||
                    cnpj == "33333333333333" ||
                    cnpj == "44444444444444" ||
                    cnpj == "55555555555555" ||
                    cnpj == "66666666666666" ||
                    cnpj == "77777777777777" ||
                    cnpj == "88888888888888" ||
                    cnpj == "99999999999999")
                    return { cnpjInvalid: true }

                // Valida DVs
                let tamanho = cnpj.length - 2
                let numeros = cnpj.substring(0,tamanho);
                let digitos = cnpj.substring(tamanho);
                let soma = 0;
                let pos = tamanho - 7;
                for (let i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--;
                  if (pos < 2)
                        pos = 9;
                }
                let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(0))
                    return { cnpjInvalid: true }

                tamanho = tamanho + 1;
                numeros = cnpj.substring(0,tamanho);
                soma = 0;
                pos = tamanho - 7;
                for (let i = tamanho; i >= 1; i--) {
                  soma += numeros.charAt(tamanho - i) * pos--;
                  if (pos < 2)
                        pos = 9;
                }
                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(1))
                    return { cnpjInvalid: true }

                return null;
            }

        }
        return null
	}

	static completeName(control: AbstractControl): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (!/\w+\s+\w{2,}/.test(value)) {
				return { completeNameInvalid: true }
			}
		}
		return null
	}

	static validateCep(control: AbstractControl): ValidationErrors | null {
		let { value } = control
		if (value) {
			value = value.replace(/\D/g, '')
			if (value !== '') {
				if (!/^[0-9]{8}$/.test(value))
					return { validateCepInvalid: true }
			}
		}
		return null
	}

	static validateDate(control: AbstractControl): ValidationErrors | null {
		let { value } = control

		if (value && value.length >= 8) {
			const day = parseInt(value.substring(0, 2), 10)
			const month = parseInt(value.substring(2, 4), 10)
			const year = parseInt(value.substring(4, 8), 10)

			if (year < 1900 || year > 3000 || month === 0 || month > 12) {
				return { dateInvalid: true }
			}

			const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

			if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
				monthLength[1] = 29
			}

			if (!(day > 0 && day <= monthLength[month - 1])) {
				return { dateInvalid: true }
			}
		}

		return null
	}

	static validatePhone(control: AbstractControl): ValidationErrors | null {
		let { value } = control
		if (value) {
			value = value.replace(/\D/g, '')
			if (value.length < 10) {
				return { phoneInvalid: true }
			}
			if (value.length === 10 && parseInt(value.substring(2, 3)) === 9) {
				return { phoneInvalid: true }
			}
			if (value.length === 11 && parseInt(value.substring(2, 3)) !== 9) {
				return { phoneInvalid: true }
			}
			const codigosDDD = [
				11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32,
				33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54,
				55, 61, 62, 64, 63, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79,
				81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97,
				98, 99,
			]
			if (!codigosDDD.includes(parseInt(value.substring(0, 2)))) {
				return { phoneInvalid: true }
			}
		}

		return null
	}

	static passwordCustom(control: AbstractControl): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (
				!(
					/[a-z]+/.test(value) &&
					/[A-Z]+/.test(value) &&
					/\d+/.test(value) &&
					/[!@#$%^&*()]+/.test(value)
				)
			) {
				return { passwordCustomInvalid: true }
			}
		}
		return null
	}

	static atLeastOneLowerLetter(
		control: AbstractControl
	): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (!/[a-z]+/.test(value)) {
				return { atLeastOneLowerLetterInvalid: true }
			}
		}
		return null
	}

	static atLeastOneUpperLetter(
		control: AbstractControl
	): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (!/[A-Z]+/.test(value)) {
				return { atLeastOneUpperLetterInvalid: true }
			}
		}
		return null
	}

	static atLeastOneNumber(control: AbstractControl): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (!/\d+/.test(value)) {
				return { atLeastOneNumberInvalid: true }
			}
		}
		return null
	}

	static atLeastOneSymbol(control: AbstractControl): ValidationErrors | null {
		const { value } = control
		if (value) {
			if (!/[!@#$%^&*()]+/.test(value)) {
				return { atLeastOneSymbolInvalid: true }
			}
		}
		return null
	}

	static checkEmails(control: AbstractControl): ValidationErrors | null {
		return !!control.parent &&
			!!control.parent.value &&
			// @ts-ignore
			control.value === control?.parent?.controls['email']?.value
			? null
			: { notSameEmail: false }
	}
	static checkPasswords(control: AbstractControl): ValidationErrors | null {
		return !!control.parent &&
			!!control.parent.value &&
			// @ts-ignore
			control.value === control?.parent?.controls['password']?.value
			? null
			: { notSamePassword: false }
	}
}

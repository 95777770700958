import { Component, Input, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-tab-description',
	templateUrl: './tab-description.component.html',
	styleUrls: ['./tab-description.component.scss'],
})
export class TabDescriptionComponent implements OnInit {
	@Input() tab: any
	public splitPoint = ''
	constructor() {}

	ngOnInit(): void {
		this.splitPoint = environment.splitPoint
	}
}

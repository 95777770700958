import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-modal-clear-form',
  templateUrl: './modal-clear-form.component.html',
  styleUrls: ['./modal-clear-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalClearFormComponent implements OnInit {

    constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
	close() {
		this.activeModal.close()
	}

}

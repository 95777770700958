import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { StoreModule } from '@ngrx/store'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { NgxMaskModule } from 'ngx-mask'
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { SlickCarouselModule } from 'ngx-slick-carousel'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { stateReducers } from './state/reducers'

import { NotFoundComponent } from './pages/not-found/not-found.component'
import { InputComponent } from './components/form/input/input.component'
import { SelectComponent } from './components/form/select/select.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { RadioComponent } from './components/form/radio/radio.component'
import { TableListComponent } from './components/table-list/table-list.component'
import { ImageComponent } from './components/image/image.component'
import { TabComponent } from './components/tabs/tab/tab.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'
import { AuthInterceptorService } from './services/auth/auth-interceptor.service'
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { CheckboxCustomComponent } from './components/form/checkbox-custom/checkbox-custom.component'
import { HeaderComponent } from './components/header/header.component'
import { FooterComponent } from './components/footer/footer.component'
import { CardComponent } from './components/card/card.component'
import { BannerFormComponent } from './components/banner-form/banner-form.component'
import { ModalBenefitComponent } from './components/modals/modal-benefit/modal-benefit.component'
import { ModalCouponComponent } from './components/modals/modal-coupon/modal-coupon.component'
import { BannerTextComponent } from './components/banner-text/banner-text.component'
import { ModalTooltipComponent } from './components/modals/modal-tooltip/modal-tooltip.component'
import { CarouselBenefitsComponent } from './components/carousel-benefits/carousel-benefits.component'
import { ToggleSafeComponent } from './components/toggle-safe/toggle-safe.component'
import { SelectAutocompleteComponent } from './components/form/select-autocomplete/select-autocomplete.component'
import { TabsRoundedComponent } from './components/tabs-rounded/tabs-rounded.component'
import { TabRoundedComponent } from './components/tabs-rounded/tab-rounded/tab-rounded.component'
import { ModalModelComponent } from './components/modals/modal-model/modal-model.component'
import { ModalProfessionalComponent } from './components/modals/modal-professional/modal-professional.component'
import { ModalRegistrationComponent } from './components/modals/modal-registration/modal-registration.component'
import { InsurancesComponent } from './components/page-content/insurances/insurances.component'
import { PageContentComponent } from './components/page-content/page-content.component'
import { TabDescriptionComponent } from './components/tab-description/tab-description.component'
import { AssistsComponent } from './components/page-content/assists/assists.component'
import { FormulaeComponent } from './pages/formulae/formulae.component'
import { ParceirosInsuranceComponent } from './pages/parceiros-insurance/parceiros-insurance.component'
import { ProdutosInsuranceComponent } from './pages/produtos-insurance/produtos-insurance.component'
import { HtmlParsePipe } from './pipes/html-parse.pipe'
import { ModalAppCarComponent } from './components/modals/modal-appcar/modal-appcar.component'
import { ToggleSafeRenovationComponent } from './components/toggle-safe-renovation/toggle-safe-renovation.component'
import { ModalClearFormComponent } from './components/modals/modal-clear-form/modal-clear-form.component';
import { Olimpycs } from './components/olimpycs/olimpycs.component'

@NgModule({
	declarations: [
		AppComponent,
		NotFoundComponent,
		InputComponent,
		SelectComponent,
		SelectAutocompleteComponent,
		CheckboxComponent,
		TableListComponent,
		ImageComponent,
		TabComponent,
		TabsComponent,
		AccordionComponent,
		SetValueDirective,
		SetFormControlErrorDirective,
		ModalDefaultComponent,
		LoadingGlobalComponent,
		LoadingLocalComponent,
		LoadingProgressComponent,
		CheckboxCustomComponent,
		HeaderComponent,
		FooterComponent,
		CardComponent,
		BannerFormComponent,
		ModalBenefitComponent,
		ModalCouponComponent,
		BannerTextComponent,
		RadioComponent,
		ModalTooltipComponent,
		CarouselBenefitsComponent,
		ToggleSafeComponent,
		ToggleSafeRenovationComponent,
		TabsRoundedComponent,
		TabRoundedComponent,
		ModalModelComponent,
		ModalProfessionalComponent,
		ModalRegistrationComponent,
		ModalAppCarComponent,
		PageContentComponent,
		InsurancesComponent,
		TabDescriptionComponent,
		AssistsComponent,
		FormulaeComponent,
		ProdutosInsuranceComponent,
		ParceirosInsuranceComponent,
		ModalClearFormComponent,
		HtmlParsePipe,
        Olimpycs
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		LazyLoadImageModule,
		StoreModule.forRoot({ ...stateReducers }, {}),
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMaskModule.forRoot(),
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			positionClass: 'toast-top-right',
		}),
		SlickCarouselModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, Route, Router, RouterEvent } from '@angular/router'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-toggle-safe',
	templateUrl: './toggle-safe.component.html',
	styleUrls: ['./toggle-safe.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ToggleSafeComponent implements OnInit {
	@Input() title?: string = ''
	@Input() text?: string = ''
	@Input() altText?: string = ''
	@Input() img: string = ''
	@Input() link?: string = ''
	@Input() isEven: boolean = false
	@Input() isOther: boolean = false
	@Input() idElement: string = 'idPadrao'
	@Input() data?: any
	public splitPoint = ''

	constructor(private sanitizer: DomSanitizer,
				private router: Router) {}

	ngOnInit (): void {
		this.splitPoint = environment.splitPoint
	}

	redirecionando (rota: string) {
		this.router.navigate([rota])
		setTimeout(() => {
			document.location.reload()
		}, 300);
	}

}

import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
	selector: 'app-banner-text',
	templateUrl: './banner-text.component.html',
	styleUrls: ['./banner-text.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class BannerTextComponent implements OnInit {
	@Input() title: string = ''
	@Input() subtitle: string = ''
	@Input() alt_text: string = ''
	@Input() img: string = ''
	@Input() services: any[] = []
	@Input() link: string = ''
	@Input() background_color: string = ''
	@Input() text_title_color: string = ''

	constructor() {}

	ngOnInit(): void {}
}

import { Action } from '@ngrx/store'
import {IGlobals} from "../../models/landingpage/globals";

export enum ActionGlobalsTypes {
	Add = '@GLOBALS/ADD',
	Clear = '@GLOBALS/CLEAR',
}

export const AddGlobals = (payload: IGlobals) => {
	return <Action>{ type: ActionGlobalsTypes.Add, payload: payload }
}

export const ClearGlobals = () => {
	return <Action>{ type: ActionGlobalsTypes.Clear, payload: null }
}

import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { finalize, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as moment from 'moment'
import { environment } from '../../../environments/environment'
import { AuthResult } from './auth-result'
import { AddUser, ClearUser } from '../../state/user/user.action'
import { AppState } from '../../state/app-state'
import { Login } from '../../models/login'
import { Router } from '@angular/router'
import { HelpersService } from '../helpers/helpers.service'
import { ToastrService } from 'ngx-toastr'

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isLogin = false
    roleAs: string[] = ['public']

    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
        private router: Router,
        private helpers: HelpersService,
        private toast: ToastrService
    ) {
    }

    loginHasSessionUser(body: Login) {
        return this.http
            .post<any>(`${environment.api}/account/session`, body.payload)
            .pipe(
                finalize(() => {
                    if (body.fnFinalized) body.fnFinalized()
                })
            )
            .subscribe((data) => {
                body.fnSuccess(data)
            }, body.fnError)
    }

    login(body: Login) {
        body.payload = {
            ...body.payload,
            cpf: this.helpers.removeMaskCpf(body.payload.cpf)
        }

        return this.http
            .post<any>(`${environment.api}/account/login`, body.payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .pipe(
                finalize(() => {
                    if (body.fnFinalized) body.fnFinalized()
                })
            )
            .subscribe((data) => {
                const resultData: AuthResult = data.data
                this.setSession(resultData)
                body.fnSuccess()
            }, body.fnError)
    }

    refreshToken() {
        const token = this.getRefreshToken()
        return this.http
            .post<any>(`${environment.api}/auth/refresh`, {
                refresh_token: token
            })
            .pipe(
                tap((data) => {
                    const resultData: AuthResult = data.data
                    this.setSession(resultData)
                })
            )
    }

    logout() {
        return this.http.delete(`${environment.api}/auth/logout`)
    }

    logOutSectionLocal() {
        this.isLogin = false
        this.roleAs = ['public']
        localStorage.removeItem('roles')
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('expires_at')
        this.setUserState()
        this.store.dispatch(ClearUser())
        return of({ success: this.isLogin, role: this.roleAs })
    }

    private setSession({ tokens }: AuthResult) {
        const {
            access,
            refresh
        } = tokens
        const { exp } = this.helpers.decodePayloadJWT(access)
        const expiresAt = moment().add(exp, 'second')

        // this.roleAs = user.roles

        if (!this.roleAs.includes('consumer')) {
            this.toast.error(
                'Este usuário não tem permissão para acessar esta área!'
            )
        } else {
            this.isLogin = true
            localStorage.setItem('roles', JSON.stringify(this.roleAs))
            localStorage.setItem('token', access)
            localStorage.setItem('refresh_token', refresh)
            localStorage.setItem(
                'expires_at',
                JSON.stringify(expiresAt.valueOf())
            )
            // this.setUserState()
        }
    }

    setUserState(): void {
        const token = localStorage.getItem('token') || ''
        if (token) {
            let { user } = this.helpers.decodePayloadJWT(token)

            this.store.dispatch(
                AddUser({
                    ...this.helpers.converterSnakeToCamelCase(user)
                })
            )
        } else {
            this.store.dispatch(ClearUser())
        }
    }

    getRole(): string[] {
        const roles = localStorage.getItem('roles')
        this.roleAs = roles ? JSON.parse(roles) : ['public']
        return this.roleAs
    }

    getToken(): string {
        return localStorage.getItem('token') || ''
    }

    getRefreshToken(): string {
        return localStorage.getItem('refresh_token') || ''
    }

    getExpiration() {
        const expiration = localStorage.getItem('expires_at')
        const expiresAt = expiration ? JSON.parse(expiration) : ''
        return expiresAt ? moment(expiresAt) : ''
    }

    getUserLocale(): any {
        const token = localStorage.getItem('token') || ''
        const { user } = this.helpers.decodePayloadJWT(token)
        return user
    }

    isLoggedIn() {
        this.isLogin = moment().isBefore(this.getExpiration())
        return this.isLogin
    }

    redirectAuth(): void {
        // const userRole = this.getRole()
        // let redirectUrl = ''
        //
        // if (userRole.includes('consumer')) {
        //     redirectUrl = environment.redirects.roles['consumer']
        // } else {
        //     redirectUrl = environment.redirects.roles['public']
        // }
        //
        // this.router.navigate([redirectUrl])
    }
}

import { Injectable } from '@angular/core'
import { Callbacks } from '../../models/callbacks'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'

@Injectable({
	providedIn: 'root',
})
export class FollowizeService {
	constructor(private http: HttpClient) {}

	sendFollowize(
		typeInsurance: string,
		payload: object,
		callback: Callbacks
	): void {
		const insurance = typeInsurance ? typeInsurance : 'followize/lead'
		this.http
			.post<any>(`${environment.api}${insurance}`, payload)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					console.warn(err)
					callback.fnError(err)
				},
			})
	}


    sendFollowizeEvent(
        typeInsurance: string,
        payload: object,
        callback: Callbacks
    ): void {
        const insurance = typeInsurance ? typeInsurance : 'followize/lead-event'
        this.http
            .post<any>(`${environment.api}${insurance}`, payload)
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    callback.fnSuccess(data)
                },
                error: (err) => {
                    console.warn(err)
                    callback.fnError(err)
                },
            })
    }
}

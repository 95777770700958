<ngx-slick-carousel class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    class="my-carousel">
    <div ngxSlickItem *ngFor="let slide of data" class="slide">
        <div class="slide-content">
            <div class="container">
                <div class="row no-gutters" >
                    <div class="slide-item col-12 col-md-6 mx-auto mx-md-0" *ngFor="let benefit of slide" >
<!--                        <div *ngIf="benefit.htmlContent" class="quero-cotacao action-button">-->
<!--                            <button class="button-cotacao next-btn next-btn1" type="button" (click)="cotacao()" >-->
<!--                                QUERO UMA COTAÇÃO-->
<!--                            </button>-->
<!--                        </div>-->
                        <div *ngIf="!benefit.htmlContent">
                            <app-image [path]="benefit.image" [title]="benefit.text" [alt]="benefit.alt_text" [lazy]="false"></app-image>
                            <p class="title" [innerHTML]="benefit.text | htmlParse"></p>
                            <p class="description" [innerHTML]="benefit.description | htmlParse"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ngx-slick-carousel>

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-assists',
	templateUrl: './assists.component.html',
	styleUrls: ['./assists.component.scss'],
})
export class AssistsComponent implements OnInit {
	@Input() data: any

	constructor() {}

	ngOnInit(): void {}
}

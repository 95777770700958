import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
	selector: 'app-banner-form',
	templateUrl: './banner-form.component.html',
	styleUrls: ['./banner-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class BannerFormComponent implements OnInit {
	@Input() title: string = ''
	@Input() invertColors: boolean = false
	@Input() description: string = ''
	@Input() text: string = ''
	@Input() disclaimer: string = ''
	@Input() img: string = ''
	@Input() alt_text: string = ''

	constructor() {}

	ngOnInit(): void {	}
}

import { Action } from '@ngrx/store'
import { ILandingpage } from 'src/app/models/landingpage/landingpage'

export enum ActionLandingpageTypes {
	Add = '@LANDINGPAGE/ADD',
	Clear = '@LANDINGPAGE/CLEAR',
}

export const AddLandingpage = (landingpage: ILandingpage) => {
	return <Action>{ type: ActionLandingpageTypes.Add, payload: landingpage }
}

export const ClearLandingpage = () => {
	return <Action>{ type: ActionLandingpageTypes.Clear, payload: null }
}

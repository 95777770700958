import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalBenefitComponent } from '../modals/modal-benefit/modal-benefit.component'

interface ICard {
	image?: string
	new_card?: boolean
	text?: string
    alt_text?: string
	sub_text?: string
	titleModal?: string
	description?: string
}

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {
	@Input() classCustom: string = ''
	@Input() data: ICard
	@Input() legalText: string | ''

	constructor(public route: ActivatedRoute, private modal: NgbModal) {}

	ngOnInit(): void {
	}

	// @ts-ignore
	transformText(value: any) {
		if(typeof(value) === 'string') {
			const parser = new DOMParser();
			const parsedHTML = parser.parseFromString(value, 'text/html');
			const decodedText = parsedHTML.body.textContent;
			return decodedText
		}
	}

	openModal(): void {
		const modalRef = this.modal.open(ModalBenefitComponent, {
			modalDialogClass: 'modal-benefit',
		})
		modalRef.componentInstance.figure = this.data.image
		modalRef.componentInstance.titleModal = this.data.text
		modalRef.componentInstance.description = this.data.description
		modalRef.componentInstance.legalText = this.legalText

        if(window.location.pathname.includes('renovacao')) {
            modalRef.componentInstance.buttonText = 'Quero renovar'
        }
	}
}

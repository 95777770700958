import {mock} from "../models/mock";
export const professions: mock[] = [
    {
        value: 'Encarregado de Obras',
        label: 'Encarregado de Obras',
    },
    {
        value: 'Administrador - Bingo',
        label: 'Administrador - Bingo',
    },
    {
        value: 'Administrador de Empresas',
        label: 'Administrador de Empresas',
    },
    {
        value: 'Almoxarife',
        label: 'Almoxarife',
    },
    {
        value: 'Atuário',
        label: 'Atuário',
    },
    {
        value: 'Auditor',
        label: 'Auditor',
    },
    {
        value: 'Bancario',
        label: 'Bancario',
    },
    {
        value: 'Banqueiro',
        label: 'Banqueiro',
    },
    {
        value: 'Conferente',
        label: 'Conferente',
    },
    {
        value: 'Contabilista',
        label: 'Contabilista',
    },
    {
        value: 'Contador',
        label: 'Contador',
    },
    {
        value: 'Continuo',
        label: 'Continuo',
    },
    {
        value: 'Controler',
        label: 'Controler',
    },
    {
        value: 'Economista',
        label: 'Economista',
    },
    {
        value: 'Estatístico',
        label: 'Estatístico',
    },
    {
        value: 'Estoquista',
        label: 'Estoquista',
    },
    {
        value: 'Faturista',
        label: 'Faturista',
    },
    {
        value: 'Marquetologa',
        label: 'Marquetologa',
    },
    {
        value: 'Mercadólogo',
        label: 'Mercadólogo',
    },
    {
        value: 'Propagandista',
        label: 'Propagandista',
    },
    {
        value: 'Recepcionista',
        label: 'Recepcionista',
    },
    {
        value: 'Securitario',
        label: 'Securitario',
    },
    {
        value: 'Tesoureiro',
        label: 'Tesoureiro',
    },
    {
        value: 'Encarregado Depto Pessoal',
        label: 'Encarregado Depto Pessoal',
    },
    {
        value: 'Corretor de Valores',
        label: 'Corretor de Valores',
    },
    {
        value: 'Auxiliar de Escritorio',
        label: 'Auxiliar de Escritorio',
    },
    {
        value: 'Assessor(A)  Financeiro(A)',
        label: 'Assessor(A)  Financeiro(A)',
    },
    {
        value: 'Corretor de Imoveis',
        label: 'Corretor de Imoveis',
    },
    {
        value: 'Corretor de Seguros',
        label: 'Corretor de Seguros',
    },
    {
        value: 'Analista Financeiro',
        label: 'Analista Financeiro',
    },
    {
        value: 'Encarregado de Producao',
        label: 'Encarregado de Producao',
    },
    {
        value: 'Agente Tec. de Estatistica',
        label: 'Agente Tec. de Estatistica',
    },
    {
        value: 'Assessor(A) de Marketing',
        label: 'Assessor(A) de Marketing',
    },
    {
        value: 'Auxiliar',
        label: 'Auxiliar',
    },
    {
        value: 'Analista Contabil',
        label: 'Analista Contabil',
    },
    {
        value: 'Auxiliar de Cobranca',
        label: 'Auxiliar de Cobranca',
    },
    {
        value: 'Coordenador  de Qualidade',
        label: 'Coordenador  de Qualidade',
    },
    {
        value: 'Assistente Executivo',
        label: 'Assistente Executivo',
    },
    {
        value: 'Assistente de Vendas',
        label: 'Assistente de Vendas',
    },
    {
        value: 'Assessoria de Migracao',
        label: 'Assessoria de Migracao',
    },
    {
        value: 'Encarregado de Cobranca',
        label: 'Encarregado de Cobranca',
    },
    {
        value: 'Auxiliar Administrativo',
        label: 'Auxiliar Administrativo',
    },
    {
        value: 'Inspetor Tecnico',
        label: 'Inspetor Tecnico',
    },
    {
        value: 'Assistente Social',
        label: 'Assistente Social',
    },
    {
        value: 'Administradora de Contratos',
        label: 'Administradora de Contratos',
    },
    {
        value: 'Encarregado de Manutencao',
        label: 'Encarregado de Manutencao',
    },
    {
        value: 'Administrador Ramo Informatica',
        label: 'Administrador Ramo Informatica',
    },
    {
        value: 'Assistente de Investimentos',
        label: 'Assistente de Investimentos',
    },
    {
        value: 'Reitoria Administrativa',
        label: 'Reitoria Administrativa',
    },
    {
        value: 'Assistente Operacional',
        label: 'Assistente Operacional',
    },
    {
        value: 'Administrador(A) de Bens',
        label: 'Administrador(A) de Bens',
    },
    {
        value: 'Auxiliar Depto.  Pessoal',
        label: 'Auxiliar Depto.  Pessoal',
    },
    {
        value: 'Adm. Empresa - Cartao Credito',
        label: 'Adm. Empresa - Cartao Credito',
    },
    {
        value: 'Marketing Promocional',
        label: 'Marketing Promocional',
    },
    {
        value: 'Adm. de Empresa - Import/Expo.',
        label: 'Adm. de Empresa - Import/Expo.',
    },
    {
        value: 'Adm.Empr. - Inst. Eletricas',
        label: 'Adm.Empr. - Inst. Eletricas',
    },
    {
        value: 'Telemarketing',
        label: 'Telemarketing',
    },
    {
        value: 'Adm. Empresas - Ag. de Turismo',
        label: 'Adm. Empresas - Ag. de Turismo',
    },
    {
        value: 'Adm Empresas Eletro Eletronico',
        label: 'Adm Empresas Eletro Eletronico',
    },
    {
        value: 'Adm.Empresas - Ramo Seguros',
        label: 'Adm.Empresas - Ramo Seguros',
    },
    {
        value: 'Assistente Contabil',
        label: 'Assistente Contabil',
    },
    {
        value: 'Adm Empresas - Com. Exterior',
        label: 'Adm Empresas - Com. Exterior',
    },
    {
        value: 'Inspetor Controle de Qualidade',
        label: 'Inspetor Controle de Qualidade',
    },
    {
        value: 'Administrador de Condominio',
        label: 'Administrador de Condominio',
    },
    {
        value: 'Auxiliar de Deselvolvimento',
        label: 'Auxiliar de Deselvolvimento',
    },
    {
        value: 'Programador de Producao',
        label: 'Programador de Producao',
    },
    {
        value: 'Auxiliar de Tesouraria',
        label: 'Auxiliar de Tesouraria',
    },
    {
        value: 'Analista de Depto. Pessoal',
        label: 'Analista de Depto. Pessoal',
    },
    {
        value: 'Encarregado de Usinagem',
        label: 'Encarregado de Usinagem',
    },
    {
        value: 'Adm. Empresas - Embalagens',
        label: 'Adm. Empresas - Embalagens',
    },
    {
        value: 'Adm Empresa Chefe Almoxarifado',
        label: 'Adm Empresa Chefe Almoxarifado',
    },
    {
        value: 'Auxiliar Financeiro',
        label: 'Auxiliar Financeiro',
    },
    {
        value: 'Adm. Empresas - Limpeza',
        label: 'Adm. Empresas - Limpeza',
    },
    {
        value: 'Adm.Empresa - Telecomunicacoes',
        label: 'Adm.Empresa - Telecomunicacoes',
    },
    {
        value: 'Assistente Depto. Pessoal',
        label: 'Assistente Depto. Pessoal',
    },
    {
        value: 'Adm. Empresa - Consultoria',
        label: 'Adm. Empresa - Consultoria',
    },
    {
        value: 'Trainne Tecnico',
        label: 'Trainne Tecnico',
    },
    {
        value: 'Coordenador Comercial',
        label: 'Coordenador Comercial',
    },
    {
        value: 'Assistente Tecnico',
        label: 'Assistente Tecnico',
    },
    {
        value: 'Administrador(A) Construtora',
        label: 'Administrador(A) Construtora',
    },
    {
        value: 'Administrador - Org Não Gorv',
        label: 'Administrador - Org Não Gorv',
    },
    {
        value: 'Encarregado de Deposito',
        label: 'Encarregado de Deposito',
    },
    {
        value: 'Adm. Empresas - Editora',
        label: 'Adm. Empresas - Editora',
    },
    {
        value: 'Administrador Financeiro',
        label: 'Administrador Financeiro',
    },
    {
        value: 'Auxiliar Contabil',
        label: 'Auxiliar Contabil',
    },
    {
        value: 'Adm. Empresas - Ramo Escola',
        label: 'Adm. Empresas - Ramo Escola',
    },
    {
        value: 'Encarregado de Transportes',
        label: 'Encarregado de Transportes',
    },
    {
        value: 'Administrador - Fazendas',
        label: 'Administrador - Fazendas',
    },
    {
        value: 'Adm.Empresa-Ramo  Alimenticio',
        label: 'Adm.Empresa-Ramo  Alimenticio',
    },
    {
        value: 'Adm.De Empresas-Emp.Borracha',
        label: 'Adm.De Empresas-Emp.Borracha',
    },
    {
        value: 'Adm. Empresas - Ramo Segurança',
        label: 'Adm. Empresas - Ramo Segurança',
    },
    {
        value: 'Adm.Empresa-Venda/Locação Veic',
        label: 'Adm.Empresa-Venda/Locação Veic',
    },
    {
        value: 'Adm. Empresa - Recurso Humanos',
        label: 'Adm. Empresa - Recurso Humanos',
    },
    {
        value: 'Administrador Hospitalar',
        label: 'Administrador Hospitalar',
    },
    {
        value: 'Inspetor de Recebimentos',
        label: 'Inspetor de Recebimentos',
    },
    {
        value: 'Coordenador Projetos Especiais',
        label: 'Coordenador Projetos Especiais',
    },
    {
        value: 'Analista de Planejamento',
        label: 'Analista de Planejamento',
    },
    {
        value: 'Administrador - Correios',
        label: 'Administrador - Correios',
    },
    {
        value: 'Analista de Materiais',
        label: 'Analista de Materiais',
    },
    {
        value: 'Analista de Comércio Exterior',
        label: 'Analista de Comércio Exterior',
    },
    {
        value: 'Lider de Controle de Qualidade',
        label: 'Lider de Controle de Qualidade',
    },
    {
        value: 'Administrador Estof e Colchoes',
        label: 'Administrador Estof e Colchoes',
    },
    {
        value: 'Encarregado(A) Financeiro(A)',
        label: 'Encarregado(A) Financeiro(A)',
    },
    {
        value: 'Adm Empresas - Marketing',
        label: 'Adm Empresas - Marketing',
    },
    {
        value: 'Assistente de Diretoria',
        label: 'Assistente de Diretoria',
    },
    {
        value: 'Inspetor Administrativo',
        label: 'Inspetor Administrativo',
    },
    {
        value: 'Assistente Administrativo',
        label: 'Assistente Administrativo',
    },
    {
        value: 'Coordenador',
        label: 'Coordenador',
    },
    {
        value: 'Assistente de Tesouraria',
        label: 'Assistente de Tesouraria',
    },
    {
        value: 'Office Boy',
        label: 'Office Boy',
    },
    {
        value: 'Advogado',
        label: 'Advogado',
    },
    {
        value: 'Comissario(A) de Bordo',
        label: 'Comissario(A) de Bordo',
    },
    {
        value: 'Aeronauta',
        label: 'Aeronauta',
    },
    {
        value: 'Aeroviario',
        label: 'Aeroviario',
    },
    {
        value: 'Trabalhador Agricola',
        label: 'Trabalhador Agricola',
    },
    {
        value: 'Agricultor',
        label: 'Agricultor',
    },
    {
        value: 'Agronomo',
        label: 'Agronomo',
    },
    {
        value: 'Agropecuarista',
        label: 'Agropecuarista',
    },
    {
        value: 'Citricultor',
        label: 'Citricultor',
    },
    {
        value: 'Fazendeiro',
        label: 'Fazendeiro',
    },
    {
        value: 'Aposentado - Doença/Acidente',
        label: 'Aposentado - Doença/Acidente',
    },
    {
        value: 'Aposentado/Pensionista',
        label: 'Aposentado/Pensionista',
    },
    {
        value: 'Aposentado - Tempo de Serviço',
        label: 'Aposentado - Tempo de Serviço',
    },
    {
        value: 'Tatuador',
        label: 'Tatuador',
    },
    {
        value: 'Artesao',
        label: 'Artesao',
    },
    {
        value: 'Artista Plastico',
        label: 'Artista Plastico',
    },
    {
        value: 'Ator/Atriz',
        label: 'Ator/Atriz',
    },
    {
        value: 'Bailarino',
        label: 'Bailarino',
    },
    {
        value: 'Cantor',
        label: 'Cantor',
    },
    {
        value: 'Cenotecnico',
        label: 'Cenotecnico',
    },
    {
        value: 'Cineasta',
        label: 'Cineasta',
    },
    {
        value: 'Comentarista',
        label: 'Comentarista',
    },
    {
        value: 'Compositor',
        label: 'Compositor',
    },
    {
        value: 'Comunicologa',
        label: 'Comunicologa',
    },
    {
        value: 'Decorador',
        label: 'Decorador',
    },
    {
        value: 'Desenhista',
        label: 'Desenhista',
    },
    {
        value: 'Designer',
        label: 'Designer',
    },
    {
        value: 'Editor',
        label: 'Editor',
    },
    {
        value: 'Escritor',
        label: 'Escritor',
    },
    {
        value: 'Escultor',
        label: 'Escultor',
    },
    {
        value: 'Estilista',
        label: 'Estilista',
    },
    {
        value: 'Fotografo',
        label: 'Fotografo',
    },
    {
        value: 'Impressor Grafico',
        label: 'Impressor Grafico',
    },
    {
        value: 'Jornalista',
        label: 'Jornalista',
    },
    {
        value: 'Locutor',
        label: 'Locutor',
    },
    {
        value: 'Luthier',
        label: 'Luthier',
    },
    {
        value: 'Marchand',
        label: 'Marchand',
    },
    {
        value: 'Modelista',
        label: 'Modelista',
    },
    {
        value: 'Modelo / Manequim',
        label: 'Modelo / Manequim',
    },
    {
        value: 'Musico',
        label: 'Musico',
    },
    {
        value: 'Paisagista',
        label: 'Paisagista',
    },
    {
        value: 'Projetista',
        label: 'Projetista',
    },
    {
        value: 'Publicitario',
        label: 'Publicitario',
    },
    {
        value: 'Radialista',
        label: 'Radialista',
    },
    {
        value: 'Redator',
        label: 'Redator',
    },
    {
        value: 'Relacoes Publicas',
        label: 'Relacoes Publicas',
    },
    {
        value: 'Reporter Fotográfico',
        label: 'Reporter Fotográfico',
    },
    {
        value: 'Roteirista de Tv',
        label: 'Roteirista de Tv',
    },
    {
        value: 'Tradutor Interprete',
        label: 'Tradutor Interprete',
    },
    {
        value: 'Web Designer',
        label: 'Web Designer',
    },
    {
        value: 'Iluminotecnico',
        label: 'Iluminotecnico',
    },
    {
        value: 'Cantora',
        label: 'Cantora',
    },
    {
        value: 'Produtor de Eventos',
        label: 'Produtor de Eventos',
    },
    {
        value: 'Montador de Fotolito',
        label: 'Montador de Fotolito',
    },
    {
        value: 'Grafico',
        label: 'Grafico',
    },
    {
        value: 'Coreografo',
        label: 'Coreografo',
    },
    {
        value: 'Artesa - Confeccao em La',
        label: 'Artesa - Confeccao em La',
    },
    {
        value: 'Assistente de Programacao',
        label: 'Assistente de Programacao',
    },
    {
        value: 'Programador Visual',
        label: 'Programador Visual',
    },
    {
        value: 'Diretora - Casa de Cultura',
        label: 'Diretora - Casa de Cultura',
    },
    {
        value: 'Autor (A)',
        label: 'Autor (A)',
    },
    {
        value: 'Diretor de Tv',
        label: 'Diretor de Tv',
    },
    {
        value: 'Continuista',
        label: 'Continuista',
    },
    {
        value: 'Revisora',
        label: 'Revisora',
    },
    {
        value: 'Editora de Video',
        label: 'Editora de Video',
    },
    {
        value: 'Assistente de Camera',
        label: 'Assistente de Camera',
    },
    {
        value: 'Garimpeiro',
        label: 'Garimpeiro',
    },
    {
        value: 'Autonomo',
        label: 'Autonomo',
    },
    {
        value: 'Autonomo - Vendedor Cristais',
        label: 'Autonomo - Vendedor Cristais',
    },
    {
        value: 'Autonomo Consultor Informatica',
        label: 'Autonomo Consultor Informatica',
    },
    {
        value: 'Autonomo - Cobranca de Titulos',
        label: 'Autonomo - Cobranca de Titulos',
    },
    {
        value: 'Autonomo - Maquinas Graficas',
        label: 'Autonomo - Maquinas Graficas',
    },
    {
        value: 'Autonomo - Ramo Marcenaria',
        label: 'Autonomo - Ramo Marcenaria',
    },
    {
        value: 'Autonomo Ramo Investimento',
        label: 'Autonomo Ramo Investimento',
    },
    {
        value: 'Autonomo - Criador de Animais',
        label: 'Autonomo - Criador de Animais',
    },
    {
        value: 'Autonomo - Vendedor de Brindes',
        label: 'Autonomo - Vendedor de Brindes',
    },
    {
        value: 'Autonomo(A) Promotor(A) Evento',
        label: 'Autonomo(A) Promotor(A) Evento',
    },
    {
        value: 'Autonomo - Carregador',
        label: 'Autonomo - Carregador',
    },
    {
        value: 'Autonomo-Manutenção Hidráulica',
        label: 'Autonomo-Manutenção Hidráulica',
    },
    {
        value: 'Autônomo - Vendedor Decoração',
        label: 'Autônomo - Vendedor Decoração',
    },
    {
        value: 'Autônomo - Culinária',
        label: 'Autônomo - Culinária',
    },
    {
        value: 'Arqueólogo',
        label: 'Arqueólogo',
    },
    {
        value: 'Astrônomo',
        label: 'Astrônomo',
    },
    {
        value: 'Biologo',
        label: 'Biologo',
    },
    {
        value: 'Cientista',
        label: 'Cientista',
    },
    {
        value: 'Fisico',
        label: 'Fisico',
    },
    {
        value: 'Geografo',
        label: 'Geografo',
    },
    {
        value: 'Geologo',
        label: 'Geologo',
    },
    {
        value: 'Geômetra',
        label: 'Geômetra',
    },
    {
        value: 'Matematico',
        label: 'Matematico',
    },
    {
        value: 'Meteorologista',
        label: 'Meteorologista',
    },
    {
        value: 'Museologa',
        label: 'Museologa',
    },
    {
        value: 'Quimico',
        label: 'Quimico',
    },
    {
        value: 'Sociologo',
        label: 'Sociologo',
    },
    {
        value: 'Zootecnista',
        label: 'Zootecnista',
    },
    {
        value: 'Cientista Politico',
        label: 'Cientista Politico',
    },
    {
        value: 'Ecologista',
        label: 'Ecologista',
    },
    {
        value: 'Enologo',
        label: 'Enologo',
    },
    {
        value: 'Acougueiro',
        label: 'Acougueiro',
    },
    {
        value: 'Alfaiate/Costureira',
        label: 'Alfaiate/Costureira',
    },
    {
        value: 'Balconista',
        label: 'Balconista',
    },
    {
        value: 'Chaveiro',
        label: 'Chaveiro',
    },
    {
        value: 'Comerciante',
        label: 'Comerciante',
    },
    {
        value: 'Comerciario',
        label: 'Comerciario',
    },
    {
        value: 'Confeiteira',
        label: 'Confeiteira',
    },
    {
        value: 'Feirante',
        label: 'Feirante',
    },
    {
        value: 'Floricultor',
        label: 'Floricultor',
    },
    {
        value: 'Frentista',
        label: 'Frentista',
    },
    {
        value: 'Joalheiro',
        label: 'Joalheiro',
    },
    {
        value: 'Jornaleiro',
        label: 'Jornaleiro',
    },
    {
        value: 'Lanterneiro',
        label: 'Lanterneiro',
    },
    {
        value: 'Marceneiro',
        label: 'Marceneiro',
    },
    {
        value: 'Ourives',
        label: 'Ourives',
    },
    {
        value: 'Promotor de Vendas',
        label: 'Promotor de Vendas',
    },
    {
        value: 'Vidraceiro',
        label: 'Vidraceiro',
    },
    {
        value: 'Atendente Comercial',
        label: 'Atendente Comercial',
    },
    {
        value: 'Assistente Comercio Exterior',
        label: 'Assistente Comercio Exterior',
    },
    {
        value: 'Auxiliar Comercial',
        label: 'Auxiliar Comercial',
    },
    {
        value: 'Comerciante - Lj. Armarinhos',
        label: 'Comerciante - Lj. Armarinhos',
    },
    {
        value: 'Comerciante Lj Instr. Musicais',
        label: 'Comerciante Lj Instr. Musicais',
    },
    {
        value: 'Comerciante - Lj. Conveniencia',
        label: 'Comerciante - Lj. Conveniencia',
    },
    {
        value: 'Comerciante - Ramo Informatica',
        label: 'Comerciante - Ramo Informatica',
    },
    {
        value: 'Comerciante  - Ramo Agricola',
        label: 'Comerciante  - Ramo Agricola',
    },
    {
        value: 'Comerciante de Lubrificantes',
        label: 'Comerciante de Lubrificantes',
    },
    {
        value: 'Comerciante Secos e Molhados',
        label: 'Comerciante Secos e Molhados',
    },
    {
        value: 'Comerciante Ramo Relojoaria',
        label: 'Comerciante Ramo Relojoaria',
    },
    {
        value: 'Comerciante de Pneus',
        label: 'Comerciante de Pneus',
    },
    {
        value: 'Repr. Vendas - Supermercado',
        label: 'Repr. Vendas - Supermercado',
    },
    {
        value: 'Comerciante - Cafe',
        label: 'Comerciante - Cafe',
    },
    {
        value: 'Comerciante - Pedreira',
        label: 'Comerciante - Pedreira',
    },
    {
        value: 'Comerciante - Turismo',
        label: 'Comerciante - Turismo',
    },
    {
        value: 'Comerciante - Peixaria',
        label: 'Comerciante - Peixaria',
    },
    {
        value: 'Comerciante Ramo Ferragens',
        label: 'Comerciante Ramo Ferragens',
    },
    {
        value: 'Comerciante de Calcados',
        label: 'Comerciante de Calcados',
    },
    {
        value: 'Comerciante - Eletrodomestico',
        label: 'Comerciante - Eletrodomestico',
    },
    {
        value: 'Comerciante Cortinas/Persianas',
        label: 'Comerciante Cortinas/Persianas',
    },
    {
        value: 'Comerciante Ramo Embalagens',
        label: 'Comerciante Ramo Embalagens',
    },
    {
        value: 'Comerciante - Ramo Imobiliaria',
        label: 'Comerciante - Ramo Imobiliaria',
    },
    {
        value: 'Comerciante - Loja de Plantas',
        label: 'Comerciante - Loja de Plantas',
    },
    {
        value: 'Comerciante - Lj. de Veiculos',
        label: 'Comerciante - Lj. de Veiculos',
    },
    {
        value: 'Comerciante - Ramo Construcao',
        label: 'Comerciante - Ramo Construcao',
    },
    {
        value: 'Comerciante - Auto Eletrico',
        label: 'Comerciante - Auto Eletrico',
    },
    {
        value: 'Comerciante Bar e Restaurante',
        label: 'Comerciante Bar e Restaurante',
    },
    {
        value: 'Comerciante Deposito de Papel',
        label: 'Comerciante Deposito de Papel',
    },
    {
        value: 'Produtor Rural Paisagismo',
        label: 'Produtor Rural Paisagismo',
    },
    {
        value: 'Comerciante - Atacadista',
        label: 'Comerciante - Atacadista',
    },
    {
        value: 'Comerciante - Supermercado',
        label: 'Comerciante - Supermercado',
    },
    {
        value: 'Comerciante - Pecas Para Motos',
        label: 'Comerciante - Pecas Para Motos',
    },
    {
        value: 'Comerciante - Couros',
        label: 'Comerciante - Couros',
    },
    {
        value: 'Comerciante - Balas e Doces',
        label: 'Comerciante - Balas e Doces',
    },
    {
        value: 'Comerciante - Loja Pet Shop',
        label: 'Comerciante - Loja Pet Shop',
    },
    {
        value: 'Comerciante Loja de Reformas',
        label: 'Comerciante Loja de Reformas',
    },
    {
        value: 'Comerciante - Ramo Buffet',
        label: 'Comerciante - Ramo Buffet',
    },
    {
        value: 'Comerciante - Banca de Revista',
        label: 'Comerciante - Banca de Revista',
    },
    {
        value: 'Comerciante Posto de Gasolina',
        label: 'Comerciante Posto de Gasolina',
    },
    {
        value: 'Comerciante Acessorios P/ Auto',
        label: 'Comerciante Acessorios P/ Auto',
    },
    {
        value: 'Comerciante - Loja de Tintas',
        label: 'Comerciante - Loja de Tintas',
    },
    {
        value: 'Comerciante de Celulares',
        label: 'Comerciante de Celulares',
    },
    {
        value: 'Comerciante - Artesanato',
        label: 'Comerciante - Artesanato',
    },
    {
        value: 'Comerciante de Joias',
        label: 'Comerciante de Joias',
    },
    {
        value: 'Comerciante - Ramo Joias',
        label: 'Comerciante - Ramo Joias',
    },
    {
        value: 'Comerciante Ramo de Alimentos',
        label: 'Comerciante Ramo de Alimentos',
    },
    {
        value: 'Comerciante Loucas Ceramicas',
        label: 'Comerciante Loucas Ceramicas',
    },
    {
        value: 'Comerciante Ramo Madeira',
        label: 'Comerciante Ramo Madeira',
    },
    {
        value: 'Produtor de Flores',
        label: 'Produtor de Flores',
    },
    {
        value: 'Comerciante Moveis Escritorio',
        label: 'Comerciante Moveis Escritorio',
    },
    {
        value: 'Comerciante- Fitas  Video Game',
        label: 'Comerciante- Fitas  Video Game',
    },
    {
        value: 'Separador de Pecas de Auto',
        label: 'Separador de Pecas de Auto',
    },
    {
        value: 'Comerciante - Bombonas',
        label: 'Comerciante - Bombonas',
    },
    {
        value: 'Comerciante - Produtos Limpeza',
        label: 'Comerciante - Produtos Limpeza',
    },
    {
        value: 'Comerciante - Agropecuaria',
        label: 'Comerciante - Agropecuaria',
    },
    {
        value: 'Comerciante Implem. Agricolas',
        label: 'Comerciante Implem. Agricolas',
    },
    {
        value: 'Comerciante Papelaria / Xerox',
        label: 'Comerciante Papelaria / Xerox',
    },
    {
        value: 'Comerciante Material Seguranca',
        label: 'Comerciante Material Seguranca',
    },
    {
        value: 'Comerciante  Material Eletrico',
        label: 'Comerciante  Material Eletrico',
    },
    {
        value: 'Comerciario Funcionario Varejo',
        label: 'Comerciario Funcionario Varejo',
    },
    {
        value: 'Comerciante Presentes em Geral',
        label: 'Comerciante Presentes em Geral',
    },
    {
        value: 'Comerciante - Farmacia',
        label: 'Comerciante - Farmacia',
    },
    {
        value: 'Auxiliar Deposito Mt. Eletrico',
        label: 'Auxiliar Deposito Mt. Eletrico',
    },
    {
        value: 'Auxiliar Deposito Auto-Pecas',
        label: 'Auxiliar Deposito Auto-Pecas',
    },
    {
        value: 'Comerciante  - Confeccao',
        label: 'Comerciante  - Confeccao',
    },
    {
        value: 'Comerciante Abatedouro Aves',
        label: 'Comerciante Abatedouro Aves',
    },
    {
        value: 'Comerciante - Construcao Civil',
        label: 'Comerciante - Construcao Civil',
    },
    {
        value: 'Comerciante - Plásticos',
        label: 'Comerciante - Plásticos',
    },
    {
        value: 'Comerciário Ramo Transportes',
        label: 'Comerciário Ramo Transportes',
    },
    {
        value: 'Comerciante - Ramo Eletrônicos',
        label: 'Comerciante - Ramo Eletrônicos',
    },
    {
        value: 'Comerciante - Casa de Rações',
        label: 'Comerciante - Casa de Rações',
    },
    {
        value: 'Comerciante - Varejão',
        label: 'Comerciante - Varejão',
    },
    {
        value: 'Comerciante - Prod. Odontolog.',
        label: 'Comerciante - Prod. Odontolog.',
    },
    {
        value: 'Comerciante-Aparelhos Auditivo',
        label: 'Comerciante-Aparelhos Auditivo',
    },
    {
        value: 'Comerciante-Ramo de Parafusos',
        label: 'Comerciante-Ramo de Parafusos',
    },
    {
        value: 'Comerciante-Tapeceiro de Auto',
        label: 'Comerciante-Tapeceiro de Auto',
    },
    {
        value: 'Comerciante-Ramo Dedetização',
        label: 'Comerciante-Ramo Dedetização',
    },
    {
        value: 'Comerciante - Ramo Acrílicos',
        label: 'Comerciante - Ramo Acrílicos',
    },
    {
        value: 'Comerciante - Ramo de Padarias',
        label: 'Comerciante - Ramo de Padarias',
    },
    {
        value: 'Comerciante Sucatas',
        label: 'Comerciante Sucatas',
    },
    {
        value: 'Carpinteiro',
        label: 'Carpinteiro',
    },
    {
        value: 'Comerciante - Reciclagem',
        label: 'Comerciante - Reciclagem',
    },
    {
        value: 'Representante Comercial',
        label: 'Representante Comercial',
    },
    {
        value: 'Assistente Comercial',
        label: 'Assistente Comercial',
    },
    {
        value: 'Corretor de Cafe',
        label: 'Corretor de Cafe',
    },
    {
        value: 'Mestre de Obras',
        label: 'Mestre de Obras',
    },
    {
        value: 'Pedreiro',
        label: 'Pedreiro',
    },
    {
        value: 'Armador de Ferragem Const Civ',
        label: 'Armador de Ferragem Const Civ',
    },
    {
        value: 'Coordenador de Obras',
        label: 'Coordenador de Obras',
    },
    {
        value: 'Trabalhador Construcao Civil',
        label: 'Trabalhador Construcao Civil',
    },
    {
        value: 'Arquiteto',
        label: 'Arquiteto',
    },
    {
        value: 'Empreiteiro de Obras',
        label: 'Empreiteiro de Obras',
    },
    {
        value: 'Nivelador',
        label: 'Nivelador',
    },
    {
        value: 'Pintor',
        label: 'Pintor',
    },
    {
        value: 'Topografo',
        label: 'Topografo',
    },
    {
        value: 'Auxiliar de Topografia',
        label: 'Auxiliar de Topografia',
    },
    {
        value: 'Construtor Civil',
        label: 'Construtor Civil',
    },
    {
        value: 'Consultor',
        label: 'Consultor',
    },
    {
        value: 'Consultor Financeiro',
        label: 'Consultor Financeiro',
    },
    {
        value: 'Consultor Imobiliario',
        label: 'Consultor Imobiliario',
    },
    {
        value: 'Consultor de Recursos Humanos',
        label: 'Consultor de Recursos Humanos',
    },
    {
        value: 'Consultor Juridico',
        label: 'Consultor Juridico',
    },
    {
        value: 'Consultor Tecnico',
        label: 'Consultor Tecnico',
    },
    {
        value: 'Consultor em Planos Producao',
        label: 'Consultor em Planos Producao',
    },
    {
        value: 'Consultor - Transporte',
        label: 'Consultor - Transporte',
    },
    {
        value: 'Consultor em Informatica',
        label: 'Consultor em Informatica',
    },
    {
        value: 'Consultor (A) Marketing',
        label: 'Consultor (A) Marketing',
    },
    {
        value: 'Consultor Empresarial',
        label: 'Consultor Empresarial',
    },
    {
        value: 'Consultor Administrativo',
        label: 'Consultor Administrativo',
    },
    {
        value: 'Consultor de Vendas',
        label: 'Consultor de Vendas',
    },
    {
        value: 'Consultor Industrial',
        label: 'Consultor Industrial',
    },
    {
        value: 'Consultor de Moda',
        label: 'Consultor de Moda',
    },
    {
        value: 'Desempregado',
        label: 'Desempregado',
    },
    {
        value: 'Diretor de Vendas',
        label: 'Diretor de Vendas',
    },
    {
        value: 'Diretor Financeiro',
        label: 'Diretor Financeiro',
    },
    {
        value: 'Diretor Secretario',
        label: 'Diretor Secretario',
    },
    {
        value: 'Diretora Presidente',
        label: 'Diretora Presidente',
    },
    {
        value: 'Diretor Adjunto',
        label: 'Diretor Adjunto',
    },
    {
        value: 'Diretor Comercial',
        label: 'Diretor Comercial',
    },
    {
        value: 'Diretor Superintendente',
        label: 'Diretor Superintendente',
    },
    {
        value: 'Diretor Geral',
        label: 'Diretor Geral',
    },
    {
        value: 'Diretor Industrial',
        label: 'Diretor Industrial',
    },
    {
        value: 'Diretor Legislativo',
        label: 'Diretor Legislativo',
    },
    {
        value: 'Diretor de Compras',
        label: 'Diretor de Compras',
    },
    {
        value: 'Diretor Rh',
        label: 'Diretor Rh',
    },
    {
        value: 'Diretor Auto-Escola',
        label: 'Diretor Auto-Escola',
    },
    {
        value: 'Diretor de Empresa',
        label: 'Diretor de Empresa',
    },
    {
        value: 'Diretor de Escola',
        label: 'Diretor de Escola',
    },
    {
        value: 'Diretor Tecnico',
        label: 'Diretor Tecnico',
    },
    {
        value: 'Do Lar',
        label: 'Do Lar',
    },
    {
        value: 'Bibliotecario',
        label: 'Bibliotecario',
    },
    {
        value: 'Pedagogo (A)',
        label: 'Pedagogo (A)',
    },
    {
        value: 'Professor',
        label: 'Professor',
    },
    {
        value: 'Auxiliar de Biblioteca',
        label: 'Auxiliar de Biblioteca',
    },
    {
        value: 'Inspetor de Alunos',
        label: 'Inspetor de Alunos',
    },
    {
        value: 'Orientadora Educacional',
        label: 'Orientadora Educacional',
    },
    {
        value: 'Orientadora Pedagogica',
        label: 'Orientadora Pedagogica',
    },
    {
        value: 'Coordenador Escolar',
        label: 'Coordenador Escolar',
    },
    {
        value: 'Monitora de Creches',
        label: 'Monitora de Creches',
    },
    {
        value: 'Empresario',
        label: 'Empresario',
    },
    {
        value: 'Micro Empresario',
        label: 'Micro Empresario',
    },
    {
        value: 'Empresario-Servicos Maritimos',
        label: 'Empresario-Servicos Maritimos',
    },
    {
        value: 'Empresario Prods Agropecuários',
        label: 'Empresario Prods Agropecuários',
    },
    {
        value: 'Empresario - Ramo Automotor',
        label: 'Empresario - Ramo Automotor',
    },
    {
        value: 'Empresario - Ramo Aluminio',
        label: 'Empresario - Ramo Aluminio',
    },
    {
        value: 'Empresario - Funeraria',
        label: 'Empresario - Funeraria',
    },
    {
        value: 'Empresario - Ramo de Borrachas',
        label: 'Empresario - Ramo de Borrachas',
    },
    {
        value: 'Empresario - Ramo de Vidros',
        label: 'Empresario - Ramo de Vidros',
    },
    {
        value: 'Empresario - Auto Posto',
        label: 'Empresario - Auto Posto',
    },
    {
        value: 'Empresario Material Construcao',
        label: 'Empresario Material Construcao',
    },
    {
        value: 'Empresario - Ramo Alimenticio',
        label: 'Empresario - Ramo Alimenticio',
    },
    {
        value: 'Empresario - Ramo Contabil',
        label: 'Empresario - Ramo Contabil',
    },
    {
        value: 'Empresario Ramo Concessionaria',
        label: 'Empresario Ramo Concessionaria',
    },
    {
        value: 'Empresario (A) - Transportes',
        label: 'Empresario (A) - Transportes',
    },
    {
        value: 'Empresario Ramo de Cosmeticos',
        label: 'Empresario Ramo de Cosmeticos',
    },
    {
        value: 'Empresario - Lj. R$ 1,99',
        label: 'Empresario - Lj. R$ 1,99',
    },
    {
        value: 'Empresario - Ramo Auto-Pecas',
        label: 'Empresario - Ramo Auto-Pecas',
    },
    {
        value: 'Empresario - Ramo Vigilancia',
        label: 'Empresario - Ramo Vigilancia',
    },
    {
        value: 'Empresario - Ramo Confeccao',
        label: 'Empresario - Ramo Confeccao',
    },
    {
        value: 'Empresario - Ramo de Maquinas',
        label: 'Empresario - Ramo de Maquinas',
    },
    {
        value: 'Empresario - Ramo de Moveis',
        label: 'Empresario - Ramo de Moveis',
    },
    {
        value: 'Empresario Ramo de Brinquedos',
        label: 'Empresario Ramo de Brinquedos',
    },
    {
        value: 'Empresario - Casa de Carnes',
        label: 'Empresario - Casa de Carnes',
    },
    {
        value: 'Empresaria - Dona de Cartorio',
        label: 'Empresaria - Dona de Cartorio',
    },
    {
        value: 'Empresario - Ramo de Advocacia',
        label: 'Empresario - Ramo de Advocacia',
    },
    {
        value: 'Empresario - Ramo Copiadora',
        label: 'Empresario - Ramo Copiadora',
    },
    {
        value: 'Empresario - Ramo Decoracao',
        label: 'Empresario - Ramo Decoracao',
    },
    {
        value: 'Empresario Aparelho Cirurgico',
        label: 'Empresario Aparelho Cirurgico',
    },
    {
        value: 'Empresario Hidro Irrigacao',
        label: 'Empresario Hidro Irrigacao',
    },
    {
        value: 'Empresario  - Video Locadora',
        label: 'Empresario  - Video Locadora',
    },
    {
        value: 'Empresario Ind. Eng.De Bebidas',
        label: 'Empresario Ind. Eng.De Bebidas',
    },
    {
        value: 'Empresario - Ramo de Seguros',
        label: 'Empresario - Ramo de Seguros',
    },
    {
        value: 'Empresario Import. Exportacao',
        label: 'Empresario Import. Exportacao',
    },
    {
        value: 'Empresario - Ramo Telefonia',
        label: 'Empresario - Ramo Telefonia',
    },
    {
        value: 'Empresario - Ramo Livraria',
        label: 'Empresario - Ramo Livraria',
    },
    {
        value: 'Empresario - Ramo Estofaria',
        label: 'Empresario - Ramo Estofaria',
    },
    {
        value: 'Empresario - Ramo de Eventos',
        label: 'Empresario - Ramo de Eventos',
    },
    {
        value: 'Empresario - Ramo Otica',
        label: 'Empresario - Ramo Otica',
    },
    {
        value: 'Empresario - Motel',
        label: 'Empresario - Motel',
    },
    {
        value: 'Empresario - Paisagismo',
        label: 'Empresario - Paisagismo',
    },
    {
        value: 'Empresario - Servicos Gerais',
        label: 'Empresario - Servicos Gerais',
    },
    {
        value: 'Empresario - Ramo Padaria',
        label: 'Empresario - Ramo Padaria',
    },
    {
        value: 'Empresario - Ramo Pizzaria',
        label: 'Empresario - Ramo Pizzaria',
    },
    {
        value: 'Empresario - Ramo Publicidade',
        label: 'Empresario - Ramo Publicidade',
    },
    {
        value: 'Empresario - Ramo Marcenaria',
        label: 'Empresario - Ramo Marcenaria',
    },
    {
        value: 'Empresario - Ramo Grafica',
        label: 'Empresario - Ramo Grafica',
    },
    {
        value: 'Empresario Rural',
        label: 'Empresario Rural',
    },
    {
        value: 'Empresario - Ramo Informatica',
        label: 'Empresario - Ramo Informatica',
    },
    {
        value: 'Empresario Comunicacao Visual',
        label: 'Empresario Comunicacao Visual',
    },
    {
        value: 'Empresario  - Ramo Hotel',
        label: 'Empresario  - Ramo Hotel',
    },
    {
        value: 'Empresario - Ramo Esportes',
        label: 'Empresario - Ramo Esportes',
    },
    {
        value: 'Empresario - Ramo Motoboys',
        label: 'Empresario - Ramo Motoboys',
    },
    {
        value: 'Empresario (A) Prods. Quimicos',
        label: 'Empresario (A) Prods. Quimicos',
    },
    {
        value: 'Empresario - Dono Construtora',
        label: 'Empresario - Dono Construtora',
    },
    {
        value: 'Empresario - Instr. Musicais',
        label: 'Empresario - Instr. Musicais',
    },
    {
        value: 'Empresario  - Marmoraria',
        label: 'Empresario  - Marmoraria',
    },
    {
        value: 'Empresario Saneamento e Servs.',
        label: 'Empresario Saneamento e Servs.',
    },
    {
        value: 'Empresario - Ramo Logistica',
        label: 'Empresario - Ramo Logistica',
    },
    {
        value: 'Empresario - Ramo Ceramica',
        label: 'Empresario - Ramo Ceramica',
    },
    {
        value: 'Empresario Produto Hospitalar',
        label: 'Empresario Produto Hospitalar',
    },
    {
        value: 'Empresario Estrs Metalicas',
        label: 'Empresario Estrs Metalicas',
    },
    {
        value: 'Empresaria - Ramo Imoveis',
        label: 'Empresaria - Ramo Imoveis',
    },
    {
        value: 'Empresario Ramo Terraplanagem',
        label: 'Empresario Ramo Terraplanagem',
    },
    {
        value: 'Empresario - Ramo de Academia',
        label: 'Empresario - Ramo de Academia',
    },
    {
        value: 'Empresario Ramo Imobiliaria',
        label: 'Empresario Ramo Imobiliaria',
    },
    {
        value: 'Empresario - Clube Recreativo',
        label: 'Empresario - Clube Recreativo',
    },
    {
        value: 'Empresario Ramo de Farmacia',
        label: 'Empresario Ramo de Farmacia',
    },
    {
        value: 'Empresario - Ramo Palmilhas',
        label: 'Empresario - Ramo Palmilhas',
    },
    {
        value: 'Empresario - Granja',
        label: 'Empresario - Granja',
    },
    {
        value: 'Empresario (A) - Assessoria',
        label: 'Empresario (A) - Assessoria',
    },
    {
        value: 'Empresario (A) Ramo de Tecidos',
        label: 'Empresario (A) Ramo de Tecidos',
    },
    {
        value: 'Empresario(A) - Ramo Escola',
        label: 'Empresario(A) - Ramo Escola',
    },
    {
        value: 'Empresario - Loja de Celular',
        label: 'Empresario - Loja de Celular',
    },
    {
        value: 'Empresario - Fabrica Plastico',
        label: 'Empresario - Fabrica Plastico',
    },
    {
        value: 'Empresario-Agencia de Viagens',
        label: 'Empresario-Agencia de Viagens',
    },
    {
        value: 'Empresario (A) Ramo Engenharia',
        label: 'Empresario (A) Ramo Engenharia',
    },
    {
        value: 'Empresario Ramo Cobranca',
        label: 'Empresario Ramo Cobranca',
    },
    {
        value: 'Empresario Ramo Consultoria',
        label: 'Empresario Ramo Consultoria',
    },
    {
        value: 'Empresario - Ramo Fotografia',
        label: 'Empresario - Ramo Fotografia',
    },
    {
        value: 'Empresario - Casa Noturna',
        label: 'Empresario - Casa Noturna',
    },
    {
        value: 'Empresario - Loja de Roupas',
        label: 'Empresario - Loja de Roupas',
    },
    {
        value: 'Empresario - Oficina Mecanica',
        label: 'Empresario - Oficina Mecanica',
    },
    {
        value: 'Empresario - Loja de Colchoes',
        label: 'Empresario - Loja de Colchoes',
    },
    {
        value: 'Empresaria Loja de Artesanato',
        label: 'Empresaria Loja de Artesanato',
    },
    {
        value: 'Empresario (A)   Art P/ Festas',
        label: 'Empresario (A)   Art P/ Festas',
    },
    {
        value: 'Empresario ( A ) - Ramo Textil',
        label: 'Empresario ( A ) - Ramo Textil',
    },
    {
        value: 'Empresario (A) - Distr. Filmes',
        label: 'Empresario (A) - Distr. Filmes',
    },
    {
        value: 'Empresario(A) Manut. Industr.',
        label: 'Empresario(A) Manut. Industr.',
    },
    {
        value: 'Empresario(A) - Metalurgica',
        label: 'Empresario(A) - Metalurgica',
    },
    {
        value: 'Empresario(A) - Loja de Joias',
        label: 'Empresario(A) - Loja de Joias',
    },
    {
        value: 'Empresario(A) - Estacionamento',
        label: 'Empresario(A) - Estacionamento',
    },
    {
        value: 'Empresario (A) - Prod de Video',
        label: 'Empresario (A) - Prod de Video',
    },
    {
        value: 'Empresario(A) - Frigorifico',
        label: 'Empresario(A) - Frigorifico',
    },
    {
        value: 'Micro-Empresario Esc. Infantil',
        label: 'Micro-Empresario Esc. Infantil',
    },
    {
        value: 'Empresario Pres Serv Manutenc.',
        label: 'Empresario Pres Serv Manutenc.',
    },
    {
        value: 'Empresario Com Pesca e Nautica',
        label: 'Empresario Com Pesca e Nautica',
    },
    {
        value: 'Empresario - Implem. Agricolas',
        label: 'Empresario - Implem. Agricolas',
    },
    {
        value: 'Empresario - Inst. de Alarmes',
        label: 'Empresario - Inst. de Alarmes',
    },
    {
        value: 'Micro-Empres Autom. Industrial',
        label: 'Micro-Empres Autom. Industrial',
    },
    {
        value: 'Empresario Ramo Software',
        label: 'Empresario Ramo Software',
    },
    {
        value: 'Empresario - Avioes',
        label: 'Empresario - Avioes',
    },
    {
        value: 'Empresário - Navios',
        label: 'Empresário - Navios',
    },
    {
        value: 'Empresário - Brindes Promoc.',
        label: 'Empresário - Brindes Promoc.',
    },
    {
        value: 'Empresário - Construção Civil',
        label: 'Empresário - Construção Civil',
    },
    {
        value: 'Empresário - Factoring',
        label: 'Empresário - Factoring',
    },
    {
        value: 'Empresário (A) - Microfil.Dig',
        label: 'Empresário (A) - Microfil.Dig',
    },
    {
        value: 'Empresário - Ramo Restaurantes',
        label: 'Empresário - Ramo Restaurantes',
    },
    {
        value: 'Empresário - Madeireira',
        label: 'Empresário - Madeireira',
    },
    {
        value: 'Empresário - Telecomunicações',
        label: 'Empresário - Telecomunicações',
    },
    {
        value: 'Empresário - Ramo de Blindagem',
        label: 'Empresário - Ramo de Blindagem',
    },
    {
        value: 'Empresário-Ramo Lavanderia',
        label: 'Empresário-Ramo Lavanderia',
    },
    {
        value: 'Empresario-Ramo de Bebidas',
        label: 'Empresario-Ramo de Bebidas',
    },
    {
        value: 'Empresario-Ramo Hipermercado',
        label: 'Empresario-Ramo Hipermercado',
    },
    {
        value: 'Empresario-Ramo de Magazine',
        label: 'Empresario-Ramo de Magazine',
    },
    {
        value: 'Empresario-Parque de Diversões',
        label: 'Empresario-Parque de Diversões',
    },
    {
        value: 'Empresário-Ramo Ar Condicionad',
        label: 'Empresário-Ramo Ar Condicionad',
    },
    {
        value: 'Empresário-Ramo de Turismo',
        label: 'Empresário-Ramo de Turismo',
    },
    {
        value: 'Empresário(A)- Ind.De Botoes',
        label: 'Empresário(A)- Ind.De Botoes',
    },
    {
        value: 'Empresário - Ramo de Calçados',
        label: 'Empresário - Ramo de Calçados',
    },
    {
        value: 'Empresário - Artigos de Couro',
        label: 'Empresário - Artigos de Couro',
    },
    {
        value: 'Empresário - Esq. Metálicas',
        label: 'Empresário - Esq. Metálicas',
    },
    {
        value: 'Empresário - Salão de Beleza',
        label: 'Empresário - Salão de Beleza',
    },
    {
        value: 'Empresário - Ramo de Artes',
        label: 'Empresário - Ramo de Artes',
    },
    {
        value: 'Empresario - Ramo Loteria',
        label: 'Empresario - Ramo Loteria',
    },
    {
        value: 'Empreendedor Franquia',
        label: 'Empreendedor Franquia',
    },
    {
        value: 'Empresario - Ramo Estetica',
        label: 'Empresario - Ramo Estetica',
    },
    {
        value: 'Empresario - Fabrica Carimbos',
        label: 'Empresario - Fabrica Carimbos',
    },
    {
        value: 'Empresario Ramo Mineracao',
        label: 'Empresario Ramo Mineracao',
    },
    {
        value: 'Empresário - Ramo Plásticos',
        label: 'Empresário - Ramo Plásticos',
    },
    {
        value: 'Empresário (A) - Isopor',
        label: 'Empresário (A) - Isopor',
    },
    {
        value: 'Empresário (A) - Intercâmbios',
        label: 'Empresário (A) - Intercâmbios',
    },
    {
        value: 'Empresário (A) - Combustíveis',
        label: 'Empresário (A) - Combustíveis',
    },
    {
        value: 'Empresário (A) - Iluminação',
        label: 'Empresário (A) - Iluminação',
    },
    {
        value: 'Empresario-Ramo de Magazine',
        label: 'Empresario-Ramo de Magazine',
    },
    {
        value: 'Empresario (A) - Etiq Adesivas',
        label: 'Empresario (A) - Etiq Adesivas',
    },
    {
        value: 'Empresario (A) - Ramo de Saúde',
        label: 'Empresario (A) - Ramo de Saúde',
    },
    {
        value: 'Empresario - Pet Shop',
        label: 'Empresario - Pet Shop',
    },
    {
        value: 'Empresario - Energia Eletrica',
        label: 'Empresario - Energia Eletrica',
    },
    {
        value: 'Engenheiro',
        label: 'Engenheiro',
    },
    {
        value: 'Engenheiro de Producao',
        label: 'Engenheiro de Producao',
    },
    {
        value: 'Engenheiro de Software',
        label: 'Engenheiro de Software',
    },
    {
        value: 'Engenheiro Civil',
        label: 'Engenheiro Civil',
    },
    {
        value: 'Engenheiro Mecanico',
        label: 'Engenheiro Mecanico',
    },
    {
        value: 'Engenheiro Cartografo',
        label: 'Engenheiro Cartografo',
    },
    {
        value: 'Engenheiro Agronomo',
        label: 'Engenheiro Agronomo',
    },
    {
        value: 'Engenheiro Pleno',
        label: 'Engenheiro Pleno',
    },
    {
        value: 'Engenheiro de Sistemas',
        label: 'Engenheiro de Sistemas',
    },
    {
        value: 'Engenheiro de Redes',
        label: 'Engenheiro de Redes',
    },
    {
        value: 'Engenheiro Naval',
        label: 'Engenheiro Naval',
    },
    {
        value: 'Engenheiro - Ramo Petroleo',
        label: 'Engenheiro - Ramo Petroleo',
    },
    {
        value: 'Engenheiro Industrial',
        label: 'Engenheiro Industrial',
    },
    {
        value: 'Engenheiro Eletrico',
        label: 'Engenheiro Eletrico',
    },
    {
        value: 'Engenheiro Metalurgico',
        label: 'Engenheiro Metalurgico',
    },
    {
        value: 'Engenheiro(A) Eletricista',
        label: 'Engenheiro(A) Eletricista',
    },
    {
        value: 'Engenheiro(A) Mecanico(A) Text',
        label: 'Engenheiro(A) Mecanico(A) Text',
    },
    {
        value: 'Engenheiro Eletronico',
        label: 'Engenheiro Eletronico',
    },
    {
        value: 'Engenheiro Quimico',
        label: 'Engenheiro Quimico',
    },
    {
        value: 'Jogador de Futebol',
        label: 'Jogador de Futebol',
    },
    {
        value: 'Atleta Profissional',
        label: 'Atleta Profissional',
    },
    {
        value: 'Atleta Amador',
        label: 'Atleta Amador',
    },
    {
        value: 'Treinador',
        label: 'Treinador',
    },
    {
        value: 'Instrutor de Tenis',
        label: 'Instrutor de Tenis',
    },
    {
        value: 'Instrutor de Judo',
        label: 'Instrutor de Judo',
    },
    {
        value: 'Coordenador de Esportes',
        label: 'Coordenador de Esportes',
    },
    {
        value: 'Instrutor de Volei',
        label: 'Instrutor de Volei',
    },
    {
        value: 'Instrutor de Basquete',
        label: 'Instrutor de Basquete',
    },
    {
        value: 'Personal Trainer',
        label: 'Personal Trainer',
    },
    {
        value: 'Jockey',
        label: 'Jockey',
    },
    {
        value: 'Treinador (A) Natação',
        label: 'Treinador (A) Natação',
    },
    {
        value: 'Estagiario',
        label: 'Estagiario',
    },
    {
        value: 'Estudante',
        label: 'Estudante',
    },
    {
        value: 'Fiscal de Renda',
        label: 'Fiscal de Renda',
    },
    {
        value: 'Fiscal de Transportes',
        label: 'Fiscal de Transportes',
    },
    {
        value: 'Fiscal de Tributos',
        label: 'Fiscal de Tributos',
    },
    {
        value: 'Fiscal de Loja',
        label: 'Fiscal de Loja',
    },
    {
        value: 'Trabalhador Florestal',
        label: 'Trabalhador Florestal',
    },
    {
        value: 'Funcionario Publico',
        label: 'Funcionario Publico',
    },
    {
        value: 'Servidor Publ. Fed.',
        label: 'Servidor Publ. Fed.',
    },
    {
        value: 'Agente Fiscal de Renda',
        label: 'Agente Fiscal de Renda',
    },
    {
        value: 'Copeiro',
        label: 'Copeiro',
    },
    {
        value: 'Cozinheiro',
        label: 'Cozinheiro',
    },
    {
        value: 'Cumin',
        label: 'Cumin',
    },
    {
        value: 'Maitre',
        label: 'Maitre',
    },
    {
        value: 'Padeiro',
        label: 'Padeiro',
    },
    {
        value: 'Cantineira Escolar',
        label: 'Cantineira Escolar',
    },
    {
        value: 'Auxiliar de Cozinheiro ( A )',
        label: 'Auxiliar de Cozinheiro ( A )',
    },
    {
        value: 'Gerente de Recursos Humanos',
        label: 'Gerente de Recursos Humanos',
    },
    {
        value: 'Gerente Marketing',
        label: 'Gerente Marketing',
    },
    {
        value: 'Gerente Territorio',
        label: 'Gerente Territorio',
    },
    {
        value: 'Gerente de Projetos',
        label: 'Gerente de Projetos',
    },
    {
        value: 'Gerente Fiscal',
        label: 'Gerente Fiscal',
    },
    {
        value: 'Gerente de Suporte Tecnico',
        label: 'Gerente de Suporte Tecnico',
    },
    {
        value: 'Gerente de Produtos',
        label: 'Gerente de Produtos',
    },
    {
        value: 'Gerente de Expedicao',
        label: 'Gerente de Expedicao',
    },
    {
        value: 'Gerente Industrial',
        label: 'Gerente Industrial',
    },
    {
        value: 'Gerente de Proc. de Dados',
        label: 'Gerente de Proc. de Dados',
    },
    {
        value: 'Gerente de Loja',
        label: 'Gerente de Loja',
    },
    {
        value: 'Gerente de Condominio',
        label: 'Gerente de Condominio',
    },
    {
        value: 'Gerente de Exportacao Calcados',
        label: 'Gerente de Exportacao Calcados',
    },
    {
        value: 'Gerente de Cargas',
        label: 'Gerente de Cargas',
    },
    {
        value: 'Gerente de Contas',
        label: 'Gerente de Contas',
    },
    {
        value: 'Gerente de Logistica',
        label: 'Gerente de Logistica',
    },
    {
        value: 'Gerente de Restaurante',
        label: 'Gerente de Restaurante',
    },
    {
        value: 'Gerente Custo/Orcamento',
        label: 'Gerente Custo/Orcamento',
    },
    {
        value: 'Gerente de Oficina Mecânica',
        label: 'Gerente de Oficina Mecânica',
    },
    {
        value: 'Gerente de Produção',
        label: 'Gerente de Produção',
    },
    {
        value: 'Gerente de Cobrança',
        label: 'Gerente de Cobrança',
    },
    {
        value: 'Gerente - Agencia Bancária',
        label: 'Gerente - Agencia Bancária',
    },
    {
        value: 'Gerente Telecomunicacoes',
        label: 'Gerente Telecomunicacoes',
    },
    {
        value: 'Gerente de Assistência Técnica',
        label: 'Gerente de Assistência Técnica',
    },
    {
        value: 'Gerente de Moda',
        label: 'Gerente de Moda',
    },
    {
        value: 'Gerente Comercial',
        label: 'Gerente Comercial',
    },
    {
        value: 'Gerente Financeiro',
        label: 'Gerente Financeiro',
    },
    {
        value: 'Gerente de Vendas',
        label: 'Gerente de Vendas',
    },
    {
        value: 'Padre / Freira / Pastor',
        label: 'Padre / Freira / Pastor',
    },
    {
        value: 'Serralheiro',
        label: 'Serralheiro',
    },
    {
        value: 'Ferramenteiro',
        label: 'Ferramenteiro',
    },
    {
        value: 'Industriario',
        label: 'Industriario',
    },
    {
        value: 'Operario',
        label: 'Operario',
    },
    {
        value: 'Ritilinista',
        label: 'Ritilinista',
    },
    {
        value: 'Auxiliar de Eletronica',
        label: 'Auxiliar de Eletronica',
    },
    {
        value: 'Industriario Ramo Refinaria',
        label: 'Industriario Ramo Refinaria',
    },
    {
        value: 'Industrial de Calcados',
        label: 'Industrial de Calcados',
    },
    {
        value: 'Auxiliar de Manutencao',
        label: 'Auxiliar de Manutencao',
    },
    {
        value: 'Auxiliar de Producao',
        label: 'Auxiliar de Producao',
    },
    {
        value: 'Aux. Tecnico de Refrigeracao',
        label: 'Aux. Tecnico de Refrigeracao',
    },
    {
        value: '1/2 Oficial Montagem',
        label: '1/2 Oficial Montagem',
    },
    {
        value: 'Embalador de Pecas de Auto',
        label: 'Embalador de Pecas de Auto',
    },
    {
        value: 'Industrial',
        label: 'Industrial',
    },
    {
        value: 'Assistente de Producao',
        label: 'Assistente de Producao',
    },
    {
        value: 'Programador de Sistemas',
        label: 'Programador de Sistemas',
    },
    {
        value: 'Analista de Programacao',
        label: 'Analista de Programacao',
    },
    {
        value: 'Digitadora',
        label: 'Digitadora',
    },
    {
        value: 'Analista de Suporte',
        label: 'Analista de Suporte',
    },
    {
        value: 'Analista de Sistemas',
        label: 'Analista de Sistemas',
    },
    {
        value: 'Desembargador',
        label: 'Desembargador',
    },
    {
        value: 'Diplomata',
        label: 'Diplomata',
    },
    {
        value: 'Escrevente',
        label: 'Escrevente',
    },
    {
        value: 'Escrivão',
        label: 'Escrivão',
    },
    {
        value: 'Juiz de Direito',
        label: 'Juiz de Direito',
    },
    {
        value: 'Magistrado',
        label: 'Magistrado',
    },
    {
        value: 'Oficial de Justica',
        label: 'Oficial de Justica',
    },
    {
        value: 'Perito',
        label: 'Perito',
    },
    {
        value: 'Promotor de Justica',
        label: 'Promotor de Justica',
    },
    {
        value: 'Serventuario',
        label: 'Serventuario',
    },
    {
        value: 'Tabeliao',
        label: 'Tabeliao',
    },
    {
        value: 'Escriturario',
        label: 'Escriturario',
    },
    {
        value: 'Conselheiro Tutelar',
        label: 'Conselheiro Tutelar',
    },
    {
        value: 'Magistrado Federal',
        label: 'Magistrado Federal',
    },
    {
        value: 'Auxiliar Tributario',
        label: 'Auxiliar Tributario',
    },
    {
        value: 'Servidor (A) da Justica',
        label: 'Servidor (A) da Justica',
    },
    {
        value: 'Auxiliar Judiciário',
        label: 'Auxiliar Judiciário',
    },
    {
        value: 'Perito Judicial',
        label: 'Perito Judicial',
    },
    {
        value: 'Assessor(A) Jurídico',
        label: 'Assessor(A) Jurídico',
    },
    {
        value: 'Oficial da Marinha',
        label: 'Oficial da Marinha',
    },
    {
        value: 'Portuario',
        label: 'Portuario',
    },
    {
        value: 'Marinheiro',
        label: 'Marinheiro',
    },
    {
        value: 'Pratico',
        label: 'Pratico',
    },
    {
        value: 'Piloto de Embarcações',
        label: 'Piloto de Embarcações',
    },
    {
        value: 'Conferente Portuario',
        label: 'Conferente Portuario',
    },
    {
        value: 'Mecanico de Manutencao',
        label: 'Mecanico de Manutencao',
    },
    {
        value: 'Mecanico Diesel',
        label: 'Mecanico Diesel',
    },
    {
        value: 'Frezador',
        label: 'Frezador',
    },
    {
        value: 'Fundidor',
        label: 'Fundidor',
    },
    {
        value: 'Mandrilhador',
        label: 'Mandrilhador',
    },
    {
        value: 'Metalurgico',
        label: 'Metalurgico',
    },
    {
        value: 'Torneiro Ferramenteiro',
        label: 'Torneiro Ferramenteiro',
    },
    {
        value: 'Torneiro Mecanico',
        label: 'Torneiro Mecanico',
    },
    {
        value: 'Alinhador Automotivo',
        label: 'Alinhador Automotivo',
    },
    {
        value: 'Furador Radial',
        label: 'Furador Radial',
    },
    {
        value: 'Lider de Montagem',
        label: 'Lider de Montagem',
    },
    {
        value: 'Auxiliar Montador Metalurgica',
        label: 'Auxiliar Montador Metalurgica',
    },
    {
        value: 'Ajudante de Metalurgica',
        label: 'Ajudante de Metalurgica',
    },
    {
        value: 'Operador de Armazem',
        label: 'Operador de Armazem',
    },
    {
        value: 'Operador de Caixa',
        label: 'Operador de Caixa',
    },
    {
        value: 'Operador de Valores',
        label: 'Operador de Valores',
    },
    {
        value: 'Operador Comercial',
        label: 'Operador Comercial',
    },
    {
        value: 'Operador Financeiro',
        label: 'Operador Financeiro',
    },
    {
        value: 'Operador de Sistemas',
        label: 'Operador de Sistemas',
    },
    {
        value: 'Operador de Open',
        label: 'Operador de Open',
    },
    {
        value: 'Operador Portuario',
        label: 'Operador Portuario',
    },
    {
        value: 'Operador de Processamento',
        label: 'Operador de Processamento',
    },
    {
        value: 'Operador Industrial',
        label: 'Operador Industrial',
    },
    {
        value: 'Operador de Maquinas',
        label: 'Operador de Maquinas',
    },
    {
        value: 'Operador (A) Bolsa de Valores',
        label: 'Operador (A) Bolsa de Valores',
    },
    {
        value: 'Operador de Audio',
        label: 'Operador de Audio',
    },
    {
        value: 'Operador de Planta Quimica',
        label: 'Operador de Planta Quimica',
    },
    {
        value: 'Operador de Telecomunicações',
        label: 'Operador de Telecomunicações',
    },
    {
        value: 'Operador de Bombeamento',
        label: 'Operador de Bombeamento',
    },
    {
        value: 'Operador de Montagem',
        label: 'Operador de Montagem',
    },
    {
        value: 'Operador de Escavadeira',
        label: 'Operador de Escavadeira',
    },
    {
        value: 'Operador de Telemarketing',
        label: 'Operador de Telemarketing',
    },
    {
        value: 'Sarrafeiro',
        label: 'Sarrafeiro',
    },
    {
        value: 'Pecuarista',
        label: 'Pecuarista',
    },
    {
        value: 'Sexador de Animais em Granja',
        label: 'Sexador de Animais em Granja',
    },
    {
        value: 'Suinocultor',
        label: 'Suinocultor',
    },
    {
        value: 'Vaqueiro',
        label: 'Vaqueiro',
    },
    {
        value: 'Trabalhador da Pecuaria',
        label: 'Trabalhador da Pecuaria',
    },
    {
        value: 'Trabalhador da Suinocultura',
        label: 'Trabalhador da Suinocultura',
    },
    {
        value: 'Avicultor',
        label: 'Avicultor',
    },
    {
        value: 'Aux Larvicultura',
        label: 'Aux Larvicultura',
    },
    {
        value: 'Pensionista',
        label: 'Pensionista',
    },
    {
        value: 'Pescador (A)',
        label: 'Pescador (A)',
    },
    {
        value: 'Piscicultor',
        label: 'Piscicultor',
    },
    {
        value: 'Trabalhador da Pesca',
        label: 'Trabalhador da Pesca',
    },
    {
        value: 'Pesquisador',
        label: 'Pesquisador',
    },
    {
        value: 'Pesquisadora Ipt',
        label: 'Pesquisadora Ipt',
    },
    {
        value: 'Pesquisador de Mercado',
        label: 'Pesquisador de Mercado',
    },
    {
        value: 'Piloto Aeronaves/ Não Regular',
        label: 'Piloto Aeronaves/ Não Regular',
    },
    {
        value: 'Piloto',
        label: 'Piloto',
    },
    {
        value: 'Piloto Aeronaves/Linha Regular',
        label: 'Piloto Aeronaves/Linha Regular',
    },
    {
        value: 'Guarda Municipal',
        label: 'Guarda Municipal',
    },
    {
        value: 'Investigador de Policia',
        label: 'Investigador de Policia',
    },
    {
        value: 'Delegado de Policia',
        label: 'Delegado de Policia',
    },
    {
        value: 'Guarda',
        label: 'Guarda',
    },
    {
        value: 'Agente Penitenciário',
        label: 'Agente Penitenciário',
    },
    {
        value: 'Policial',
        label: 'Policial',
    },
    {
        value: 'Bombeiro',
        label: 'Bombeiro',
    },
    {
        value: 'Militar',
        label: 'Militar',
    },
    {
        value: 'Perito(A) Criminal',
        label: 'Perito(A) Criminal',
    },
    {
        value: 'Assessor(A) Parlamentar',
        label: 'Assessor(A) Parlamentar',
    },
    {
        value: 'Deputado Federal',
        label: 'Deputado Federal',
    },
    {
        value: 'Deputado Estadual',
        label: 'Deputado Estadual',
    },
    {
        value: 'Prefeito',
        label: 'Prefeito',
    },
    {
        value: 'Estivador',
        label: 'Estivador',
    },
    {
        value: 'Presidente',
        label: 'Presidente',
    },
    {
        value: 'Dentista',
        label: 'Dentista',
    },
    {
        value: 'Enfermeiro',
        label: 'Enfermeiro',
    },
    {
        value: 'Esteticista',
        label: 'Esteticista',
    },
    {
        value: 'Farmaceutico',
        label: 'Farmaceutico',
    },
    {
        value: 'Fisioterapeuta',
        label: 'Fisioterapeuta',
    },
    {
        value: 'Fonoaudiologo',
        label: 'Fonoaudiologo',
    },
    {
        value: 'Instrumentador(A)  Cirurgico(A)',
        label: 'Instrumentador(A)  Cirurgico(A)',
    },
    {
        value: 'Massagista',
        label: 'Massagista',
    },
    {
        value: 'Medico',
        label: 'Medico',
    },
    {
        value: 'Nutricionista',
        label: 'Nutricionista',
    },
    {
        value: 'Odontologo',
        label: 'Odontologo',
    },
    {
        value: 'Optico',
        label: 'Optico',
    },
    {
        value: 'Perfusionista',
        label: 'Perfusionista',
    },
    {
        value: 'Protetico',
        label: 'Protetico',
    },
    {
        value: 'Psicologo',
        label: 'Psicologo',
    },
    {
        value: 'Psiquiatra',
        label: 'Psiquiatra',
    },
    {
        value: 'Veterinario',
        label: 'Veterinario',
    },
    {
        value: 'Auxiliar de Enfermagem',
        label: 'Auxiliar de Enfermagem',
    },
    {
        value: 'Podologo',
        label: 'Podologo',
    },
    {
        value: 'Terapeuta Ocupacional',
        label: 'Terapeuta Ocupacional',
    },
    {
        value: 'Biomedica',
        label: 'Biomedica',
    },
    {
        value: 'Bioquimico (A)',
        label: 'Bioquimico (A)',
    },
    {
        value: 'Ortodontista',
        label: 'Ortodontista',
    },
    {
        value: 'Atendente Enfermagem',
        label: 'Atendente Enfermagem',
    },
    {
        value: 'Auxiliar de Protetico',
        label: 'Auxiliar de Protetico',
    },
    {
        value: 'Inspetor de Laboratorio',
        label: 'Inspetor de Laboratorio',
    },
    {
        value: 'Medico(A) Radiologista',
        label: 'Medico(A) Radiologista',
    },
    {
        value: 'Psicoterapeuta',
        label: 'Psicoterapeuta',
    },
    {
        value: 'Medico (A) Anestesista',
        label: 'Medico (A) Anestesista',
    },
    {
        value: 'Anestesista',
        label: 'Anestesista',
    },
    {
        value: 'Medico(A) Oftalmologista',
        label: 'Medico(A) Oftalmologista',
    },
    {
        value: 'Agente de Saude',
        label: 'Agente de Saude',
    },
    {
        value: 'Cardiologista',
        label: 'Cardiologista',
    },
    {
        value: 'Auxiliar de Laboratorio',
        label: 'Auxiliar de Laboratorio',
    },
    {
        value: 'Massoterapeuta',
        label: 'Massoterapeuta',
    },
    {
        value: 'Cirurgiao Dentista',
        label: 'Cirurgiao Dentista',
    },
    {
        value: 'Secretaria Compra  Metalurgica',
        label: 'Secretaria Compra  Metalurgica',
    },
    {
        value: 'Secretaria da Diretoria',
        label: 'Secretaria da Diretoria',
    },
    {
        value: 'Secretario ( A )',
        label: 'Secretario ( A )',
    },
    {
        value: 'Vigilante',
        label: 'Vigilante',
    },
    {
        value: 'Vigia',
        label: 'Vigia',
    },
    {
        value: 'Seguranca',
        label: 'Seguranca',
    },
    {
        value: 'Empres.Prest Serv Vigilancia Particular',
        label: 'Empres.Prest Serv Vigilancia Particular',
    },
    {
        value: 'Inspetor de Seguranca',
        label: 'Inspetor de Seguranca',
    },
    {
        value: 'Analista de Segurança',
        label: 'Analista de Segurança',
    },
    {
        value: 'Agente de Seguros',
        label: 'Agente de Seguros',
    },
    {
        value: 'Assessora de Seguros',
        label: 'Assessora de Seguros',
    },
    {
        value: 'Analista de Sinistro',
        label: 'Analista de Sinistro',
    },
    {
        value: 'Motoboy',
        label: 'Motoboy',
    },
    {
        value: 'Caminhoneiro',
        label: 'Caminhoneiro',
    },
    {
        value: 'Servente',
        label: 'Servente',
    },
    {
        value: 'Arrumadeira /Camareira',
        label: 'Arrumadeira /Camareira',
    },
    {
        value: 'Barbeiro',
        label: 'Barbeiro',
    },
    {
        value: 'Barman',
        label: 'Barman',
    },
    {
        value: 'Borracheiro',
        label: 'Borracheiro',
    },
    {
        value: 'Cabelereiro(a)',
        label: 'Cabelereiro(a)',
    },
    {
        value: 'Carteiro',
        label: 'Carteiro',
    },
    {
        value: 'Cartorario',
        label: 'Cartorario',
    },
    {
        value: 'Depiladora',
        label: 'Depiladora',
    },
    {
        value: 'Despachante',
        label: 'Despachante',
    },
    {
        value: 'Eletricista',
        label: 'Eletricista',
    },
    {
        value: 'Eletricitario',
        label: 'Eletricitario',
    },
    {
        value: 'Eletrotecnico',
        label: 'Eletrotecnico',
    },
    {
        value: 'Empregada Domestica / Babá / Faxineira',
        label: 'Empregada Domestica / Babá / Faxineira',
    },
    {
        value: 'Funileiro',
        label: 'Funileiro',
    },
    {
        value: 'Garcom',
        label: 'Garcom',
    },
    {
        value: 'Governanta',
        label: 'Governanta',
    },
    {
        value: 'Guia Turistico',
        label: 'Guia Turistico',
    },
    {
        value: 'Instrutor',
        label: 'Instrutor',
    },
    {
        value: 'Jardineiro',
        label: 'Jardineiro',
    },
    {
        value: 'Leloeiro',
        label: 'Leloeiro',
    },
    {
        value: 'Manicure / Pedicure / Podologo / ',
        label: 'Manicure / Pedicure / Podologo / ',
    },
    {
        value: 'Manobrista',
        label: 'Manobrista',
    },
    {
        value: 'Marmorista',
        label: 'Marmorista',
    },
    {
        value: 'Mecanico',
        label: 'Mecanico',
    },
    {
        value: 'Mensageiro',
        label: 'Mensageiro',
    },
    {
        value: 'Pespontador de Calcados',
        label: 'Pespontador de Calcados',
    },
    {
        value: 'Porteiro',
        label: 'Porteiro',
    },
    {
        value: 'Sapateiro',
        label: 'Sapateiro',
    },
    {
        value: 'Taxista',
        label: 'Taxista',
    },
    {
        value: 'Telefonista',
        label: 'Telefonista',
    },
    {
        value: 'Telegrafista',
        label: 'Telegrafista',
    },
    {
        value: 'Tintureiro',
        label: 'Tintureiro',
    },
    {
        value: 'Zelador / Caseiro',
        label: 'Zelador / Caseiro',
    },
    {
        value: 'Costureira (A)',
        label: 'Costureira (A)',
    },
    {
        value: 'Agente de Viagem',
        label: 'Agente de Viagem',
    },
    {
        value: 'Vistoriador',
        label: 'Vistoriador',
    },
    {
        value: 'Caseiro',
        label: 'Caseiro',
    },
    {
        value: 'Baba',
        label: 'Baba',
    },
    {
        value: 'Diarista',
        label: 'Diarista',
    },
    {
        value: 'Divulgadora de Prod Higienicos',
        label: 'Divulgadora de Prod Higienicos',
    },
    {
        value: 'Auxiliar Producao - Estofados',
        label: 'Auxiliar Producao - Estofados',
    },
    {
        value: 'Modelista de Calcados',
        label: 'Modelista de Calcados',
    },
    {
        value: 'Auxiliar Na Rede Telefonica',
        label: 'Auxiliar Na Rede Telefonica',
    },
    {
        value: 'Instalador de Calhas',
        label: 'Instalador de Calhas',
    },
    {
        value: 'Comprador',
        label: 'Comprador',
    },
    {
        value: 'Bombeiro Hidraulico/encanador',
        label: 'Bombeiro Hidraulico/encanador',
    },
    {
        value: 'Instrutor de Auto Escola',
        label: 'Instrutor de Auto Escola',
    },
    {
        value: 'Entregador',
        label: 'Entregador',
    },
    {
        value: 'Lavador de Automoveis',
        label: 'Lavador de Automoveis',
    },
    {
        value: 'Instalador de Persianas',
        label: 'Instalador de Persianas',
    },
    {
        value: 'Auxiliar Tecnico',
        label: 'Auxiliar Tecnico',
    },
    {
        value: 'Auxiliar Tecn. Telecomunicacao',
        label: 'Auxiliar Tecn. Telecomunicacao',
    },
    {
        value: 'Camareira',
        label: 'Camareira',
    },
    {
        value: 'Cadastrador',
        label: 'Cadastrador',
    },
    {
        value: 'Instalador de Box',
        label: 'Instalador de Box',
    },
    {
        value: 'Auxiliar de Serviços - Limpeza',
        label: 'Auxiliar de Serviços - Limpeza',
    },
    {
        value: 'Demonstradora-Exposição  Autos',
        label: 'Demonstradora-Exposição  Autos',
    },
    {
        value: 'Montador de Esteiras',
        label: 'Montador de Esteiras',
    },
    {
        value: 'Caixa',
        label: 'Caixa',
    },
    {
        value: 'Auxiliar de Serviços Gerais',
        label: 'Auxiliar de Serviços Gerais',
    },
    {
        value: 'Encadernador',
        label: 'Encadernador',
    },
    {
        value: 'Mordomo',
        label: 'Mordomo',
    },
    {
        value: 'Classificador de Couro',
        label: 'Classificador de Couro',
    },
    {
        value: 'Maquiadora',
        label: 'Maquiadora',
    },
    {
        value: 'Pet Shop',
        label: 'Pet Shop',
    },
    {
        value: 'Cavaleiro',
        label: 'Cavaleiro',
    },
    {
        value: 'Calheiro',
        label: 'Calheiro',
    },
    {
        value: 'Adestrador de Cães',
        label: 'Adestrador de Cães',
    },
    {
        value: 'Gesseiro',
        label: 'Gesseiro',
    },
    {
        value: 'Instalador de Paineis',
        label: 'Instalador de Paineis',
    },
    {
        value: 'Instalador de Antena',
        label: 'Instalador de Antena',
    },
    {
        value: 'Ajudante Geral',
        label: 'Ajudante Geral',
    },
    {
        value: 'Faxineiro',
        label: 'Faxineiro',
    },
    {
        value: 'Auxiliar Despachante',
        label: 'Auxiliar Despachante',
    },
    {
        value: 'Tosador',
        label: 'Tosador',
    },
    {
        value: 'Esteticista',
        label: 'Esteticista',
    },
    {
        value: 'Siderúrgico',
        label: 'Siderúrgico',
    },
    {
        value: 'Soldador',
        label: 'Soldador',
    },
    {
        value: 'Assessor(A) Sindical',
        label: 'Assessor(A) Sindical',
    },
    {
        value: 'Diretora Sindical',
        label: 'Diretora Sindical',
    },
    {
        value: 'Superintendente',
        label: 'Superintendente',
    },
    {
        value: 'Supervisor de Vigilante',
        label: 'Supervisor de Vigilante',
    },
    {
        value: 'Supervisor de Compras',
        label: 'Supervisor de Compras',
    },
    {
        value: 'Supervisor de Vendas',
        label: 'Supervisor de Vendas',
    },
    {
        value: 'Supervisor de Contas A Pagar',
        label: 'Supervisor de Contas A Pagar',
    },
    {
        value: 'Supervisor de Producao',
        label: 'Supervisor de Producao',
    },
    {
        value: 'Supervisor de Treinamento',
        label: 'Supervisor de Treinamento',
    },
    {
        value: 'Supervisor de Suprimentos',
        label: 'Supervisor de Suprimentos',
    },
    {
        value: 'Supervisor  Ramo Transportes',
        label: 'Supervisor  Ramo Transportes',
    },
    {
        value: 'Supervisor Serv. Mecanicos',
        label: 'Supervisor Serv. Mecanicos',
    },
    {
        value: 'Supervisor Administrativo',
        label: 'Supervisor Administrativo',
    },
    {
        value: 'Supervisor de Manutencao',
        label: 'Supervisor de Manutencao',
    },
    {
        value: 'Supervisor  Operacoes Petroleo',
        label: 'Supervisor  Operacoes Petroleo',
    },
    {
        value: 'Supervisor de Projetos',
        label: 'Supervisor de Projetos',
    },
    {
        value: 'Supervisor de Exp. Auto Peças',
        label: 'Supervisor de Exp. Auto Peças',
    },
    {
        value: 'Supervisor',
        label: 'Supervisor',
    },
    {
        value: 'Tecnico Industriario',
        label: 'Tecnico Industriario',
    },
    {
        value: 'Tecnico de Sondagem',
        label: 'Tecnico de Sondagem',
    },
    {
        value: 'Tecnico de Informatica',
        label: 'Tecnico de Informatica',
    },
    {
        value: 'Tecnico Ultra-Som',
        label: 'Tecnico Ultra-Som',
    },
    {
        value: 'Tecnico em Laboratorio',
        label: 'Tecnico em Laboratorio',
    },
    {
        value: 'Tecnico',
        label: 'Tecnico',
    },
    {
        value: 'Tecnico Agricola',
        label: 'Tecnico Agricola',
    },
    {
        value: 'Tecnico Eletronico',
        label: 'Tecnico Eletronico',
    },
    {
        value: 'Tecnico Avionico',
        label: 'Tecnico Avionico',
    },
    {
        value: 'Tecnico Refrigeracao',
        label: 'Tecnico Refrigeracao',
    },
    {
        value: 'Tecnico em Agrimensura',
        label: 'Tecnico em Agrimensura',
    },
    {
        value: 'Tecnico em Contabilidade',
        label: 'Tecnico em Contabilidade',
    },
    {
        value: 'Tecnologo',
        label: 'Tecnologo',
    },
    {
        value: 'Tecnico Eletrotecnico',
        label: 'Tecnico Eletrotecnico',
    },
    {
        value: 'Tecnico em Metalurgica',
        label: 'Tecnico em Metalurgica',
    },
    {
        value: 'Tecnico de Futebol',
        label: 'Tecnico de Futebol',
    },
    {
        value: 'Tecnico em Edificacoes',
        label: 'Tecnico em Edificacoes',
    },
    {
        value: 'Tecnico em Televisao',
        label: 'Tecnico em Televisao',
    },
    {
        value: 'Tecnica em Nutricao',
        label: 'Tecnica em Nutricao',
    },
    {
        value: 'Tecnico em Celular',
        label: 'Tecnico em Celular',
    },
    {
        value: 'Tecnico Termo Plasticos',
        label: 'Tecnico Termo Plasticos',
    },
    {
        value: 'Tecnico Materia-Prima Plastico',
        label: 'Tecnico Materia-Prima Plastico',
    },
    {
        value: 'Tecnica em Computacao',
        label: 'Tecnica em Computacao',
    },
    {
        value: 'Tecnico em Manutencao',
        label: 'Tecnico em Manutencao',
    },
    {
        value: 'Tecnico Operador',
        label: 'Tecnico Operador',
    },
    {
        value: 'Técnico Ambiental',
        label: 'Técnico Ambiental',
    },
    {
        value: 'Técnico em Couros e Calçados',
        label: 'Técnico em Couros e Calçados',
    },
    {
        value: 'Tecnologo de Automação',
        label: 'Tecnologo de Automação',
    },
    {
        value: 'Técnico Esteticista',
        label: 'Técnico Esteticista',
    },
    {
        value: 'Técnico em Açúcar e Álcool',
        label: 'Técnico em Açúcar e Álcool',
    },
    {
        value: 'Tecnico em Administracao',
        label: 'Tecnico em Administracao',
    },
    {
        value: 'Tecnico Seguranca de Trabalho',
        label: 'Tecnico Seguranca de Trabalho',
    },
    {
        value: 'Tecnico em Raio X',
        label: 'Tecnico em Raio X',
    },
    {
        value: 'Tecnico em Enfermagem',
        label: 'Tecnico em Enfermagem',
    },
    {
        value: 'Ajudante de Motorista',
        label: 'Ajudante de Motorista',
    },
    {
        value: 'Ferroviario',
        label: 'Ferroviario',
    },
    {
        value: 'Metroviário',
        label: 'Metroviário',
    },
    {
        value: 'Motorista',
        label: 'Motorista',
    },
    {
        value: 'Cobrador de Onibus',
        label: 'Cobrador de Onibus',
    },
    {
        value: 'Inspetor Ramo Transportes',
        label: 'Inspetor Ramo Transportes',
    },
    {
        value: 'Auxiliar  Estatis. Transporte',
        label: 'Auxiliar  Estatis. Transporte',
    },
    {
        value: 'Metroviário - Bilheteria',
        label: 'Metroviário - Bilheteria',
    },
    {
        value: 'Analista de Tacografo',
        label: 'Analista de Tacografo',
    },
    {
        value: 'Condutor de Trem',
        label: 'Condutor de Trem',
    },
    {
        value: 'Universitario',
        label: 'Universitario',
    },
    {
        value: 'Vendedor Ambulante',
        label: 'Vendedor Ambulante',
    },
    {
        value: 'Vendedor',
        label: 'Vendedor',
    },
    {
        value: 'Vendedor de Veiculos',
        label: 'Vendedor de Veiculos',
    },
    {
        value: 'Vendedor Nautico',
        label: 'Vendedor Nautico',
    },
    {
        value: 'Vendedor (A) de Barcos',
        label: 'Vendedor (A) de Barcos',
    },
    {
        value: 'Vendedor  de Auto-Pecas',
        label: 'Vendedor  de Auto-Pecas',
    },
    {
        value: 'Vendedora de Livros',
        label: 'Vendedora de Livros',
    },
    {
        value: 'Vendedor Tecnico',
        label: 'Vendedor Tecnico',
    },
    {
        value: 'Vendedor de Pedras Preciosas',
        label: 'Vendedor de Pedras Preciosas',
    },
    {
        value: 'Vendedor - Ramo Laboratorio',
        label: 'Vendedor - Ramo Laboratorio',
    },
    {
        value: 'Vendedor Estruturas Metalicas',
        label: 'Vendedor Estruturas Metalicas',
    },
    {
        value: 'Vendedor Produtos Plasticos',
        label: 'Vendedor Produtos Plasticos',
    },
    {
        value: 'Vendedor  - Ramo de Sapatos',
        label: 'Vendedor  - Ramo de Sapatos',
    },
    {
        value: 'Vendedor de Roupas',
        label: 'Vendedor de Roupas',
    },
    {
        value: 'Vendedor - Plano de Saude',
        label: 'Vendedor - Plano de Saude',
    },
    {
        value: 'Vendedor de Tintas',
        label: 'Vendedor de Tintas',
    },
    {
        value: 'Vendedor - Ramo Eletronicos',
        label: 'Vendedor - Ramo Eletronicos',
    },
    {
        value: 'Vendedor de Bebidas',
        label: 'Vendedor de Bebidas',
    },
    {
        value: 'Vendedor de Pocos Artesianos',
        label: 'Vendedor de Pocos Artesianos',
    },
    {
        value: 'Vendedor Autonomo de Lingerie',
        label: 'Vendedor Autonomo de Lingerie',
    },
    {
        value: 'Vendedor de Tv A Cabo',
        label: 'Vendedor de Tv A Cabo',
    },
    {
        value: 'Vendedor Aut. Bilhete Loteria',
        label: 'Vendedor Aut. Bilhete Loteria',
    },
    {
        value: 'Vendedor de Carvao',
        label: 'Vendedor de Carvao',
    },
    {
        value: 'Vendedor de Gado',
        label: 'Vendedor de Gado',
    },
    {
        value: 'Vendedor - Ramo de Alimentos',
        label: 'Vendedor - Ramo de Alimentos',
    },
    {
        value: 'Vendedor (A) - Fliperama',
        label: 'Vendedor (A) - Fliperama',
    },
    {
        value: 'Vendedor(A) - Farmaceutico',
        label: 'Vendedor(A) - Farmaceutico',
    },
    {
        value: 'Vendedor -  Atacado de Doces',
        label: 'Vendedor -  Atacado de Doces',
    },
    {
        value: 'Vendedor de Brinquedos',
        label: 'Vendedor de Brinquedos',
    },
    {
        value: 'Vendedor de Miniaturas',
        label: 'Vendedor de Miniaturas',
    },
    {
        value: 'Vendedor - Papelaria',
        label: 'Vendedor - Papelaria',
    },
    {
        value: 'Vendedor - Material Construcao',
        label: 'Vendedor - Material Construcao',
    },
    {
        value: 'Vendedor de Pneus',
        label: 'Vendedor de Pneus',
    },
    {
        value: 'Vendedor Material Hospitalar',
        label: 'Vendedor Material Hospitalar',
    },
    {
        value: 'Vendedor(A) - Produtos Beleza',
        label: 'Vendedor(A) - Produtos Beleza',
    },
    {
        value: 'Vendedor de Persianas',
        label: 'Vendedor de Persianas',
    },
    {
        value: 'Vendedor Radiadores/Colmeias',
        label: 'Vendedor Radiadores/Colmeias',
    },
    {
        value: 'Vendedor(A) de Cigarros',
        label: 'Vendedor(A) de Cigarros',
    },
    {
        value: 'Vendedor - Material Eletrico',
        label: 'Vendedor - Material Eletrico',
    },
    {
        value: 'Vendedor - Ramo de Marmores',
        label: 'Vendedor - Ramo de Marmores',
    },
    {
        value: 'Vendedor Estof e Colchões',
        label: 'Vendedor Estof e Colchões',
    },
    {
        value: 'Vendedor de Consórcio',
        label: 'Vendedor de Consórcio',
    },
    {
        value: 'Vereador',
        label: 'Vereador',
    },
]

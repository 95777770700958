<div >
    <div class="title-toggle">
        <h2>Conheça também os seguros</h2>
        <h2>Allianz Residencia e Allianz Vida!</h2>
    </div>
    <div [id]="idElement1" class="know-more-content" [ngClass]="{'is-even': isEven1, 'is-other': isOther1}">
        <app-image [path]="img1 ? img1 : data1.image" [title]="title1 ? title1 : data1.text" [alt]="data1.alt_text"></app-image>
        <div class="know-more-wrapper">
            <div class="title" [innerHTML]="title1 || data1.text | htmlParse"></div>
            <div class="subtitle" [innerHTML]="text1 || data1.description | htmlParse"></div>
            <div class="title" [innerHTML]="titleFooter1 || data1.text | htmlParse"></div>
            <div *ngIf="textFooter1" class="textFooter" [innerHTML]="textFooter1 || data1.text | htmlParse"></div>
        </div>
    </div>
    <div [id]="idElement2" class="know-more-content" [ngClass]="{'is-even': isEven2, 'is-other': isOther2}">
        <app-image [path]="img2 ? img2 : data2.image" [title]="title2 ? title2 : data2.text" [alt]="data2.alt_text"></app-image>
        <div class="know-more-wrapper" >
            <div class="title" [innerHTML]="title2 || data2.text | htmlParse"></div>
            <div class="subtitle" [innerHTML]="text2 || data2.description | htmlParse"></div>
            <div class="title mt-2" [innerHTML]="titleFooter2 || data2.text | htmlParse"></div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks'
import { AppState } from '../../state/app-state'
import { Store } from '@ngrx/store'
import { AddGlobals, ClearGlobals } from '../../state/globals/globals.action'
import { ActivatedRoute, Router } from '@angular/router'

@Injectable({
	providedIn: 'root',
})
export class LandPagesService {
	constructor(private http: HttpClient, private store: Store<AppState>, public router: Router, public routerActivated: ActivatedRoute) {}

	getLandingPagesData(payload: any,url: string, callback: Callbacks): void {
		this.http
			.get(`${environment.api}api/landpages/${url}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data: any) => {
					if (data) {
						this.setGlobalsConfig(data)
						const { pageContent } = data
						callback.fnSuccess(pageContent, data)
					} else {
						this.router.navigate(['/not-found/404/*'])
					}
				},
				error: (err) => {
					callback.fnError(err)
					this.router.navigate(['/not-found/404/*'])
				},
			})
	}

	getPartnersData(payload: any, url: string, callback: Callbacks): void {
		this.http
			.get(`${environment.api}api/page/${url}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data: any) => {
					if (data) {
						this.setGlobalsConfig(data)
						const { pageContent } = data
						callback.fnSuccess(pageContent)
					} else {
						this.router.navigate(['/not-found/404/*'])
					}
				},
				error: (err) => {
					callback.fnError(err)
					this.router.navigate(['/not-found/404/*'])
				},
			})
	}
	setGlobalsConfig(data: any): void {
		const { header, footer } = data
		this.store.dispatch(ClearGlobals())
		this.store.dispatch(AddGlobals({ header: header, footer: footer }))
	}

	getHomePage(payload: any, callback: Callbacks): void {
		this.http
			.get(`${environment.api}api/page/${payload.partner}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data: any) => {
					this.setGlobalsConfig(data)
					const { pageContent } = data
					callback.fnSuccess(pageContent)
				},
				error: (err) => {
					console.warn(err)
					callback.fnError(err)
				},
			})
	}
}

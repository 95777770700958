import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { ParceirosInsuranceComponent } from './pages/parceiros-insurance/parceiros-insurance.component'
import { ProdutosInsuranceComponent } from './pages/produtos-insurance/produtos-insurance.component'
// import { FormulaeComponent } from './pages/formulae/formulae.component'

const routes: Routes = [
	{
		path: '',
		component: ParceirosInsuranceComponent,
		data: { title: 'Seguro Allianz - Faça sua cotação' },
	},
    // {
    //     path: 'formulae',
    //     component: FormulaeComponent,
    //     data: { title: 'Seguro Allianz - Faça sua cotação' },
    // },
	{
		path: 'auto',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Auto! Faça sua cotação' },
	},
	{
		path: 'residencia',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Residência! Faça sua cotação' },
	},
    {
		path: 'empresarial',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Empresarial! Faça sua cotação' },
	},
	{
		path: 'vida',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Vida! Faça sua cotação' },
	},
	{
		path: 'vida-rappiprime',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Vida! Faça sua cotação' },
	},
	{
		path: 'residencia-rappiprime',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Residência! Faça sua cotação' },
	},
	{
		path: ':parceiro/:produto',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Produto! Faça sua cotação' },
	},
	{
		path: ':parceiro',
		component: ParceirosInsuranceComponent,
		data: { title: 'Seguro Allianz - Parceiro! Faça sua cotação' },
	},
	{
		path: ':parceiro/:produto/:uuid/preview',
		component: ProdutosInsuranceComponent,
		data: { title: 'Seguro Allianz - Produto Preview! Faça sua cotação' },
	},
	{
		path: ':parceiro/:uuid/preview',
		component: ParceirosInsuranceComponent,
		data: { title: 'Seguro Allianz - Parceiro Preview! Faça sua cotação' },
	},
	// {
	// 	path: 'not-found/404/*',
	// 	component: NotFoundComponent,
	// 	data: { title: 'Página não encontrada' },
	// },
	{
		path: '**',
		redirectTo: '/',
		data: { title: 'Seguro Allianz - Faça sua cotação' },
	}
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 100],
        }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

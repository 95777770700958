<div class="modal-content">
    <button
        type="button"
        class="close light-gray-bg-xp gray-text-xp"
        (click)="close()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <app-image *ngIf="figure" class="modal-figure" [path]="figure" [alt]="alt_text"></app-image>
        <h2 *ngIf="titleModal" class="modal-title black-text-xp" [innerHTML]="titleModal | htmlParse"></h2>
        <h4
            *ngIf="description"
            class="modal-description"
            [innerHTML]="description | htmlParse"
        ></h4>
        <button *ngIf='uri !== "/formulae"' class="modal-button black-bg-xp" [attr.data-benefit]="transformText(titleModal)" (click)="scrollToSection('quote-form')" [innerHTML]="(buttonText ? buttonText : 'quero uma cotação') | htmlParse"></button>
        <h4 *ngIf='uri === "/formulae"' class="modal-description black-text-xp" [attr.data-benefit]="transformText(titleModal)" [innerHTML]="(buttonText ? buttonText : 'FAÇA JÁ SUA COTAÇÃO!') | htmlParse"></h4>
        <p class="modal-info black-text-xp" [innerHTML]="legalText | htmlParse"></p>
    </div>
</div>

import { ActionReducerMap } from '@ngrx/store'
import { landingpageReducer } from './landingpage/landingpage.reducer'
import { loadingReducer } from './loading/loading.reducer'
import { userReducer } from './user/user.reducer'
import {globalsReducer} from "./globals/globals.reducer";

export const stateReducers: ActionReducerMap<any> = {
    loading: loadingReducer,
    user: userReducer,
    landingpage: landingpageReducer,
    globals: globalsReducer
}

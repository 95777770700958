import { Component, OnInit, Input } from '@angular/core'

@Component({
	selector: 'app-tab',
	templateUrl: './tab.component.html',
	styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
	@Input('tabTitle') title: string | ''
	@Input() active = false
	@Input() background: string | '#fff'

	constructor() {}

	ngOnInit(): void {}
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-modal-registration',
	templateUrl: './modal-registration.component.html',
	styleUrls: ['./modal-registration.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModalRegistrationComponent implements OnInit {
	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}
	close() {
		this.activeModal.close()
	}
}

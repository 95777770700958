import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { AppState } from 'src/app/state/app-state'
import { Store } from '@ngrx/store'

@Component({
	selector: 'app-modal-benefit',
	templateUrl: './modal-benefit.component.html',
	styleUrls: ['./modal-benefit.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ModalBenefitComponent implements OnInit {
	@Input() figure?: string = ''
	@Input() alt_text?: string = ''
	@Input() titleModal?: string = ''
	@Input() description?: string = ''
	@Input() legalText: string = ''
	@Input() buttonText: string = ''

    uri: any
	constructor(public activeModal: NgbActiveModal, public store: Store<AppState>) {}

	ngOnInit(): void {
	    this.uri = window.location.pathname
    }
	close() {
		this.activeModal.close()
	}
    // @ts-ignore
    transformText(value: any) {
        if(typeof(value) === 'string') {
            const parser = new DOMParser();
            const parsedHTML = parser.parseFromString(value, 'text/html');
            const decodedText = parsedHTML.body.textContent;
            return decodedText
        }
    }
	scrollToSection(id: string) {
		this.close()
		setTimeout(function () {
			const el = document.getElementById(id) || null
			if (el) {
				window.scrollTo({
					top: window.scrollY + el.getBoundingClientRect().top - 100,
					behavior: 'smooth',
				})
			}
		}, 300)
	}
}

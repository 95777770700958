import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { AddressService } from '../../services/address/address.service'
import { FollowizeService } from '../../services/followize/followize.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { HelpersService } from '../../services/helpers/helpers.service'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { ModalTooltipComponent } from '../../components/modals/modal-tooltip/modal-tooltip.component'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { LandPagesService } from 'src/app/services/landpages/landpages.service'
import { ActivatedRoute, Router } from '@angular/router'
import { share } from 'rxjs/operators';
import { ModalModelComponent } from 'src/app/components/modals/modal-model/modal-model.component'
import { VehicleService } from 'src/app/services/vehicle/vehicle.service'
import { DomSanitizer } from '@angular/platform-browser'
import { Meta, Title } from '@angular/platform-browser'
import { CanonicalService } from '../../services/canonical/canonical.service'
import { ModalClearFormComponent } from 'src/app/components/modals/modal-clear-form/modal-clear-form.component'
import { ModalProfessionalComponent } from 'src/app/components/modals/modal-professional/modal-professional.component'
import { ModalRegistrationComponent } from 'src/app/components/modals/modal-registration/modal-registration.component'
import { environment } from 'src/environments/environment'
import {ControlSelectOptions} from "../../models/control-select-options";
import {ToastrService} from "ngx-toastr";
import {professions as mockProfessions} from "../../mocks/professions";
import * as moment from 'moment'

export interface moc {
    value: string
    label: string
}

@Component({
    selector: 'app-produtos-insurance',
    templateUrl: './produtos-insurance.component.html',
    styleUrls: ['./produtos-insurance.component.scss'],
})
export class ProdutosInsuranceComponent implements OnInit {
    activeItems: boolean = false;
    slideConfig = {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        arrows: true,
        infinite: false,
        variableWidth: false,
        // centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // variableWidth: false,
                    // infinite: true,
                }
            },
        ]
    }
    form: FormGroup
    hubUtm = ''
    pathParams: string = ''
    menuActive = false
    disabled = true
    step = 1
    formFeedback = {
        title_form: '',
        title: '',
        subtitle: '',
    }
    protectOptions: any[] = []

    data: any

    public time: any = 0
    modelSelected = false
    fullRequest = false
    modelos: Array<moc> = []
    years: any[] = []
    types: any[] = [
        {
            value: 'Automóvel',
            label: 'Carro',
        },
        {
            value: 'Moto',
            label: 'Moto',
        },
        {
            value: 'Caminhão',
            label: 'Caminhão',
        },
    ]
    nationalities: any[] = [
        {
            value: 'Nacional',
            label: 'Nacional',
        },
        {
            value: 'Importado',
            label: 'Importado',
        },
    ]

    showLoading = false
    salaryRange: any[] = [
        {
            value: 'R$ 0,00',
            label: 'R$ 0,00',
        },
        {
            value: 'de R$ 0,01 até R$ 1.500,00',
            label: 'de R$ 0,01 até R$ 1.500,00',
        },
        {
            value: 'de R$ 1.500,01 até R$ 3.000,00',
            label: 'de R$ 1.500,01 até R$ 3.000,00',
        },
        {
            value: 'de R$ 3.000,01 até R$ 6.000,00',
            label: 'de R$ 3.000,01 até R$ 6.000,00',
        },
        {
            value: 'de R$ 6.000,01 até R$ 10.000,00',
            label: 'de R$ 6.000,01 até R$ 10.000,00',
        },
        {
            value: 'acima de R$ 10.000,00',
            label: 'acima de R$ 10.000,00',
        },
    ]

    professions: any[] = []
    professionSelected = false
    allProfessions: any[] = mockProfessions


    messageFeedback = ''
    isScrolled: boolean = false
    isMobile: boolean = false
    splitPoint = ''
    typeForm = ''
    partner = this.router.snapshot.paramMap.get('parceiro')
    slug = this.router.snapshot.paramMap.get('produto')

    disabledAddress = false
    activeSiteSection = false

    loadingDataZipCode: boolean = false
    _reload: boolean = true
    ufOptions: any[] = []
    cityOptions: ControlSelectOptions[] = []
    renovacaoData = [
        {
            text:"<p><span style=\"color:#003781\"><strong>Proteja sua casa ou apartamento</strong></span></p>",
            sub_text:"<p><span style=\"color:#003781\"><strong>Peça para o seu Corretor ao renovar o Seguro auto!</strong></span></p>",
            description:"<p><span style='color:#003781'>A partir de </span><span style='color:#f62459'><strong>R$15,00 </strong></span><span style='color:#003781'><strong>por mês!</strong><br />Você pode pagar em até<strong> 10x sem juros no débito em conta</strong>, e proteger seu lar, seja alugado ou próprio.</span></p>",
            sub_description:"<p><span style=\"color:#003781\"><strong>Segurado Allianz Auto tem condições especiais no Seguro Allianz Residencial</strong></span></p>",
            text_legal:"",
            image:"https://nyc3.digitaloceanspaces.com/valide-dev/CMS-cote-allianz/EsquerdoBanner.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=XX2M4JTBQV3AFFHU3YDY%2F20230914%2Fnyc3%2Fs3%2Faws4_request&X-Amz-Date=20230914T171949Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=8d8dde4a89cd0e9ad3a8027f3f7d3d60fea82ab9ac4d751f99591960da441c87",
            button:{
               background:"#003781",
               background_hover:"#122b54",
               text:"VEM SABER MAIS",
               text_color:"#ffffff",
               text_color_hover:"#ffffff",
               link_url:"https://cote-allianz-integrada.valide.vc/allianz/residencia",
               link_external:true
            }
        },
        {
            text:"<p><span style=\"color:#003781\"><strong>Proteja a sua família</strong></span></p>",
            sub_text:"<p><span style=\"color:#003781\"><strong>Peça para o seu Corretor ao renovar o Seguro auto!</strong></span></p>",
            description:"<p><span style='color:#f62459'><strong>Seguro de vida </strong></span><span style='color:#003781'>a partir de </span><span style='color:#f62459'><strong>R$19,90 </strong></span><span style='color:#003781'><strong>por mês</strong><br />No <strong>débito automático </strong>ou em até <strong>12x sem juros no boleto</strong></span></p>",
            sub_description:"",
            text_legal:"",
            image:"https://nyc3.digitaloceanspaces.com/valide-dev/CMS-cote-allianz/DireitoBanner.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=XX2M4JTBQV3AFFHU3YDY%2F20230914%2Fnyc3%2Fs3%2Faws4_request&X-Amz-Date=20230914T172253Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=7f71ec81ae7a23fa78d13c112d00a144dab4d295b5ef5dffc4f3f9c72506af1c",
            button:{
               background:"#003781",
               background_hover:"#122b54",
               text:"VEM SABER MAIS",
               text_color:"#ffffff",
               text_color_hover:"#ffffff",
               link_url:"https://cote-allianz-integrada.valide.vc/allianz/residencia",
               link_external:true
            }
        },
    ]
    activeFragment = this.router.fragment.pipe(share());

    constructor(
        private canonicalService: CanonicalService,
        private title: Title,
        private meta: Meta,
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private addressService: AddressService,
        private followizeService: FollowizeService,
        private modal: NgbModal,
        public helpers: HelpersService,
        private landpageService: LandPagesService,
        public router: ActivatedRoute,
        public routeAction: Router,
        private veihcleService: VehicleService,
        private toast: ToastrService,
    ) {
        const parceiro = this.router.snapshot.routeConfig?.path || ''
        const arrarProducts = ['vida', 'auto', 'residencia', 'empresarial']
        const rappi = ['residencia-rappiprime', 'vida-rappiprime']

        if (arrarProducts.includes(parceiro) && !this.partner && !this.slug) {
            this.partner = 'allianz'
            this.slug = this.router.snapshot.routeConfig?.path || ''
        } else if (rappi.includes(parceiro) && !this.partner && !this.slug) {
            this.partner = 'rappi'
            this.slug = this.router.snapshot.routeConfig?.path === 'residencia-rappiprime' ? 'residencia' : 'vida'
        }
        if (this.slug === 'residencia' || this.slug === 'residencia-rappiprime') {
            this.title.setTitle("Cotação Seguro Residencial | Faça a sua | Allianz");
            this.meta.updateTag({ name: 'description', content: 'Seguro Residencial da Allianz. Serviços de Assistência 24h para a sua casa. Faça uma cotação e proteja-se!' });
            this.canonicalService.setCanonicalURL('https://www.allianz.com.br/seguros/seu-patrimonio/residencia.html');

            if (this.partner === 'renovacao') {
                    this.form = this.formBuilder.group({
                        safe: ['residencia'],
                        hubUtmz: null,
                        api_key: null,
                        team_key: null,
                        name: [null, [Validators.required]],
                        registrationNumber: [
                            null,
                            [Validators.required, GenericValidatorService.isValidCpf],
                        ],
                        email: [null, [Validators.required, Validators.email]],
                        phone: [
                            null,
                            [Validators.required, GenericValidatorService.validatePhone],
                        ],
                        consent: [false, [Validators.requiredTrue]],
                        safeType: ['Renovação de Seguro'],
                })
            }

            else {

            this.form = this.formBuilder.group({
                safe: ['residencia'],
                hubUtmz: null,
                api_key: null,
                team_key: null,
                name: [null, [Validators.required]],
                registrationNumber: [
                    null,
                    [Validators.required, GenericValidatorService.isValidCpf],
                ],
                email: [null, [Validators.required, Validators.email]],
                confirm_email: [null, [Validators.required, Validators.email]],
                phone: [
                    null,
                    [Validators.required, GenericValidatorService.validatePhone],
                ],
                city: [null, [Validators.required]],
                state: [null, [Validators.required]],
                neighborhood: [null],
                zipCode: [
                    null,
                    [Validators.required, GenericValidatorService.validateCep],
                ],
                // message: [null, [Validators.required]],
                safeType: [null, [Validators.required]],
                interests_1: [null],
                interests_2: [null],
                consent: [false, [Validators.requiredTrue]],
                interest1Title: [null, [Validators.required]],
                interest2Title: [null]
            })
        }
        } else if (this.slug === 'auto') {
            this.title.setTitle("Cotação Seguro Auto | Faça a sua | Allianz");
            this.meta.updateTag({ name: 'description', content: 'Seguro Auto da Allianz em até 10x sem juros no cartão de crédito. Cobertura contra roubo, furto, colisão e muito mais. Faça uma cotação e proteja-se!' });
            this.canonicalService.setCanonicalURL('https://www.allianz.com.br/seguros/seu-patrimonio/carro.html');

            if (this.partner === 'renovacao') {
                this.form = this.formBuilder.group({
                    safe: ['auto'],
                    hubUtmz: null,
                    api_key: null,
                    team_key: null,
                    name: [null, [Validators.required]],
                    registrationNumber: [
                        null,
                        [Validators.required, GenericValidatorService.isValidCpf],
                    ],
                    email: [null, [Validators.required, Validators.email]],
                    phone: [
                        null,
                        [Validators.required, GenericValidatorService.validatePhone],
                    ],
                    safeType: ['Renovação de Seguro'],
                    consent: [false, [Validators.requiredTrue]],
                })
            } else if (this.partner === 'renovar') {
                this.form = this.formBuilder.group({
                    safe: ['auto'],
                    hubUtmz: null,
                    api_key: null,
                    team_key: null,
                    name: [null, [Validators.required]],
                    registrationNumber: [
                        null,
                        [Validators.required, GenericValidatorService.isValidCpf],
                    ],
                    email: [null, [Validators.required, Validators.email]],
                    phone: [
                        null,
                        [Validators.required, GenericValidatorService.validatePhone],
                    ],
                    safeType: ['Renovação de Seguro'],
                    consent: [false, [Validators.requiredTrue]],
                })
            } 
            else if (this.partner === 'xp-investimentos' || this.partner === 'xp-colaboradores') {
                this.form = this.formBuilder.group({
                    safe: ['auto'],
                    hubUtmz: null,
                    api_key: null,
                    team_key: null,
                    name: [null, [Validators.required]],
                    registrationNumber: [
                        null,
                        [Validators.required, GenericValidatorService.isValidCpf],
                    ],
                    email: [null, [Validators.required, Validators.email]],
                    confirm_email: [null, [Validators.required, Validators.email]],
                    phone: [
                        null,
                        [Validators.required, GenericValidatorService.validatePhone],
                    ],
                    city: [null, [Validators.required]],
                    state: [null, [Validators.required]],
                    zipCode: [
                        null,
                        [Validators.required, GenericValidatorService.validateCep],
                    ],
                    interest5Title: [null],
                    safeType: [null, [Validators.required]],
                    consent: [false, [Validators.requiredTrue]],
                })
            }
            else {
                this.form = this.formBuilder.group({
                    safe: ['auto'],
                    hubUtmz: null,
                    api_key: null,
                    team_key: null,
                    name: [null, [Validators.required]],
                    registrationNumber: [
                        null,
                        [Validators.required, GenericValidatorService.isValidCpf],
                    ],
                    email: [null, [Validators.required, Validators.email]],
                    confirm_email: [null, [Validators.required, Validators.email]],
                    phone: [
                        null,
                        [Validators.required, GenericValidatorService.validatePhone],
                    ],
                    city: [null, [Validators.required]],
                    state: [null, [Validators.required]],
                    neighborhood: [null],
                    zipCode: [
                        null,
                        [Validators.required, GenericValidatorService.validateCep],
                    ],
                    message: [null],
                    safeType: [null, [Validators.required]],
                    changeItem: [null],
                    interest6Title: [null, [Validators.required]],
                    interest5Title: [null],
                    consent: [false, [Validators.requiredTrue]],
                    interest1Title: [null, [Validators.required]],
                    nationality: [null, [Validators.required]],
                    interest3Title: [null, [Validators.required]],
                    CarApp: null,
                    interest2Title: [null]
                })
            }
        } else if (this.slug === 'vida' || this.slug === 'vida-rappiprime') {
            this.form = this.formBuilder.group({
                safe: ['vida'],
                hubUtmz: null,
                api_key: null,
                team_key: null,
                name: [null, [Validators.required]],
                registrationNumber: [
                    null,
                    [Validators.required, GenericValidatorService.isValidCpf],
                ],
                profession: [null],
                salaryRange: [null],
                email: [null, [Validators.required, Validators.email]],
                confirm_email: [null, [Validators.required, Validators.email]],
                phone: [
                    null,
                    [Validators.required, GenericValidatorService.validatePhone],
                ],
                city: [null, [Validators.required]],
                state: [null, [Validators.required]],
                neighborhood: [null],
                zipCode: [
                    null,
                    [Validators.required, GenericValidatorService.validateCep],
                ],
                message: [null],
                safeType: [null, [Validators.required]],
                interests_1: [null],
                interests_2: [null],
                consent: [false, [Validators.requiredTrue]],
                interest2Title: [null]
            })
        } else if (this.slug === 'empresarial') {
            this.title.setTitle("Cotação Seguro Empresarial | Até 10x no débito ou boleto");
            this.meta.updateTag({ name: 'description', content: 'Seguro Empresarial da Allianz. Serviços de Assistência 24h para o seu negócio. Pague em até 10x no débito em conta ou boleto. Faça uma cotação!' });
            this.canonicalService.setCanonicalURL('https://www.allianz.com.br/seguros/seu-patrimonio/residencia.html');

            this.form = this.formBuilder.group({
                safe: ['empresarial'],
                hubUtmz: null,
                api_key: null,
                team_key: null,
                name: [null, [Validators.required]],
                companyName: [null, [Validators.required]],
                registrationNumberCFPorCNPJ: [
                    null,
                    [Validators.required, GenericValidatorService.isValidCPForCNPJ],
                ],
                email: [null, [Validators.required, Validators.email]],
                confirm_email: [null, [Validators.required, Validators.email]],
                phone: [
                    null,
                    [Validators.required, GenericValidatorService.validatePhone],
                ],
                city: [null, [Validators.required]],
                state: [null, [Validators.required]],
                neighborhood: [null],
                zipCode: [
                    null,
                    [Validators.required, GenericValidatorService.validateCep],
                ],
                consent: [false, [Validators.requiredTrue]],
                safeType: [null, [Validators.required]],
                multipleCheckbox:  [null, [Validators.required]],
            })
        }

        this.watchChangesForm()
    }

    ngOnInit(): void {
        this.splitPoint = environment.splitPoint

        if (window.location.href.split('?').length > 1) {
            this.pathParams = '?'+window.location.href.split('?')[1]
            this.hubUtm = this.helpers.formatUtmz(window.location.href)
            this.form.controls['hubUtmz'].setValue(this.hubUtm)
        }

        const date = new Date()
        const dateToday =  moment.utc(date).local().format('DD/MM/YYYY')

        if (dateToday === '25/12/2023') {
            this.messageFeedback = "Agradecemos o seu interesse! <br> Não estamos atendendo hoje mas voltaremos no dia 26/12. Ah, pode ficar tranquilo! Salvamos os seus dados e em breve vamos falar com você 😊"
        } else if (dateToday === '01/01/2023') {
            this.messageFeedback = "Agradecemos o seu interesse! <br> Não estamos atendendo hoje mas voltaremos no dia 02/12. Ah, pode ficar tranquilo! Salvamos os seus dados e em breve vamos falar com você! 😊"
        } else if (dateToday === '22/12/2023' || dateToday === '29/12/2023') {
            this.messageFeedback = "Agradecemos o seu interesse! <br> Nos dias 22 e 29/12, atenderemos em horário especial, de 09h às 12h. Ah, pode ficar tranquilo! Salvamos os seus dados e em breve vamos falar com você! 😊"
        }

        this.getLPData()
        this.isMobile = screen.width < 576
        setTimeout(() => {
            const header = document.getElementById("anchor-mobile");
            // @ts-ignore
            if(header) {
                const sticky = header.offsetTop;
                window.addEventListener('scroll', this.scrollMenu.bind(this, header, sticky))
            }
        }, 2000)

        if(this.partner === 'xp-investimentos' || this.partner === 'xp-colaboradores') {
            const content = document.querySelector('.home');
            const header = document.querySelector('header');
            const footer = document.querySelector('footer');

            content?.classList.add('xp-content')
            header?.classList.add('xp-content')
            footer?.classList.add('xp-content')
        }
    }
    showItems(): void {
        if(!this.activeItems) {
            this.activeItems = true
        } else {
            this.activeItems = false
        }
    }
    closeItems(): void {
        this.activeItems = false
    }
    scrollMenu(this: any, header: any, sticky: any): void {
        if (this.isMobile) {
            // @ts-ignore
            if (window.pageYOffset + 41 > sticky) {
                // @ts-ignore
                header.classList.add("sticky");
            } else {
                // @ts-ignore
                header.classList.remove("sticky");
            }
        } else {
            const scrollTop = window.scrollY || document.documentElement.scrollTop
            this.isScrolled = scrollTop > 780
        }
    }
    async getLPData() {
        const payload = {
            partner: this.partner,
            slug: this.slug,
            uuid: this.router.snapshot.routeConfig?.data?.title === 'Seguro Allianz - Produto Preview! Faça sua cotação' ? this.router.snapshot?.paramMap?.get('uuid') : ''
        }
        const self = this
        self.landpageService.getLandingPagesData(payload, this.router.snapshot.routeConfig?.data?.title === 'Seguro Allianz - Produto Preview! Faça sua cotação' ? `${payload.partner}/${payload.slug}/${payload.uuid}/preview` : `${payload.partner}/${payload.slug}`, {
            fnSuccess(data?: any, data1?: any) {
                if (data1.type_form) {
                    self.typeForm = data1.type_form
                }

                if (data) {
                    data.forEach((handle: any) => {
                        if (handle.handle === 'form_section' ) {
                            let count = 1
                            if (handle.data.form.sucess_message || handle.data.form.sucess_title) {
                                self.formFeedback.title = handle.data.form.sucess_message
                                self.formFeedback.title_form = handle.data.form.sucess_title
                            }
                            handle.data.form.steps.forEach((e:any) => {
                                e.order = count++
                            }) 

                            let valuesCustom = handle.data.form.steps['1']?.inputs.find((item: any) => item.component == 'multipleCheckbox')

                            if(valuesCustom) {
                                self.protectOptions = valuesCustom.value.split('|').map((u: any) => {
                                    const container = {
                                        value: '',
                                        label: ''
                                    };

                                    container.value = u.trim();
                                    container.label = u.trim();

                                    return container;

                                })
                            }
                        }
                    })
                    if (self.slug !== 'residencia' && self.slug !== 'empresarial') {
                        self.data = data
                        data.forEach((handle: any) => {
                            if (handle.handle === 'card_section') {
                                if (handle.data.cards.filter((card: any) => card.type === 'box').length > 0) {
                                    handle.type = 'box'
                                } else {
                                    handle.type = 'circle'
                                }
                            }
                        })
                    } else {
                        self.data = self.getBlocks(data)
                    }
                } else {
                    self.routeAction.navigate(['/not-found/404/*'])
                }
            },
            fnError(error) {
                self.routeAction.navigate(['/not-found/404/*'])
            },
            fnFinalized() {
                self.getStates()
            },
        })
    }
    getBlocks(data: any[]): any[] {
        let blocksData: any
        data.forEach((element: any) => {
            if (element.handle === 'block_section') {
                blocksData = element.data
            }
        })
        let blocks = blocksData.blocks
        // blocks.push({ htmlContent: true })
        const perChunk = blocksData.block_per_page <= 0 ? 1 : blocksData.block_per_page  // items per chunk

        const result = blocks.reduce(
            (resultArray: any, item: any, index: any) => {
                const chunkIndex = Math.floor(index / perChunk)

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [] // start a new chunk
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            },
            []
        )
        data.forEach((element: any) => {
            if (element.handle === 'block_section') {
                element.data.blocks = result
            }
        })
        data.forEach((handle) => {
            if (handle.handle === 'card_section') {
                if (handle.data.cards.filter((card: any) => card.type === 'box').length > 0) {
                    handle.type = 'box'
                    handle.data.product = 'residencia'
                    const result = handle.data.cards.reduce(
                        (resultArray: any, item: any, index: any) => {
                            const chunkIndex = Math.floor(index / 3)

                            if (!resultArray[chunkIndex]) {
                                resultArray[chunkIndex] = [] // start a new chunk
                            }

                            resultArray[chunkIndex].push(item)

                            return resultArray
                        },
                        []
                    )
                    handle.data.cards = result
                } else {
                    handle.type = 'circle'
                }
            }
        })
        return data
    }
    changeEmail(email: string) {
        var re = /\S+@\S+\.\S+/
        if (email) {
            return re.test(email)
        } else {
            return true
        }
    }
    isFieldValid(field: string): boolean {
        const form = this.form
        // @ts-ignore
        return form.get(field).valid
    }
    watchChangesForm(): void {
        if (this.slug === 'residencia') {
            const self = this



            self.form.valueChanges.subscribe((data) => {
                if (self.partner === 'renovacao') {
                    if (
                        self.step === 1 &&
                        self.isFieldValid('name') &&
                        self.isFieldValid('registrationNumber') &&
                        self.isFieldValid('email') &&
                        self.isFieldValid('phone') &&
                        self.isFieldValid('consent')
                        // && self.isFieldValid('message')
                    ) {
                        self.disabled = false
                    } else {
                        self.disabled = true
                    }
                } else {
                    if (
                        self.step === 1 &&
                        self.isFieldValid('name') &&
                        self.isFieldValid('registrationNumber') &&
                        self.isFieldValid('email') &&
                        self.isFieldValid('confirm_email') &&
                        self.form.get('email')?.value ===
                        self.form.get('confirm_email')?.value &&
                        self.isFieldValid('phone')
                        // && self.isFieldValid('message')
                    ) {
                        self.disabled = false
                    } else if (
                        self.step === 2 &&
                        self.isFieldValid('zipCode') &&
                        self.isFieldValid('city') &&
                        self.isFieldValid('state') &&
                        self.isFieldValid('safeType') &&
                        self.isFieldValid('consent') &&
                        self.isFieldValid('interest1Title')
                    ) {
                        self.disabled = false
                    } else {
                        self.disabled = true
                    }
                }
            })
        } else if (this.slug === 'auto') {
            const self = this

            self.form.valueChanges.subscribe((data) => {
                if (self.partner === 'renovacao') {
                    if (
                        self.step === 1 &&
                        self.isFieldValid('name') &&
                        self.isFieldValid('registrationNumber') &&
                        self.isFieldValid('email') &&
                        self.isFieldValid('phone') &&
                        self.isFieldValid('consent')
                    ) {
                        self.disabled = false
                    } else {
                        self.disabled = true
                    }
                } else if (self.partner === 'renovar') {
                    if (
                        self.step === 1 &&
                        self.isFieldValid('name') &&
                        self.isFieldValid('registrationNumber') &&
                        self.isFieldValid('email') &&
                        self.isFieldValid('phone') &&
                        self.isFieldValid('consent')
                    ) {
                        self.disabled = false
                    } else {
                        self.disabled = true
                    }
                } else if (self.partner === 'formcpf') {
                    if (
                        self.step === 1 &&
                        self.isFieldValid('name') &&
                        self.isFieldValid('email') &&
                        self.isFieldValid('confirm_email') &&
                        self.form.get('email')?.value ===
                        self.form.get('confirm_email')?.value &&
                        self.isFieldValid('phone') &&
                        self.isFieldValid('message')
                    ) {
                        self.disabled = false
                    } else if (
                        self.step === 2 &&
                        self.isFieldValid('zipCode') &&
                        self.isFieldValid('city') &&
                        self.isFieldValid('state')
                    ) {
                        self.disabled = false
                    } else if (
                        self.step === 3 &&
                        self.isFieldValid('interest1Title') &&
                        self.isFieldValid('nationality') &&
                        self.isFieldValid('interest6Title') &&
                        self.isFieldValid('interest3Title') &&
                        self.isFieldValid('safeType') &&
                        self.isFieldValid('consent') &&
                        self.form.get('interest1Title')?.value !== 'Automóvel' &&
                        // !self.isFieldValid('changeItem') &&
                        !self.form.get('CarApp')?.value &&
                        self.modelSelected
                    ) {
                        if (
                            self.form.get('interest6Title')?.value === '0' &&
                            !self.form.get('interest5Title')?.value
                        ) {
                            self.disabled = false
                        } else if (self.form.get('interest5Title')?.value) {
                            self.disabled = false
                        } else {
                            self.disabled = true
                        }
                    } else if (
                        self.step === 3 &&
                        self.isFieldValid('interest1Title') &&
                        self.isFieldValid('nationality') &&
                        self.isFieldValid('interest6Title') &&
                        self.isFieldValid('interest3Title') &&
                        self.isFieldValid('safeType') &&
                        self.isFieldValid('consent') &&
                        self.form.get('interest1Title')?.value === 'Automóvel' &&
                        // self.isFieldValid('changeItem') &&
                        !self.form.get('CarApp')?.value &&
                        self.modelSelected
                    ) {
                        if (
                            self.form.get('interest6Title')?.value === '0' &&
                            !self.form.get('interest5Title')?.value && self.form.get('changeItem')?.value
                        ) {
                            self.disabled = false
                        } else if (self.form.get('interest5Title')?.value && self.form.get('changeItem')?.value) {
                            self.disabled = false
                        } else {
                            self.disabled = true
                        }
                    } else {
                        self.disabled = true
                    }
                } 
                else if (self.partner === 'xp-investimentos' || this.partner === 'xp-colaboradores') {
                    if (
                        self.step === 1 &&
                        self.isFieldValid('name') &&
                        self.isFieldValid('registrationNumber') &&
                        self.isFieldValid('email') &&
                        self.isFieldValid('confirm_email') &&
                        self.form.get('email')?.value ===
                        self.form.get('confirm_email')?.value &&
                        self.isFieldValid('phone')
                    ) {
                        self.disabled = false
                    } else if (
                        self.step === 2 &&
                        self.isFieldValid('zipCode') &&
                        self.isFieldValid('city') &&
                        self.isFieldValid('state') &&
                        self.isFieldValid('safeType') &&
                        self.isFieldValid('consent')
                    ) {

                        if (
                            self.form.get('interest6Title')?.value === '0' &&
                            !self.form.get('interest5Title')?.value
                        ) {
                            self.disabled = false
                        } else if (self.form.get('interest5Title')?.value) {
                            self.disabled = false
                        } else {
                            self.disabled = true
                        }
                        // self.disabled = false
                    } else {
                        self.disabled = true
                    }
                }
                else {
                    if (
                        self.step === 1 &&
                        self.isFieldValid('name') &&
                        self.isFieldValid('registrationNumber') &&
                        self.isFieldValid('email') &&
                        self.isFieldValid('confirm_email') &&
                        self.form.get('email')?.value ===
                        self.form.get('confirm_email')?.value &&
                        self.isFieldValid('phone') &&
                        self.isFieldValid('message')
                    ) {
                        self.disabled = false
                    } else if (
                        self.step === 2 &&
                        self.isFieldValid('zipCode') &&
                        self.isFieldValid('city') &&
                        self.isFieldValid('state')
                    ) {
                        self.disabled = false
                    } else if (
                        self.step === 3 &&
                        self.isFieldValid('interest1Title') &&
                        self.isFieldValid('nationality') &&
                        self.isFieldValid('interest6Title') &&
                        self.isFieldValid('interest3Title') &&
                        self.isFieldValid('safeType') &&
                        self.isFieldValid('consent') &&
                        self.form.get('interest1Title')?.value !== 'Automóvel' &&
                        !self.form.get('CarApp')?.value &&
                        self.modelSelected
                    ) {
                        if (
                            self.form.get('interest6Title')?.value === '0' &&
                            !self.form.get('interest5Title')?.value
                        ) {
                            self.disabled = false
                        } else if (self.form.get('interest5Title')?.value) {
                            self.disabled = false
                        } else {
                            self.disabled = true
                        }
                    } else if (
                        self.step === 3 &&
                        self.isFieldValid('interest1Title') &&
                        self.isFieldValid('nationality') &&
                        self.isFieldValid('interest6Title') &&
                        self.isFieldValid('interest3Title') &&
                        self.isFieldValid('safeType') &&
                        self.isFieldValid('consent') &&
                        self.form.get('interest1Title')?.value === 'Automóvel' &&
                        // self.isFieldValid('changeItem') &&
                        !self.form.get('CarApp')?.value &&
                        self.modelSelected
                    ) {
                        if (
                            self.form.get('interest6Title')?.value === '0' &&
                            !self.form.get('interest5Title')?.value && self.form.get('changeItem')?.value
                        ) {
                            self.disabled = false
                        } else if (self.form.get('interest5Title')?.value && self.form.get('changeItem')?.value) {
                            self.disabled = false
                        } else {
                            self.disabled = true
                        }
                    } else {
                        self.disabled = true
                    }
                }
            })
        } else if (this.slug === 'vida') {
            const self = this

            self.form.valueChanges.subscribe((data) => {
                if (
                    self.step === 1 &&
                    self.isFieldValid('name') &&
                    self.isFieldValid('registrationNumber') &&
                    self.isFieldValid('email') &&
                    self.isFieldValid('confirm_email') &&
                    self.form.get('email')?.value ===
                    self.form.get('confirm_email')?.value &&
                    self.isFieldValid('phone') &&
                    self.isFieldValid('message')
                ) {
                    self.disabled = false
                } else if (
                    self.step === 2 &&
                    self.isFieldValid('zipCode') &&
                    self.isFieldValid('city') &&
                    self.isFieldValid('state') &&
                    self.isFieldValid('safeType') &&
                    self.isFieldValid('consent')
                ) {
                    self.disabled = false
                } else {
                    self.disabled = true
                }
            })
        } else if (this.slug === 'empresarial') {
            const self = this

            self.form.valueChanges.subscribe((data) => {
                if (
                    self.step === 1 &&
                    self.isFieldValid('name') &&
                    self.isFieldValid('companyName') &&
                    self.isFieldValid('registrationNumberCFPorCNPJ') &&
                    self.isFieldValid('email') &&
                    self.isFieldValid('confirm_email') &&
                    self.form.get('email')?.value ===
                    self.form.get('confirm_email')?.value &&
                    self.isFieldValid('phone')
                ) {
                    self.disabled = false
                } else if (
                    self.step === 2 &&
                    self.isFieldValid('zipCode') &&
                    self.isFieldValid('city') &&
                    self.isFieldValid('state') &&
                    this.isFieldValid('safeType') &&
                    self.isFieldValid('consent') &&
                    self.isFieldValid('multipleCheckbox')
                ) {
                    self.disabled = false
                } else {
                    self.disabled = true
                }
            })
        }
    }
    goTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    backStep(): void {
        this.disabled = false
        this.step--
    }
    nextStep(text: string): void {
        if (text === 'PRÓXIMO') {
            if (this.step === 1) {
                window.dataLayer.push({
                    event: 'Click_step1',
                    eventCategory: 'lead',
                    eventAction: 'click',
                    eventLabel: '',
                })
            }

            if (this.slug === 'residencia') {

                this.step++
                if (this.partner === 'renovacao') {
                    if (this.step === 1) {
                        window.dataLayer.push({
                            event: 'preConversionHome',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                    }
                }
                else {
                    if (this.step === 2) {
                        window.dataLayer.push({
                            event: 'preConversionHome',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                    }
                }

                if (this.partner === 'renovacao') {
                    if (
                        this.step === 1 &&
                        this.isFieldValid('name') &&
                        this.isFieldValid('registrationNumber') &&
                        this.isFieldValid('email') &&
                        this.isFieldValid('phone') &&
                        this.isFieldValid('consent')
                    ) {
                        this.disabled = false
                    } else {
                        this.disabled = true
                    }
                }
                else {
                    if (
                        this.step === 1 &&
                        this.isFieldValid('name') &&
                        this.isFieldValid('registrationNumber') &&
                        this.isFieldValid('email') &&
                        this.isFieldValid('confirm_email') &&
                        this.isFieldValid('phone') &&
                        this.isFieldValid('message')
                    ) {
                        this.disabled = false
                    } else if (
                        this.step === 2 &&
                        this.isFieldValid('zipCode') &&
                        this.isFieldValid('city') &&
                        this.isFieldValid('state') &&
                        this.isFieldValid('safeType') &&
                        this.isFieldValid('consent') &&
                        this.isFieldValid('interest1Title')
                    ) {
                        this.disabled = false
                    } else {
                        this.disabled = true
                    }
                }
            } else if (this.slug === 'auto') {
                this.step++

                if (this.step === 2) {
                    window.dataLayer.push({
                        event: 'preConversionCar',
                        eventCategory: 'lead',
                        eventAction: 'send',
                        eventLabel: '',
                    })
                }

                if(this.partner === 'xp-investimentos' || this.partner === 'xp-colaboradores') {
                    if (
                        this.step === 1 &&
                        this.isFieldValid('name') &&
                        this.isFieldValid('registrationNumber') &&
                        this.isFieldValid('email') &&
                        this.isFieldValid('confirm_email') &&
                        this.isFieldValid('phone') &&
                        this.isFieldValid('message')
                    ) {
                        this.disabled = false
                    } else if (
                        this.step === 2 &&
                        this.isFieldValid('zipCode') &&
                        this.isFieldValid('city') &&
                        this.isFieldValid('state') &&
                        this.isFieldValid('safeType') &&
                        this.isFieldValid('consent') 
                    ) {
                        if (
                            !this.form.get('interest5Title')?.value
                        ) {
                            this.disabled = false
                        } else if (this.form.get('interest5Title')?.value) {
                            this.disabled = false
                        } else {
                            this.disabled = true
                        }

                        // this.disabled = false
                    } 
                    else {
                        this.disabled = true
                    }
                }
                
                else {
                    if (
                        this.step === 1 &&
                        this.isFieldValid('name') &&
                        this.isFieldValid('registrationNumber') &&
                        this.isFieldValid('email') &&
                        this.isFieldValid('confirm_email') &&
                        this.isFieldValid('phone') &&
                        this.isFieldValid('message')
                    ) {
                        this.disabled = false
                    } else if (
                        this.step === 2 &&
                        this.isFieldValid('zipCode') &&
                        this.isFieldValid('city') &&
                        this.isFieldValid('state')
                    ) {
                        this.disabled = false
                    } else if (
                        this.step === 3 &&
                        this.isFieldValid('interest1Title') &&
                        this.isFieldValid('nationality') &&
                        this.isFieldValid('interest6Title') &&
                        this.isFieldValid('interest3Title') &&
                        this.isFieldValid('safeType') &&
                        this.isFieldValid('consent') &&
                        this.modelSelected
                    ) {
                        if (
                            this.form.get('interest6Title')?.value === '0' &&
                            !this.form.get('interest5Title')?.value
                        ) {
                            this.disabled = false
                        } else if (this.form.get('interest5Title')?.value) {
                            this.disabled = false
                        } else {
                            this.disabled = true
                        }
                    }
                    else {
                        this.disabled = true
                    }
                }

            } else if (this.slug === 'vida') {
                this.step++

                window.dataLayer.push({
                    event: 'preConversionLive',
                    eventCategory: 'lead',
                    eventAction: 'send',
                    eventLabel: '',
                })

                if (
                    this.step === 1 &&
                    this.isFieldValid('name') &&
                    this.isFieldValid('registrationNumber') &&
                    this.isFieldValid('email') &&
                    this.isFieldValid('confirm_email') &&
                    this.isFieldValid('phone') &&
                    this.isFieldValid('message')
                ) {
                    this.disabled = false
                } else if (
                    this.step === 2 &&
                    this.isFieldValid('zipCode') &&
                    this.isFieldValid('city') &&
                    this.isFieldValid('state') &&
                    this.isFieldValid('safeType') &&
                    this.isFieldValid('consent')
                ) {
                    this.disabled = false
                } else {
                    this.disabled = true
                }
            } else if (this.slug === 'empresarial') {

                this.step++
                if (this.partner === 'renovacao') {
                    if (this.step === 1) {
                        window.dataLayer.push({
                            event: 'preConversionHome',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                    }
                }
                else {
                    if (this.step === 2) {
                        window.dataLayer.push({
                            event: 'preConversionHome',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                    }
                }

                if (this.partner === 'renovacao') {
                    if (
                        this.step === 1 &&
                        this.isFieldValid('name') &&
                        this.isFieldValid('companyName') &&
                        this.isFieldValid('registrationNumberCFPorCNPJ') &&
                        this.isFieldValid('email') &&
                        this.isFieldValid('phone') &&
                        this.isFieldValid('consent')
                    ) {
                        this.disabled = false
                    } else {
                        this.disabled = true
                    }
                }
                else {
                    if (
                        this.step === 1 &&
                        this.isFieldValid('name') &&
                        this.isFieldValid('companyName') &&
                        this.isFieldValid('registrationNumberCFPorCNPJ') &&
                        this.isFieldValid('email') &&
                        this.isFieldValid('confirm_email') &&
                        this.isFieldValid('phone')
                    ) {
                        this.disabled = false
                    } else if (
                        this.step === 2 &&
                        this.isFieldValid('zipCode') &&
                        this.isFieldValid('city') &&
                        this.isFieldValid('state') &&
                        this.isFieldValid('safeType') &&
                        this.isFieldValid('consent') &&
                        this.isFieldValid('multipleCheckbox')
                    ) {
                        this.disabled = false
                    } else {
                        this.disabled = true
                    }
                }
            }
        } else {
            this.onSubmit()
        }
    }
    openModal(): void {
        this.modal.open(ModalTooltipComponent, {
            modalDialogClass: 'modal-tooltip',
        })
    }
    onSubmit(): void {
        const self = this
        const data = self.form.value
        const form_section: Array<any> = self.data.filter((handle: any) => handle.handle == 'form_section')
        const slug = this.slug
        const partner = self.capitalizeFirstLetter(this.partner || '')
        const totalSteps = self.data.find((item:any) => item.handle === 'form_section').data.form.steps.length

        if (slug === 'residencia') {
            if (self.form.valid) {
                self.disabled = true
                self.store.dispatch(ShowLoading())
                const formData = new FormData()
                for (let key in data) {
                    if (key === 'consent') {
                        formData.append(key, 'on')
                    } else if (key === 'api_key') {
                        formData.append(key, form_section[0].api_key)
                    } else if (key === 'team_key') {
                        formData.append(key, form_section[0].team_key)
                    } else {
                        formData.append(key, data[key] ? data[key] : '')
                    }
                }

                if (this.typeForm) {
                    formData.append('conversionGoal', this.typeForm)
                }

                self.followizeService.sendFollowize('', formData, {
                    fnSuccess(data?: any) {
                        window.dataLayer.push({
                            event: 'sendlead',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                        if (partner.toLowerCase() === 'allianz') {
                            window.dataLayer.push({
                                event: 'sendLeadHome',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: '',
                            })
                        } else {
                            window.dataLayer.push({
                                event: 'sendLeadPartner',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: 'Home'+partner,
                            })
                        }
                        window.gtag('event', 'conversion', {
                            send_to: 'AW-1031001625/Cet3CP_01-EDEJmsz-sD',
                            event_callback: window.location,
                        })

                        self.fullRequest = true
                    },
                    fnError(error) {
                        self.formFeedback = {
                            title: 'Ops!',
                            subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
                            title_form: ''
                        }
                    },
                    fnFinalized() {
                        self.step = 2
                        self.form.reset()
                        self.store.dispatch(HiddenLoading())
                    },
                })
            } else {
                self.step++
                self.disabled = true
            }
        } else if (slug === 'auto') {
            if (self.form.valid && self.step === totalSteps) {
                self.disabled = true
                self.store.dispatch(ShowLoading())
                const formData = new FormData()

                for (let key in data) {
                    if (key === 'consent') {
                        formData.append(key, 'on')
                    } else if (key === 'api_key') {
                        formData.append(key, form_section[0].api_key)
                    } else if (key === 'team_key') {
                        formData.append(key, form_section[0].team_key)
                    } else {
                        formData.append(key, data[key] ? data[key] : '')
                    }
                }

                if (this.typeForm) {
                    formData.append('conversionGoal', this.typeForm)
                }

                self.followizeService.sendFollowize('', formData, {
                    fnSuccess(data?: any) {
                        window.dataLayer.push({
                            event: 'sendlead',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                        if (partner.toLowerCase() === 'allianz') {
                            window.dataLayer.push({
                                event: 'sendLeadCar',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: '',
                            })
                        } else {
                            window.dataLayer.push({
                                event: 'sendLeadPartner',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: 'Car'+partner,
                            })
                        }
                        window.gtag('event', 'conversion', {
                            send_to: 'AW-1031001625/En43CLyhiuEDEJmsz-sD',
                            event_callback: window.location,
                        })
                        self.fullRequest = true
                    },
                    fnError(error) {
                        self.formFeedback = {
                            title: 'Ops!',
                            subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
                            title_form: ''
                        }
                        self.fullRequest = true
                    },
                    fnFinalized() {
                        self.step = 3
                        self.form.reset()
                        self.store.dispatch(HiddenLoading())
                    },
                })
            } else {
                self.step++
                self.watchChangesForm()
            }
        } else if (slug === 'vida') {
            if (self.form.valid) {
                self.disabled = true
                self.store.dispatch(ShowLoading())
                const formData = new FormData()

                for (let key in data) {
                    if (key === 'interests_1' && data[key]) {
                        formData.append(
                            `interests[${key}]`,
                            'Tenho interesse em outros produtos da Allianz'
                        )
                    } else if (key === 'consent') {
                        formData.append(key, 'on')
                    } else if (key === 'api_key') {
                        formData.append(key, form_section[0].api_key)
                    } else if (key === 'team_key') {
                        formData.append(key, form_section[0].team_key)
                    } else {
                        formData.append(key, data[key] ? data[key] : '')
                    }
                }

                self.followizeService.sendFollowize('', formData, {
                    fnSuccess(data?: any) {
                        window.dataLayer.push({
                            event: 'sendlead',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                        if (partner.toLowerCase() === 'allianz') {
                            window.dataLayer.push({
                                event: 'sendLeadLife',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: '',
                            })
                        } else {
                            window.dataLayer.push({
                                event: 'sendLeadPartner',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: 'Life'+partner,
                            })
                        }

                        window.gtag('event', 'conversion', {
                            send_to: 'AW-1031001625/s4wDCK_p1-EDEJmsz-sD',
                            event_callback: window.location,
                        })

                        self.fullRequest = true
                    },
                    fnError(error) {
                        self.formFeedback = {
                            title: 'Ops!',
                            subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
                            title_form: ''
                        }
                    },
                    fnFinalized() {
                        self.step = 2
                        self.form.reset()
                        self.store.dispatch(HiddenLoading())
                    },
                })
            } else {
                self.step++
                self.disabled = true
            }
        } else if (slug === 'empresarial') {
            if (self.form.valid) {
                self.disabled = true
                self.store.dispatch(ShowLoading())
                const formData = new FormData()
                for (let key in data) {
                    if (key === 'consent') {
                        formData.append(key, 'on')
                    } else if (key === 'api_key') {
                        formData.append(key, form_section[0].api_key)
                    } else if (key === 'team_key') {
                        formData.append(key, form_section[0].team_key)
                    } else {
                        formData.append(key, data[key] ? data[key] : '')
                    }
                }

                if (this.typeForm) {
                    formData.append('conversionGoal', this.typeForm)
                }

                self.followizeService.sendFollowize('', formData, {
                    fnSuccess(data?: any) {
                        window.dataLayer.push({
                            event: 'sendlead',
                            eventCategory: 'lead',
                            eventAction: 'send',
                            eventLabel: '',
                        })
                        if (partner.toLowerCase() === 'allianz') {
                            window.dataLayer.push({
                                event: 'sendLeadHome',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: '',
                            })
                        } else {
                            window.dataLayer.push({
                                event: 'sendLeadPartner',
                                eventCategory: 'lead',
                                eventAction: 'send',
                                eventLabel: 'Home'+partner,
                            })
                        }
                        window.gtag('event', 'conversion', {
                            send_to: 'AW-1031001625/Cet3CP_01-EDEJmsz-sD',
                            event_callback: window.location,
                        })

                        self.fullRequest = true
                    },
                    fnError(error) {
                        self.formFeedback = {
                            title: 'Ops!',
                            subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
                            title_form: ''
                        }
                    },
                    fnFinalized() {
                        self.step = 2
                        self.form.reset()
                        self.store.dispatch(HiddenLoading())
                    },
                })
            } else {
                self.step++
                self.disabled = true
            }
        }
    }
    changeRadio(event: any): any {
        if (event) {
            this.form.get('name')?.markAsTouched()
            this.form.get('registrationNumber')?.markAsTouched()
            this.form.get('email')?.markAsTouched()
            this.form.get('confirm_email')?.markAsTouched()
            this.form.get('phone')?.markAsTouched()
        }
    }

    getYears() {
        const self = this
        const data = self.form.value
        self.years = []
        self.modelos = []
        this.form.get('interest6Title')?.setValue('')
        this.form.get('interest3Title')?.setValue('')
        this.showLoading = false
        self.disabled = true
        self.modelSelected = false
        if (
            this.form.get('interest1Title')?.value &&
            this.form.get('nationality')?.value
        ) {
            const payload = {
                type: this.form.get('interest1Title')?.value,
                origin: this.form.get('nationality')?.value,
            }
            self.veihcleService.getYears(payload, {
                fnSuccess(data?: any) {
                    if (data.response === 'success') {
                        data.years.forEach((item: number) => {
                            const obj = {
                                value: item,
                                label: item === 0 ? '0km' : item.toString(),
                            }
                            self.years.push(obj)
                        })
                    } else {
                        data.years.forEach((item: number) => {
                            const obj = {
                                value: item,
                                label: item === 0 ? '0km' : item.toString(),
                            }
                            self.years.push(obj)
                        })
                    }
                },
                fnError(error) {
                    self.formFeedback = {
                        title: 'Ops!',
                        subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
                        title_form: ''
                    }
                },
            })
        }
    }
    getModel(type: string) {
        const self = this
        self.modelos = []
        self.modelSelected = false
        self.disabled = true
        if (
            this.form.get('interest1Title')?.value &&
            this.form.get('nationality')?.value &&
            this.form.get('interest6Title')?.value
        ) {
            const payload = {
                type: this.form.get('interest1Title')?.value,
                origin: this.form.get('nationality')?.value,
                year: this.form.get('interest6Title')?.value,
                model: type,
                cepParam: this.form.get('zipCode')?.value
            }
            this.veihcleService.getModel(payload, {
                fnSuccess(data?: any) {
                    if (data.response === 'success') {
                        const noData = {
                            value: '00',
                            label: 'Modelo não encontrado ou sem cobertura…',
                        }
                        if (data.length === 0) {
                            self.modelos.push(noData)
                            self.form.get('interest3Title')?.setValue('')
                        } else {
                            data.forEach((item: any) => {
                                const obj = {
                                    value: item.model,
                                    label: `${item.model} - ${item.version}`,
                                }
                                self.modelos.push(obj)
                            })
                        }
                        self.showLoading = false
                    } else {
                        const noData = {
                            value: '00',
                            label: 'Modelo não encontrado ou sem cobertura…',
                        }
                        if (data.length === 0) {
                            self.modelos.push(noData)
                            self.form.get('interest3Title')?.setValue('')
                        } else {
                            data.forEach((item: any) => {
                                const obj = {
                                    value: item.model,
                                    label: `${item.model} - ${item.version}`,
                                }
                                self.modelos.push(obj)
                            })
                        }
                        self.showLoading = false
                    }
                },
                fnError(error) {
                    self.formFeedback = {
                        title: 'Ops!',
                        subtitle: `Ocorreu um erro inesperado. <br />Tente novamente!`,
                        title_form: ''
                    }
                    self.showLoading = false
                },
            })
        }
    }
    onSearch(event: any) {
        this.disabled = true
        this.showLoading = true
        this.modelSelected = false
        clearTimeout(this.time)
        if (event) {
            this.time = setTimeout(() => {
                this.getModel(event)
            }, 2400)
        }
    }
    onSelect(event: any) {
        if (event.value && event.value !== '') {
            this.modelSelected = true
        } else {
            this.modelSelected = false
        }
        this.form.get('interest3Title')?.setValue(event.value)
    }
    openModalModel(): void {
        this.modal.open(ModalModelComponent, {
            modalDialogClass: 'modal-tooltip',
        })
    }
    onSearchProfession(event: any) {
        this.disabled = true
        this.showLoading = true
        this.professionSelected = false
        clearTimeout(this.time)
        if (event) {
            this.time = setTimeout(() => {
                this.getProfession(event)
            }, 2400)
        } else {
            this.showLoading = false
            this.professions = []
        }
    }
    onSelectProfession(event: any) {
        if (event.value && event.value !== '') {
            this.professionSelected = true
        } else {
            this.professionSelected = false
        }
        this.form.get('profession')?.setValue(event.value)
    }
    getProfession(type: string) {
        const self = this
        self.professions = []
        self.professionSelected = false
        self.disabled = true
        const prof = this.allProfessions.filter((e) => e.value.includes(type))
        if (prof && prof.length > 0) {
            this.professions = prof
            this.showLoading = false
        } else {
            const naoEncontrado = {
                value: '00',
                label: 'Profissão não encontrada ou sem cobertura',
            }
            this.professions.push(naoEncontrado)
            this.form.get('profession')?.setValue('')
            clearTimeout(this.time)
            this.time = setTimeout(() => {
                this.professions = []
            }, 3000)
            this.showLoading = false
        }
    }

    clearForm(): void {

        const self = this
        self.step = 1
        this.form = this.formBuilder.group({
            name: [null, [Validators.required]],
            registrationNumber: [
                null,
                [Validators.required, GenericValidatorService.isValidCpf],
            ],
            email: [null, [Validators.required, Validators.email]],
            confirm_email: [null, [Validators.required, Validators.email]],
            phone: [
                null,
                [Validators.required, GenericValidatorService.validatePhone],
            ],
            city: [null, [Validators.required]],
            state: [null, [Validators.required]],
            neighborhood: [null],
            zipCode: [
                null,
                [Validators.required, GenericValidatorService.validateCep],
            ],
            message: [null],
            changeItem: [null],
            safeType: [null, [Validators.required]],
            interest6Title: [null, [Validators.required]],
            interest5Title: [null],
            consent: [false, [Validators.requiredTrue]],
            interest1Title: [null, [Validators.required]],
            nationality: [null, [Validators.required]],
            interest3Title: [null, [Validators.required]],
            CarApp: null,
            interest2Title: [null]
        })

        this.form.get('state')?.setValue('')
        this.form.get('city')?.setValue('')
        this.form.get('interest1Title')?.setValue('')
        this.form.get('nationality')?.setValue('')
        this.form.get('interest6Title')?.setValue('')

        setTimeout(() => {
            this.watchChangesForm()
        }, 500)


    }

    openModalClearForm(): void {
        this.clearForm()

        this.modal.open(ModalClearFormComponent, {
            modalDialogClass: 'modal-clear-form',
        })
    }

    openModalProfessions(): void {
        this.modal.open(ModalProfessionalComponent, {
            modalDialogClass: 'modal-tooltip',
        })
    }
    openModalRegistration(): void {
        this.modal.open(ModalRegistrationComponent, {
            modalDialogClass: 'modal-tooltip',
        })

        window.dataLayer.push({
            event: 'click_CPF',
            eventCategory: 'tooltip',
            eventAction: 'click',
            eventLabel: '',
        })
    }
    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    private reload() {
        setTimeout(() => (this._reload = false))
        setTimeout(() => (this._reload = true))
    }
    getAddressByZipCode(value: string): void {
        const self = this

        if (value.length === 8 && !self.loadingDataZipCode) {
            self.loadingDataZipCode = true
            value = value.replace('-', '')

            self.addressService.getAddressByZipCode(value, {
                fnSuccess(data?: any) {
                    self.form.controls['state']?.setValue(data.state)
                    self.form.controls['neighborhood']?.setValue(data.neighborhood)
                    self.getCities(data.city)
                },
                fnError(error) {
                    self.toast.error(error.error.detail, 'Opa!')
                },
                fnFinalized() {
                    self.loadingDataZipCode = false
                },
            })
        }
    }
    getStates(): void {
        const self = this
        self.addressService.getStates({
            fnSuccess(response?: any) {
                self.ufOptions = response.map((state: any) => ({
                    label: state.name,
                    value: state.uf,
                    id: state.id,
                }))
            },
            fnError() {
                self.toast.error(
                    `Não foi possível encontrar os estados`,
                    'Ops!'
                )
                self.disabledAddress = true
            },
            fnFinalized() {
                setTimeout(() => self.form.controls['state']?.setValue(''),150)
                setTimeout(() => self.form.controls['city']?.setValue(''),150)
            }
        })
    }
    getCities(cityCep: string = ''): void {
        const self = this
        let { state } = self.form?.value
        self.cityOptions = []

        if (state) {
            const id = self.ufOptions.filter((item) => item.value === state)[0].id

            state = parseInt(state)
            self.addressService.getCities(id, {
                fnSuccess(response?: any) {

                    self.cityOptions = response.map((city: any) => ({
                        label: city.name,
                        value: city.name,
                    }))

                    if (cityCep.length > 0) {
                        setTimeout(() =>
                            self.form.controls['city'].setValue(cityCep)
                        )
                    } else {
                        // self.reload()
                        setTimeout(() => self.form.controls['city'].setValue(''))
                    }
                },
                fnError() {
                    self.toast.error(
                        `Não foi possível encontrar as cidades`,
                        'Ops!'
                    )
                },
            })
        } else {
            self.cityOptions = []
        }
    }
}
